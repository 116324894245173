import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { shareProperty } from "../../../API/propertyDetails";
import * as Yup from "yup";
import GlobalContext from "../../../context/GlobalContext";
import "react-toastify/dist/ReactToastify.css";
import RuumrModal from "../../../components/molecules/Modal";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import CustomInputs from "../../../components/atoms/Inputs/CustomTextArea";
import Button from "@material-ui/core/Button";

import { Formik, useFormik } from "formik";
import { ModalContext } from "../../../context/ModalContext";

const ShareModel = ({
  shareFollow,
  toggleShare,
  copyToClipBoard,
  property,
  userDetails,
  site_id,
  copied,
}) => {
  const validationArray = Yup.object({
    recipient_email: Yup.string().required("Enter emails"),
    sender_email: Yup.string().required("Enter sender email"),
    user_name: Yup.string().required("Enter username"),
    url: Yup.string().required("Enter url"),
    property_address: Yup.string().required("Enter property address"),
    message: Yup.string().required("Enter message tobe shared"),
  });

  const address = property?.house_number
    ? `${property?.loc_address}, ${property?.zipcode}, ${property?.house_number}`
    : `${property?.loc_address}, ${property?.zipcode}, ${property?.loc_city}, ${property?.state}`;

  const userName =
    userDetails?.first_name == undefined
      ? ""
      : userDetails?.first_name + " " + userDetails?.last_name;
  console.log(userName);

  const shareFormik = useFormik({
    initialValues: {
      recipient_email: [],
      sender_email: "" || userDetails?.email,
      user_name: userName,
      url: window.location.href || "1 ",
      property_address: address || "",
      message:
        `Hi. Check out the property I found on mynextbid.com: ${address}` || "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      await shareProperty(
        {
          ...values,
          recipient_email: values.recipient_email.split(","),
        },
        site_id,
      );
      toggleShare();
    },
  });

  return (
    <RuumrModal
      open={shareFollow}
      handleClose={toggleShare}
      modalClass="pv-share-modal"
    >
      <h5>Email to Your Friends</h5>
      {copied ? (
        <div className="link-copied">
          <i class="fas fa-check"></i>
          <span>Link Copied</span>
        </div>
      ) : (
        <Button
          className="shrBtn d-flex align-items-center flex-wrap justify-content-center mt-4"
          onClick={copyToClipBoard}
        >
          <i className="shrIcon fas fa-link">
            <br />
          </i>
          <span className="gsl-text">Get shareable link</span>
        </Button>
      )}

      <form className="mdlForm" onSubmit={shareFormik.handleSubmit}>
        <div className="row">
          <div className="col-12 mt-3">
            <CustomInput
              label="Recipient email*"
              name="recipient_email"
              placeholder="Separate emails with a comma"
              value={shareFormik.values.recipient_email}
              onChange={shareFormik.handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <CustomInput
              label="Your email*"
              name="sender_email"
              placeholder="Enter your email"
              value={shareFormik.values.sender_email}
              onChange={shareFormik.handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <CustomInput
              label="Your name*"
              name="user_name"
              placeholder="Enter your full name"
              value={shareFormik.values.user_name}
              onChange={shareFormik.handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <CustomInput
              label="Property Address"
              name="property_address"
              placeholder="Enter  property address"
              value={shareFormik.values.property_address}
              onChange={shareFormik.handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <CustomInputs
              label="Message"
              name="message"
              placeholder="Enter message"
              shrink={true}
              rows="4"
              value={shareFormik.values.message}
              onChange={shareFormik.handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <button className="btn btn-primary">Send</button>
          </div>
        </div>
      </form>
    </RuumrModal>
  );
};

export default ShareModel;
