import React, { useState, useEffect, useContext, useRef } from "react";
import {
  getPropertyDetails,
  getBidHistory,
  watchList,
  getOfferHistory,
  addtoCart,
  submitOffer,
  bidding,
  beforeReg,
} from "../../../API/propertyDetails";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import { useHistory, useLocation } from "react-router-dom";
import PrimaryButton from "../../atoms/PrimaryButton";
import { toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import ShareModel from "../../../Themes/Theme1/PropertyView/ShareModel";
import {
  numberWithCommas,
  setDecimalPoint,
  noImage,
  onImageError,
  getTimeInterval,
} from "../../../utils";
import "./listing-card.css";
import moment from "moment";
import PropertyRight from "../../molecules/Property/PropertyRight";
import Bidding from "../../../components/atoms/Bidding";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
import CustomDialog from "../CustomDialog";
import Button from "@material-ui/core/Button";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import { getAllNotes } from "../../../API/notes";
import { capitalize } from "lodash";
import Timer from "../../molecules/Timer/timer";
import { abbrState } from "../../../utils/stateToAbbr";

const MyAuctionCard = ({ item, action, index, historyPage }) => {
  let history = useHistory();
  const location = useLocation();
  const site_id = global?.storeDetails?.site_id;
  const [ruumrFeeChecked, setRuumrFeeChecked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [tourCount, setTourCount] = useState(0);
  const {
    currentTime,
    userDetails,
    userLoggedIn,
    propertyAwarded,
    bid,
    bidIncrement,
  } = useContext(GlobalContext);
  const [agentDetails, setAgentDetails] = useState({});
  const anchorRef = React.useRef(null);
  const [mobile, setMobile] = useState("");
  const [getSellerData, setSellerData] = useState({});
  const [noComments, setNoComments] = useState(0);
  const [beforePopup, setBeforePopup] = useState(false);
  const [submittedPopup, setSubmittedPopup] = useState(false);
  const [error, setError] = useState("");
  const [modalopenBid, setModelopenBid] = useState(false);
  const [selectedClient, setSelectedClient] = useState({
    id: "-1",
    first_name: "Buyer does not have a Ruumr account",
    last_name: "",
    email: "",
    property_id: "",
    purchase_power: "",
  });
  const [timer, setTimer] = useState({});
  const [offerHistoryData, setOfferHistoryData] = useState([]);
  const bidContainer = useRef();
  const bookTour = useRef();
  const commentsRef = useRef();
  const sellerTermsCon = useRef(null);
  const [agentInfo, setAgentInfo] = useState({});
  const [submitBidPopup, setSubmitBidPopup] = useState(false);
  const [currentPropCity, setCurrentPropCity] = useState({});
  const [rtbCompleted, setRtbCompleted] = useState(true);
  const [fav, setFav] = useState(0);
  const [rtbFiles, setRtbFiles] = useState({});
  const [biddingAmount, setBiddingAmount] = useState("");
  const [shareFollow, setShareFollow] = useState(false);
  const [nextBidAmount, setNextBidAmount] = useState(item.next_bid);
  const [notes, setNotes] = useState([]);
  const [hideBuy, setHideBuy] = useState(true);
  const [buyTimer, setBuyTimer] = useState({});
  const [bidPopup, setBidPopup] = useState(false);
  const [offerTimer, setOfferTimer] = useState({});
  const [refundAmt, setRefundAmt] = useState(false);
  const [highestBidder, setHighestBidder] = useState("");
  const width = useMediaQuery("(min-width:1200px)");
  const progressSteps = [
    { id: 1, label: "Property Won" },
    { id: 2, label: "Payment Completed" },
    { id: 3, label: "Contract Signed" },
    { id: 4, label: "Completed" },
  ];
  const [currentProgressStep, setCurrentProgressStep] = useState(1);
  const handleProgressNext = () => {
    if (currentProgressStep < progressSteps.length) {
      setCurrentProgressStep(currentProgressStep + 1);
    }
  };
  const handleProgressBack = () => {
    if (currentProgressStep > 1) {
      setCurrentProgressStep(currentProgressStep - 1);
    }
  };
  useEffect(() => {
    currentTime.get &&
      setTimer(
        getTimeInterval(
          item.date_added,
          item.date_closed,
          currentTime.get,
          item.market_status,
        ),
      );
  }, [currentTime.get]);
  const toggleShare = () => {
    setShareFollow((prv) => !prv);
    setCopied(false);
  };

  const [copied, setCopied] = useState(false);

  const copyToClipBoard = (productId) => {
    // copy(window.location.href);
    const baseUrl = window.location.origin;
    const productViewUrl = `${baseUrl}/property/${productId}`;
    copy(productViewUrl);
    setCopied(true);
  };

  const openFile = (path) => {
    window.open(process.env.REACT_APP_IMAGE_URL + path, "_blank");
  };

  const fetchOfferHistory = async () => {
    let offerResult = await getOfferHistory({ id: item.id + "" }, site_id);
    if (offerResult.status === true) {
      setOfferHistoryData(offerResult.result);
    }
  };

  useEffect(() => {
    if (Object.keys(item).length !== 0 && currentTime.get) {
      if (item.buynow == 1) {
        setBuyTimer(
          getTimeInterval(
            item["b_date_added"],
            item["b_date_closed"],
            currentTime.get,
            item["market_status"],
          ),
        );
      }
    }

    if (Object.keys(item).length !== 0 && currentTime.get) {
      if (item.offer == 1) {
        setOfferTimer(
          getTimeInterval(
            item["o_date_added"],
            item["o_date_closed"],
            currentTime.get,
            item["market_status"],
          ),
        );
      }
    }
  }, [item, currentTime.get]);

  const toggleModalBid = () => {
    setModelopenBid(!modalopenBid);
  };

  useEffect(() => {
    if (
      Object.keys(propertyAwarded.get).length !== 0 &&
      propertyAwarded.get["project_id"] == item.id
    ) {
      if (propertyAwarded.get["winner"] == userDetails.get["id"]) {
        toast.success(
          `Congratulations! You won the property for $${setDecimalPoint(
            propertyAwarded.get["amount"],
          )}`,
        );
      } else {
        if (isUserBid !== 0) {
          toast.warning(`Unfortunately! You have lost this property`);
        }
      }
      setHighestBidder(propertyAwarded.get["winner"]);
      propertyAwarded.set({});
    }
  }, [userDetails.get, propertyAwarded.get]);

  const getBidHistorys = async () => {
    let data = await getBidHistory({ id: item.id }, site_id);
    let bidsArr = data.result;
    item.bidsCount = bidsArr.length;
    item.uniqueBidders = data.uniqueBidders;
  };

  const addProductToCart = async (id, bprice) => {
    try {
      let res = await addtoCart(id, bprice, site_id);
      if (res && res.status === true) {
        history.push(`/checkout/${id}`);
      } else {
        if (res) {
          toast.warning(res.message);
        }
      }
    } catch (err) {
      toast.warning(err);
    }
  };

  const getNotes = async () => {
    if (site_id != (null || undefined)) {
      const data = await getAllNotes({ project_id: item.id }, site_id);
      if (data.status === true) {
        setNotes(data.result);
      }
    }
  };

  const limit = "unlimited";

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const submittingOffer = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == item.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          offerTimer.isCompleted === false &&
          offerTimer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          toggleModalBid();
        } else if (
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          if (userDetails.get.customerid == "") {
            setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (item.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-success`,
              state: {
                path: location,
                tier: item.listing_tier,
                propertyDtls: { ...item, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (offerTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && offerTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            path: location,
            tier: item.listing_tier,
            propertyDtls: { ...item, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const updateAfterBid = (data) => {
    setSubmitBidPopup(data);
  };

  const submittingBuy = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == item.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          buyTimer.isCompleted === false &&
          buyTimer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          toggleModalBid();
        } else if (
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          localStorage.setItem("fromPage", "buynow");
          if (userDetails.get.customerid == "") {
            setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (item.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-success`,
              state: {
                path: location,
                productType: "buynow",
                from: "buynow",
                tier: item.listing_tier,
                propertyDtls: { ...item, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (buyTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && buyTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        localStorage.setItem("fromPage", "buynow");
        history.push({
          pathname: `/signup`,
          state: {
            path: location,
            productType: "buynow",
            from: "buynow",
            tier: getProductDetails.listing_tier,
            propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber?.length) {
        return (
          <>
            <div className="addressLine1">{`${
              addressArray[0]
            }, ${unitNumber}, ${addressArray[1]}, ${
              addressArray[2]?.trim().split(" ")[0]
            } ${zipcode || ""}`}</div>{" "}
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">{`${
            addressArray[0]
          }, ${city}, ${state}, ${zipcode || ""}`}</div>
        );
      } else {
        return (
          <>
            <div className="addressLine1">{`${addressArray[0]}, ${
              addressArray[1]
            }, ${addressArray[2]?.trim().split(" ")[0]} ${
              zipcode || ""
            }`}</div>{" "}
          </>
        );
      }
    } else {
      return address;
    }
  };

  useEffect(() => {
    if (
      bidIncrement.get &&
      !!Object.keys(bidIncrement.get).length &&
      item?.id === bidIncrement.get.productId
    ) {
      setNextBidAmount(bidIncrement.get["nextBid"]);
    }
  }, [bidIncrement.get, item?.id]);

  useEffect(() => {
    if (item.id !== undefined && userDetails?.get["id"]) {
      if (item.offer_statuss == "highest_bid") {
        setHighestBidder(userDetails.get["id"]);
      }
      setNextBidAmount(item.wprice + item.bidincrement);
      fetchOfferHistory();
    }
  }, [item]);

  useEffect(() => {
    if (
      timer &&
      timer?.isCompleted &&
      offerHistoryData.length &&
      highestBidder
    ) {
      let filterOffer = offerHistoryData
        .filter((data) => data.user_id == highestBidder)
        .sort((a, b) => a.id - b.id)
        .map((val) => {
          return val;
        });
      let index = filterOffer.length - 1;
      if (
        filterOffer[index] &&
        filterOffer[index]?.id &&
        filterOffer[index]?.declined
      ) {
        setHideBuy(false);
      } else {
        setHideBuy(true);
      }
    }
  }, [offerHistoryData]);

  const rtbFormConfirmation = async () => {};

  const submittingBid = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == item.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          timer.isCompleted === false &&
          timer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          setSubmitBidPopup(true);
        } else if (
          (timer.isCompleted === false &&
            timer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          if (userDetails.get.customerid == "") {
            setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (item.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (item.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-success`,
              state: {
                productType: "bidding",
                path: location,
                tier: item.listing_tier,
                propertyDtls: { ...item, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (timer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && timer.isCompleted) {
        toast.warning("Closed");
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            productType: "bidding",
            path: location,
            tier: item.listing_tier,
            propertyDtls: { ...item, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const cleanString = (str) => {
    if (!str) return "";
    return str.replace(/\s+/g, "-").replace(/,/g, "");
  };

  // const title = `${item.loc_address.split(" ").join("-")}-${item.loc_city}-${
  //   abbrState(item.state, "abbr") ? abbrState(item.state, "abbr") : item.state
  // }-${item.zipcode}`;

  const title = [
    cleanString(item.loc_address),
    cleanString(item.loc_city),
    abbrState(item.state, "abbr") || item.state,
    item.zipcode,
  ]
    .filter(Boolean)
    .join("-");

  return (
    <div
      className="search-card dashContentWrapper d-block p-0"
      key={`${action}-${index}`}
    >
      <div className="d-flex position-relative">
        <div className="imgCont">
          {userLoggedIn.get === true && (
            <div className="favIconCont">
              <FavoriteCheckbox project_id={item.id} />
            </div>
          )}

          <img
            src={
              item.avatar === null || undefined
                ? "/images/noimage.png"
                : `${process.env.REACT_APP_IMAGE_URL}uploads/product/${item.avatar}`
            }
            onError={onImageError}
            alt="productImg"
            onClick={() => history.push(`/property/${item.id}/${title}`)}
          />
          <div className="tmrCont">
            <div
              className="tmr grid-timer"
              style={{
                background:
                  item.winning === true
                    ? "green"
                    : item.winning === false
                      ? "red"
                      : "black",
              }}
            >
              <span
                className="material-icons-outlined"
                style={{ fontSize: "16px" }}
              >
                timer
              </span>
              <span className="nowrap" style={{ fontSize: "13px" }}>
                <Timer item={item} />
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 searchCardContent h-100">
          <div className="search-card-header">
            <div
              className="d-flex flex-column justify-content-between"
              style={{ gap: "16px" }}
            >
              <p
                className="m-0 paraTite"
                onClick={() => history.push(`/property/${item.id}/${title}`)}
              >
                {item.loc_address ? item.loc_address.split(",")[0] : ""},
                <br />
                {item.loc_city && `${item.loc_city},`}{" "}
                {abbrState(item.state, "abbr")
                  ? abbrState(item.state, "abbr")
                  : item.state}
                {","} {item.zipcode && `${item.zipcode}`}{" "}
                {/* {item.county && `${item.county}`} */}
              </p>
              <div className="listingType">
                {item.product_type !== "" && item.product_type != "None" ? (
                  <span className="assetType">{item.product_type}</span>
                ) : null}
                {item.cash_only_flag === 1 ? (
                  <span className="featuedType">Cash Only</span>
                ) : null}
              </div>
              <div className="search-card-details">
                <ul className="availImg mb-0">
                  <li className="listing-type">
                    <img src="/images/beds.svg" alt="" />{" "}
                    {item.listing_type !== 2 && item.bedroom_type !== ""
                      ? item.bedroom_type
                      : 0}{" "}
                  </li>
                  <li className="listing-type">
                    <img src="/images/baths.svg" alt="" />{" "}
                    {item.listing_type !== 2 && item.bathroom !== ""
                      ? item.bathroom
                      : 0}
                  </li>
                  <li className="listing-type">
                    <img src="/images/bedssqrt.svg" alt="" />{" "}
                    {item.listing_type && item.sqrtarea
                      ? `${numberWithCommas(item.sqrtarea)}`
                      : 0}{" "}
                    {item.listing_type == 2 ? "Acres" : "sq. ft."}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="search-card-actions changeAli flex-column">
            {action == "wishlist" && (
              <div onClick={toggleShare} className="share-card">
                <i className="fa fa-share-alt mr-2"></i>
                <span className="headTil">Share</span>
              </div>
            )}

            {action == "seller_active" && (
              <div className="biddingValue">
                <p className="currbid">Reserve Price</p>
                <h5 className="bidValue">${setDecimalPoint(item?.rprice)}</h5>
              </div>
            )}

            {!action?.includes("seller_active" || "seller_pending") &&
            item.bidsCount == 0 &&
            item.auction ? (
              <>
                {item.enable_start_price == "false" ? (
                  <div className="biddingValue">
                    <p className="currbid">Current Bid</p>
                    <h5 className="bidValue">${item?.wprice}</h5>
                  </div>
                ) : (
                  <div className="biddingValue">
                    <p className="currbid">Starting Bid</p>
                    <h5 className="bidValue">
                      ${setDecimalPoint(item.sprice)}
                    </h5>
                  </div>
                )}
              </>
            ) : !action?.includes("seller_active" || "seller_pending") &&
              !timer.isCompleted &&
              item.auction &&
              item.bidsCount > 0 ? (
              <div className="biddingValue">
                <p className="currbid">Current Price</p>
                <h5 className="bidValue">${setDecimalPoint(item.wprice)}</h5>
              </div>
            ) : !action?.includes("seller_active" || "seller_pending") &&
              timer.isCompleted &&
              item.buynow &&
              !buyTimer.isCompleted ? (
              <div className="biddingValue">
                <p className="currbid">Buynow Price</p>
                <h5 className="bidValue">${setDecimalPoint(item.bprice)}</h5>
              </div>
            ) : !action?.includes("seller_active" || "seller_pending") &&
              item.offer &&
              !offerTimer.isCompleted ? (
              <div className="biddingValue">
                <p className="currbid">Offer Price</p>
                <h5 className="bidValue">${setDecimalPoint(item.oprice)}</h5>
              </div>
            ) : !action?.includes("seller_active" || "seller_pending") &&
              item.listing_status == "pending" &&
              item.market_status == "sold" ? (
              <div className="biddingValue">
                <p className="currbid">Sold Price</p>
                <h5 className="bidValue">${setDecimalPoint(item?.amount)}</h5>
              </div>
            ) : null}
            {action == "active" && (
              <>
                {item.offer_statuss === "highest_bid" && (
                  <div className="h-auto saved-badge">High Bidder</div>
                )}

                {item.offer_statuss === "won" && (
                  <div className="h-auto saved-badge">Won</div>
                )}
                {item.offer_statuss === "outbid" && (
                  <div className="h-auto saved-badge outbid">Outbid</div>
                )}
                {item.offer_statuss === "lost" && (
                  <div className="h-auto saved-badge lostbid">Lost Bid</div>
                )}
              </>
            )}

            {action == "seller_active" && (
              <>
                {item.wprice >= item.rprice ? (
                  <div className="h-auto saved-badge">Reserve Met</div>
                ) : (
                  <div className="h-auto saved-badge lostbid">
                    Reserve Not Met
                  </div>
                )}
              </>
            )}

            {action == "seller_active" && (
              <>
                <p className="dateColor ContrRel mb-4">
                  Contracts Release Date: March 25th 2024 03:30pm
                </p>
                <div className="biddingButtons custBid">
                  <PrimaryButton>LOWER RESERVE PRICE</PrimaryButton>
                </div>
              </>
            )}
            {action == "active" && (
              <div className="biddingButtons">
                {timer &&
                Object.keys(timer)?.length != 0 &&
                item.auction == 1 &&
                timer?.isCompleted !== true ? (
                  <div>
                    <span className="headTil">
                      {userDetails.get["status"] == "incomplete" ? (
                        <PrimaryButton
                          btnSize="small"
                          onClick={() =>
                            handleUpdateClick(
                              userDetails.get["id"],
                              userDetails.get["email"],
                              "bidding",
                            )
                          }
                          label={"Register to Bid"}
                        />
                      ) : (
                        <PrimaryButton
                          onClick={() => submittingBid()}
                          btnSize="small"
                          label={
                            <>
                              {userDetails.get["id"] == item.user_id ? (
                                "Can't bid on own property"
                              ) : timer.isCompleted === false &&
                                timer.text === "Auction Ends In" &&
                                timer.text !== "Bidding Starts In" &&
                                timer.isCompleted === false &&
                                item.offer_statuss == "highest_bid" ? (
                                "You are the Highest Bidder"
                              ) : timer.isCompleted === false &&
                                timer.text === "Auction Ends In" &&
                                timer.text !== "Bidding Starts In" &&
                                timer.isCompleted === false ? (
                                "Submit a Bid"
                              ) : (timer.isCompleted === false &&
                                  timer.text === "Auction Ends In") ||
                                (timer.isCompleted === false &&
                                  timer.text === "Bidding Starts In") ? (
                                <>Register to Bid</>
                              ) : timer.isCompleted === false &&
                                timer.text === "Bidding Starts In" ? (
                                "Edit Registration"
                              ) : (
                                "Biddingclosed"
                              )}
                            </>
                          }
                        />
                      )}
                    </span>
                  </div>
                ) : null}
                {item.market_status !== "sold" && timer?.isCompleted ? (
                  <>
                    {buyTimer &&
                    Object.keys(buyTimer)?.length != 0 &&
                    item.buynow == 1 &&
                    buyTimer?.isCompleted != true ? (
                      <>
                        <div>
                          <span className="headTil">
                            <PrimaryButton
                              btnSize="small"
                              onClick={() =>
                                history.push(`/property/${item.id}/${title}`)
                              }
                            >
                              Buy Now
                            </PrimaryButton>
                          </span>
                        </div>
                      </>
                    ) : item.offer == 1 && !offerTimer.isCompleted ? (
                      <div>
                        <span className="headTil">
                          <PrimaryButton
                            btnSize="small"
                            onClick={() =>
                              history.push(`/property/${item.id}/${title}`)
                            }
                          >
                            Buy Now
                          </PrimaryButton>
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            )}

            {/* {action == "pending" && (
              <>
                <p className="dateColor">{item.buynow_date_added}</p>
                <div className="biddingButtons custBid">
                  <PrimaryButton>Sign purchase agreement</PrimaryButton>
                </div>
              </>
            )}

            {action == "seller_pending" && (
              <>
                <p className="dateColor">{item.buynow_date_added}</p>
                <div className="biddingButtons custBid">
                  <PrimaryButton>Sign purchase agreement</PrimaryButton>
                </div>
              </>
            )} */}
          </div>
          {/* {action == "pending" && (
            <div className="compStepper">
              <div>
                <span className="material-icons">check</span>
                <h4>Contracts Signed</h4>
              </div>
              <div>
                <span className="material-icons">check</span>
                <h4>Contracts Signed</h4>
              </div>
              <div>
                <span className="material-icons">check</span>
                <h4>Contracts Signed</h4>
              </div>
              <div>
                <span className="material-icons">check</span>
                <h4>Contracts Signed</h4>
              </div>
            </div>
          )} */}
        </div>
        {historyPage ? (
          <div className="historySection">
            <div className="d-flex align-items-center justify-content-between position-relative">
              {progressSteps.map((step, index) => (
                <div key={step.id} className="step">
                  <div
                    className="stepId d-flex align-items-center justify-content-center m-auto"
                    style={{
                      backgroundColor:
                        currentProgressStep >= step.id
                          ? currentProgressStep === step.id
                            ? "#000"
                            : "#991199"
                          : "#cacaca",
                      color:
                        currentProgressStep >= step.id
                          ? currentProgressStep === step.id
                            ? "#fff"
                            : "#fff"
                          : "#000",
                    }}
                  >
                    {step.id}
                  </div>
                  <div className="stepLabel">{step.label}</div>
                  {index < progressSteps.length - 1 && (
                    <div
                      style={{
                        backgroundColor:
                          currentProgressStep > step.id ? "#991199" : "#cacaca",
                      }}
                      className="stepProgress"
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {Object.keys(userDetails.get).length !== 0 && (
        <ShareModel
          shareFollow={shareFollow}
          toggleShare={toggleShare}
          copyToClipBoard={copyToClipBoard}
          property={item}
          userDetails={userDetails.get}
          site_id={site_id}
          copied={copied}
        />
      )}
      <Bidding
        submitBidPopup={submitBidPopup}
        function={updateAfterBid}
        id={item.id}
      />

      <CustomDialog
        handleClose={() => setBidPopup(false)}
        open={bidPopup}
        dialogClass="modalViewChan"
        title="Bidding Popup"
      >
        <div className="confimBuyPopup">
          <div className="subBidPop pt-3">
            <div className="row mb-3">
              <div className="col-md-6">
                <div
                  className="property-sub-head"
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${item.lat},${item.lng}`,
                      "_blank",
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="text-left">
                    {item.house_number
                      ? constructAddressComponent(
                          item.loc_address,
                          item.zipcode,
                          item.house_number,
                        )
                      : constructAddressComponent(
                          item.loc_address,
                          item.zipcode,
                          "",
                          item.loc_city,
                          item.state,
                        )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pd-op-center">
                  <div className="tmrConts">
                    <div className="currtmr">
                      {item.auction == 1 ? (
                        timer.isCompleted === true ? (
                          buyTimer?.isCompleted ? (
                            " "
                          ) : (
                            <>
                              <div className="trm">
                                {Object.keys(buyTimer)?.length !== 0 && (
                                  <>
                                    {buyTimer?.isCompleted === true ? (
                                      <span
                                        className="pv-timer"
                                        style={{
                                          color: "#FB0000",
                                        }}
                                      >
                                        Closed
                                      </span>
                                    ) : buyTimer?.text ===
                                      "Bidding Starts In" ? (
                                      <span
                                        className="pv-timer"
                                        style={{
                                          color: "#FB0000",
                                        }}
                                      >
                                        {moment(item.b_date_added).format(
                                          "MM/DD/YY",
                                        ) +
                                          " at " +
                                          moment(item.b_date_added).format(
                                            "LT",
                                          )}
                                      </span>
                                    ) : (
                                      <span className="pv-timer">{`${buyTimer?.days}D ${buyTimer?.hours}H ${buyTimer?.minutes}:${buyTimer?.seconds}`}</span>
                                    )}
                                  </>
                                )}
                              </div>
                              <span>
                                {buyTimer?.text === "Bidding Starts In"
                                  ? "Starts on"
                                  : "Ends in"}
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            <div className="trm">
                              {Object.keys(timer).length !== 0 && (
                                <>
                                  {timer.isCompleted === true ? (
                                    <span
                                      className="pv-timer"
                                      style={{
                                        color: "#000",
                                      }}
                                    >
                                      Closed
                                    </span>
                                  ) : timer.text === "Bidding Starts In" ? (
                                    <span
                                      className="pv-timer"
                                      style={{
                                        color: "#000",
                                      }}
                                    >
                                      {moment(item.date_added).format(
                                        "MM/DD/YY",
                                      ) +
                                        " at " +
                                        moment(item.date_added).format("LT")}
                                    </span>
                                  ) : (
                                    <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                                  )}
                                </>
                              )}
                            </div>
                            <span>
                              {timer.text === "Bidding Starts In"
                                ? "Live Bidding on"
                                : "Time Left"}
                            </span>
                          </>
                        )
                      ) : buyTimer?.isCompleted === true && item.buynow == 1 ? (
                        " "
                      ) : (
                        <>
                          <div className="trm">
                            {Object.keys(buyTimer)?.length !== 0 && (
                              <>
                                {buyTimer?.isCompleted === true ? (
                                  <span
                                    className="pv-timer"
                                    style={{
                                      color: "#FB0000",
                                    }}
                                  >
                                    Closed
                                  </span>
                                ) : buyTimer?.text === "Bidding Starts In" ? (
                                  <span
                                    className="pv-timer"
                                    style={{
                                      color: "#FB0000",
                                    }}
                                  >
                                    {moment(item.b_date_added).format(
                                      "MM/DD/YY",
                                    ) +
                                      " at " +
                                      moment(item.b_date_added).format("LT")}
                                  </span>
                                ) : (
                                  <span className="pv-timer">{`${buyTimer?.days}D ${buyTimer?.hours}H ${buyTimer?.minutes}:${buyTimer?.seconds}`}</span>
                                )}
                              </>
                            )}
                          </div>
                          <span>
                            {buyTimer?.text === "Bidding Starts In"
                              ? "Starts on"
                              : "Ends in"}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="currbid">
                      {item.bidsCount == 0 && !timer.isCompleted ? (
                        <>
                          {item.enable_start_price == "false" ? (
                            <>
                              {/* <label>Current Bid:</label>
                                    <h3>$ -</h3> */}
                            </>
                          ) : (
                            <>
                              <span className="bidAmt">
                                ${setDecimalPoint(item.sprice)}
                              </span>
                              <span>Starting Bid</span>
                            </>
                          )}
                        </>
                      ) : timer.isCompleted ? (
                        <>
                          <span className="bidAmt">
                            ${setDecimalPoint(item.bprice)}
                          </span>
                          <span>Buynow Price</span>
                        </>
                      ) : (
                        <>
                          <span className="bidAmt">
                            ${setDecimalPoint(item.wprice)}
                          </span>
                          <span>Current Bid</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {bidPopup && item && (
              <PropertyRight
                userValue={userDetails}
                highestBidder={highestBidder}
                data={item}
                tourCount={tourCount}
                agentDetails={agentDetails}
                userDetails={getSellerData}
                currentPropCity={currentPropCity}
                note={notes}
                agentInfo={agentInfo}
                openFile={openFile}
                propertyId={item.id}
                refreshNotes={() => getNotes()}
                buyTimerNw={buyTimer}
                timerNw={timer}
                offerTimer={offerTimer}
                // toggleModalBid={toggleModalBid}
                submittingBid={submittingBid}
                addProductToCart={addProductToCart}
                // rtbFormConfirmation={rtbFormConfirmation}
                rtbCompleted={rtbCompleted}
                // rtbFiles={rtbFiles}
                sellerTermsCon={sellerTermsCon}
                width={width}
                noComments={noComments}
                // addFav={addFav}
                mobile={mobile}
                fav={fav}
                toggleShare={toggleShare}
                // clickAskQuestion={clickAskQuestion}
                // clickBidNumber={clickBidNumber}
                submittingBuy={submittingBuy}
                submittingOffer={submittingOffer}
                // soldDetails={soldDetails}
                rtbFiles={rtbFiles}
                setBeforePopup={setBeforePopup}
                setSubmittedPopup={setSubmittedPopup}
                submittedPopup={submittedPopup}
              />
            )}
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

export default MyAuctionCard;
