import {
  Checkbox,
  FormControlLabel,
  Select,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import CheckBox from "../../../components/atoms/CheckBox";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import CustomSelect from "../../../components/atoms/Inputs/CustomSelect";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { useFormik } from "formik";
import GlobalContext from "../../../context/GlobalContext";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";
import {
  updateProfileData,
  saveProfileData,
  changePassword,
} from "../../../API/userData";
import { states } from "../../../utils/states";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "./myaccount.css";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const MyAccountForm = () => {
  const site_id = localStorage.getItem("site_id");
  const { userDetails } = useContext(GlobalContext);

  const passwordRules =
    global.defaultPasswordRule ||
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  const updateProfile = async (values) => {
    let data = await saveProfileData(
      {
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        companyName: values.companyName,
        public_contact: values.public_contact ? "true" : "false",
        country: values.country,
        state: values.state,
        city: values.city,
        zip: values.zip,
        address1: values.address,
      },
      site_id,
    );

    if (data) {
      let userData = userDetails.get;
      userData["first_name"] = values.first_name;
      userData["last_name"] = values.last_name;
      userData["phone"] = values.phone;
      userData["companyName"] = values.companyName;
      userData["public_contact"] = values.public_contact ? "true" : "false";
      userData["address"] = values.address;
      userData["city"] = values.city;
      userData["country"] = values.country;
      userData["state"] = values.state;
      userData["zip"] = values.zip;
      userDetails.set({ ...userData });
      toast.success("Profile updated successfully");
    } else {
      toast.warning("Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: userDetails?.get["first_name"] || "",
      last_name: userDetails?.get["last_name"] || "",
      email: userDetails?.get["email"] || "",
      phone: userDetails?.get["phone"] || "",
      companyName: userDetails?.get["companyName"] || "",
      public_contact:
        userDetails?.get["public_contact"] == "true" ? true : false,
      address: userDetails?.get["address"] || "",
      city: userDetails?.get["city"] || "",
      country: userDetails?.get["country"] || "US",
      state: userDetails?.get["state"] || "",
      zip: userDetails?.get["zip"] || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your First Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      last_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your Last Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
      // companyName: Yup.string().required("Enter your Company Name"),
      phone: Yup.string()
        .required("Phone number is required")
        .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
      public_contact: Yup.boolean().nullable(),
      address: Yup.string().required("Required!"),
      phone: Yup.string().required("Phone number is required"),
      country: Yup.string().trim().required("Required!"),
      state: Yup.string().trim().required("Required!"),
      city: Yup.string().trim().required("Required!"),
      zip: Yup.string()
        .trim()
        .max(7, "Maximum 7 characters Required!")
        .min(3, "Minimum 3 characters Required!")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      // Handle form submission
      updateProfile(values);
    },
  });

  useEffect(() => {
    if (userDetails.get) {
      formik.values.first_name = userDetails?.get["first_name"] || "";
      formik.values.last_name = userDetails?.get["last_name"] || "";
      formik.values.email = userDetails?.get["email"] || "";
      formik.values.phone = userDetails?.get["phone"] || "";
      formik.values.company = userDetails?.get["company"] || "";
      formik.values.companyName = userDetails?.get["companyName"] || "";
      formik.values.public_contact =
        userDetails?.get["public_contact"] || false;
      formik.values.address = userDetails?.get["address"] || "";
      formik.values.city = userDetails?.get["city"] || "";
      formik.values.zip = userDetails?.get["zip"] || "";
      formik.values.state = userDetails?.get["state"] || "";
    }
  }, [userDetails.get]);
  const [showPassword, setShowPassword] = useState(false);
  const [newshowPassword, setNewShowPassword] = useState(false);
  const [confirmshowPassword, setCurrentShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handlePasswordVisibility2 = () => {
    setNewShowPassword((prevState) => !prevState);
  };
  const handlePasswordVisibility3 = () => {
    setCurrentShowPassword((prevState) => !prevState);
  };
  const formikPassword = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
      current_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .max(50, "Maximum 50 characters")
        .min(8, "Minimum 8 characters")
        .matches(passwordRules, {
          message:
            "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
        })
        .required("Required!"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password")], "Passwords not match")
        .required("Required!"),
      current_password: Yup.string().required("Required!"),
    }),
    onSubmit: (values, { resetForm }) => {
      // Handle form submission
      changePassword(
        {
          password: values.current_password,
          newpassword: values.new_password,
        },
        site_id,
      ).then((response) => {
        if (response.success == true) {
          toast.success(response.message);
          resetForm();
        } else {
          toast.error(response.message);
        }
      });
    },
  });

  const formikNotification = useFormik({
    initialValues: {
      unsubscribe: 0,
      events: 0,
      newsLetter: 0,
      property_tracker: 0,
      property_recommend: 0,
    },
    onSubmit: (values) => {
      // Handle form submission
    },
  });

  const formFields = [
    {
      label: "First Name",
      name: "first_name",
      type: "text",
      placeholder: "Enter your first name",
      className: "col-md-6 ",
    },
    {
      label: "Last Name",
      name: "last_name",
      type: "text",
      placeholder: "Enter your last name",
      className: "col-md-6 ",
    },
    {
      label: "Phone",
      name: "phone",
      type: "phone",
      placeholder: "Enter your Phone",
      className: "col-md-6 ",
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      className: "col-md-6 ",
      disabled: true,
    },
    // {
    //   label: "Show email contact in Public profile",
    //   name: "public_contact",
    //   type: "checkbox",
    //   placeholder: "",
    //   className: "col-md-12",
    // },
    {
      label: "Address",
      name: "address",
      type: "text",
      placeholder: "Address",
      className: "col-md-6 ",
    },
    {
      label: "City",
      name: "city",
      type: "text",
      placeholder: "City",
      className: "col-md-6 ",
    },
    {
      label: "State",
      name: "state",
      type: "select",
      className: "col-md-6 ",
      placeholder: "Select your State",
      options: states,
    },
    {
      label: "Zip Code",
      name: "zip",
      type: "text",
      placeholder: "Zip Code",
      className: "col-md-6 ",
    },
    // {
    //   label: "Country",
    //   name: "country",
    //   type: "select",
    //   className: "col-md-6 ",
    //   placeholder: "Select your country",
    //   disabled: true,
    //   options: [
    //     { value: "us", label: "United States" },
    //     { value: "ca", label: "Canada" },
    //     { value: "uk", label: "United Kingdom" },
    //     { value: "au", label: "Australia" },
    //   ],
    // },
  ];
  const passwordFields = [
    {
      label: "New Password",
      name: "new_password",
      type: newshowPassword ? "text" : "password",
      placeholder: "Enter your new password",
      className: "col-md-6 ",
    },
    {
      label: "Confirm New Password",
      name: "confirm_password",
      type: confirmshowPassword ? "text" : "password",
      placeholder: "Enter your confirm password",
      className: "col-md-6 ",
    },
    {
      label: "Current Password",
      name: "current_password",
      type: showPassword ? "text" : "password",
      placeholder: "Enter your current password",
      className: "col-md-6 ",
    },
  ];

  const [activeChcekState, setActiveChcekState] = useState(false);

  return (
    <div className="profile-card-wrapper pfrlCardNew">
      <div className="clearfix">
        {/* <h2 className="pp-head float-left">My Account</h2> */}
      </div>
      <div className="pcd-media prflNwCs">
        <h4 className="title">General Information</h4>
        <form>
          <div className="row mt-3">
            {formFields.map((field, index) => (
              <div key={index} className={`mb-3 ${field.className}`}>
                {field.type == "phone" ? (
                  <>
                    <CustomInput
                      label="Mobile Number"
                      name="phone"
                      placeholder="Mobile Number*"
                      value={formik.values.phone}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "phone",
                          normalizePhoneNumberInput(
                            e.target.value,
                            formik.values.phone,
                          ),
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[field.name] && formik.errors[field.name] ? (
                      <div className="invalid-feedback">
                        {formik.errors[field.name]}
                      </div>
                    ) : null}
                  </>
                ) : field.type ==
                  "checkbox" ? null : //     label="Show Public Contact" //     } //       /> //         name="public_contact" //         } //           ) //             !formik.values.public_contact //             "public_contact", //           formik.setFieldValue( //         onChange={() => //         // onChange={handleCheckBoxChange} //         checked={formik.values.public_contact} //         value={formik.values.public_contact} //       <GreenCheckbox //     control={ //   <FormControlLabel // <div className=""> // </div> //   /> //     }} //       ); //         e.target.checked //         "public_contact", //       formik.setFieldValue( //     onchange={(e) => { //     checked={formik.values.public_contact} // Use checked instead of value for checkboxes //     name={field.name} //     label={field.label} //   <CheckBox // <div className="accountCheck">
                //   />
                // </div>
                field.type !== "select" ? (
                  <>
                    <CustomInput
                      type={field.type}
                      placeholder={field.placeholder}
                      value={formik.values[field.name]}
                      className={field.className}
                      label={field.label}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name={field.name}
                      disabled={field.disabled}
                    />
                    {formik.touched[field.name] && formik.errors[field.name] ? (
                      <div className="invalid-feedback">
                        {formik.errors[field.name]}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <CustomSelect
                      name={field.name}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      selectLabel={field.label}
                      customLabel={field.label}
                      disabled={field.disabled}
                    >
                      {field.options.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </CustomSelect>
                    {formik.touched[field.name] && formik.errors[field.name] ? (
                      <div className="invalid-feedback">
                        {formik.errors[field.name]}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="secBtn mb-4" style={{ maxWidth: "fit-content" }}>
            <PrimaryButton label={"UPDATE"} onClick={formik.handleSubmit} />
          </div>
        </form>
        <hr />
        <h4 className="title">Password</h4>
        <form>
          <div className="row mt-3" style={{ flexFlow: "wrap-reverse" }}>
            {passwordFields.map((field, index) => (
              <div key={index} className={`mb-3 ${field.className}`}>
                {field.type !== "select" ? (
                  <>
                    <CustomInput
                      type={field.type}
                      placeholder={field.placeholder}
                      className={field.className}
                      onBlur={formikPassword.handleBlur}
                      value={formikPassword.values[field.name]}
                      onChange={formikPassword.handleChange}
                      label={field.label}
                      name={field.name}
                    />
                    {field.name === "current_password" && (
                      <div
                        className="eye-icon"
                        onClick={handlePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "26px",
                          top: "35px",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff style={{ cursor: "pointer" }} />
                        ) : (
                          <Visibility style={{ cursor: "pointer" }} />
                        )}
                      </div>
                    )}
                    {field.name === "confirm_password" && (
                      <div
                        className="eye-icon"
                        onClick={handlePasswordVisibility3}
                        style={{
                          position: "absolute",
                          right: "26px",
                          top: "35px",
                        }}
                      >
                        {confirmshowPassword ? (
                          <VisibilityOff style={{ cursor: "pointer" }} />
                        ) : (
                          <Visibility style={{ cursor: "pointer" }} />
                        )}
                      </div>
                    )}
                    {field.name === "new_password" && (
                      <div
                        className="eye-icon"
                        onClick={handlePasswordVisibility2}
                        style={{
                          position: "absolute",
                          right: "26px",
                          top: "35px",
                        }}
                      >
                        {newshowPassword ? (
                          <VisibilityOff style={{ cursor: "pointer" }} />
                        ) : (
                          <Visibility style={{ cursor: "pointer" }} />
                        )}
                      </div>
                    )}

                    {formikPassword.touched[field.name] &&
                    formikPassword.errors[field.name] ? (
                      <div className="invalid-feedback">
                        {formikPassword.errors[field.name]}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <CustomSelect
                      onChange={formikPassword.handleChange}
                      name={field.name}
                      selectLabel={field.label}
                      value={formikPassword.values[field.name]}
                      onBlur={formikPassword.handleBlur}
                      customLabel={field.label}
                    >
                      {field.options.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </CustomSelect>
                    {formik.touched[field.name] && formik.errors[field.name] ? (
                      <div className="invalid-feedback">
                        {formik.errors[field.name]}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="secBtn mb-4" style={{ maxWidth: "fit-content" }}>
            <PrimaryButton
              label={"UPDATE"}
              onClick={formikPassword.handleSubmit}
            />
          </div>
        </form>
        {/* <hr />
        <h4 className="title">Manage Your Notifications</h4>
        <form>
          <div className="mt-3 checkboxWithContent">
            <CheckBox
              label={<span>Property Recommendations</span>}
              name="property_recommend"
              value={formikNotification.values.property_recommend}
              onChange={formikNotification.handleChange}
              onBlur={formikNotification.handleBlur}
            />
            <span className="subti">
              Get Property recommendations, auction reminders and events in your
              area
            </span>
          </div>
          <div className="mt-3 checkboxWithContent">
            <CheckBox
              label={<span>Property Tracker Updates</span>}
              name="property_tracker"
              value={formikNotification.values.property_tracker}
              onChange={formikNotification.handleChange}
              onBlur={formikNotification.handleBlur}
            />
            <span className="subti">
              Get details on all available properties based on your preferred
              county
            </span>
          </div>
          <div className="mt-3 checkboxWithContent">
            <CheckBox
              label={<span>Newslatters and Update</span>}
              name="newsLetter"
              value={formikNotification.values.newsLetter}
              onChange={formikNotification.handleChange}
              onBlur={formikNotification.handleBlur}
            />
            <span className="subti">
              24 Hour Auction Newsletters, Offers, Marketplace News and More
            </span>
          </div>
          <div className="mt-3 checkboxWithContent">
            <CheckBox
              label={<span>Events and Conferences</span>}
              name="events"
              value={formikNotification.values.events}
              onChange={formikNotification.handleChange}
              onBlur={formikNotification.handleBlur}
            />
            <span className="subti">
              Insider updates on educational and networking events in your area
            </span>
          </div>
          <div className="mt-3 checkboxWithContent">
            <CheckBox
              label={<span>Unsubscribe from All Notifications</span>}
              name="unsubscribe"
              value={formikNotification.values.unsubscribe}
              onChange={formikNotification.handleChange}
              onBlur={formikNotification.handleBlur}
            />
            <span className="subti">
              This will unsubscribe you from all emails and notifications from
              24 Hour Sold
            </span>
          </div>
          <div className="secBtn mt-4 pb-5" style={{ maxWidth: "fit-content" }}>
            <PrimaryButton
              label={"UPDATE"}
              onClick={formikNotification.handleSubmit}
            />
          </div>
        </form> */}
      </div>
    </div>
  );
};

export default MyAccountForm;
