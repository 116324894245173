import React, { useState, useEffect, useContext, useCallback } from "react";
import SearchCard from "../../../components/atoms/SearchCard";
import "./SearchGrid.css";
import Slider from "react-slick";
import GlobalContext from "../../../context/GlobalContext";
import socket from "../../../utils/socket";
const SearchGrid = (props) => {
  const { bid, currentTime } = useContext(GlobalContext);
  const site_id = global?.storeDetails?.site_id;

  const [properties, setProperties] = useState(
    props.properties ? props.properties : [],
  );

  const listings = [
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
  ];

  const upcomingSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    swipeToSlide: true,
    centerMode: false,
    variableWidth: false,
    adaptiveHeight: true,
    autoplay: false,
    pauseOnHover: true,
    initialSlide: 0,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 749,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (props?.properties) {
      setProperties(props?.properties);
    }
  }, [props?.properties]);

  const handlePropertyUpdate = useCallback((data) => {
    setProperties((currentProperties) => {
      const index = currentProperties.findIndex(
        (s) => s.id === parseInt(data.productId, 10),
      );

      if (index === -1) return currentProperties;

      const updatedProperties = [...currentProperties];
      const productToChange = { ...updatedProperties[index] };

      if (Number(data.belowFive) === 1) {
        productToChange.date_closed = data.belowFiveIncrement;
      }

      updatedProperties[index] = {
        ...productToChange,
        bidcount: data.bidCount,
        bidCount: data.bidCount,
        wprice: data.wPrice,
      };

      return updatedProperties;
    });
  }, []);

  useEffect(() => {
    // Set up socket listener
    const handleBidAddTime = (data) => {
      handlePropertyUpdate(data);
    };

    socket.on("bidAddtime", handleBidAddTime);

    // Cleanup socket listener
    return () => {
      socket.off("bidAddtime", handleBidAddTime);
    };
  }, [handlePropertyUpdate]);

  return (
    <>
      <Slider {...upcomingSettings}>
        {properties.map((item, index) => {
          return (
            <>
              <SearchCard
                item={item}
                key={index}
                showTimer={true}
                site_id={site_id}
                className="search-card"
              />
            </>
          );
        })}
      </Slider>
    </>
  );
};

export default SearchGrid;
