import React, { useContext, useEffect, useState } from "react";
import FavoriteCheckbox from "../FavoriteCheckbox";
import { useHistory } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import ImageGallery from "react-image-gallery";
import "./search-card.css";
import {
  numberWithCommas,
  setDecimalPoint,
  onImageError,
  onPropertyImageError,
  getTimeInterval,
} from "../../../utils";
import { abbrState } from "../../../utils/stateToAbbr";
import Timer from "../../molecules/Timer/timer";
import moment from "moment";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { Gavel } from "@material-ui/icons";
import { getBidHistory } from "../../../API/propertyDetails";
import CustomDialog from "../CustomDialog";
import { ModalContext } from "../../../context/ModalContext";

const SearchCard = (props) => {
  const history = useHistory();
  const { currentTime, bid } = useContext(GlobalContext);
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const item = props.item;
  const [timer, setTimer] = useState({});
  const [buyTimer, setBuyTimer] = useState({});
  const [offerTimer, setOfferTimer] = useState({});
  const [propertyPhotos, setPropertyPhotos] = useState([]);
  const _currentTime = currentTime || props.timercurrent;
  const store_id = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (item?.all_images?.length > 0) {
      setPropertyPhotos(
        item.all_images.map((data) => ({
          original: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${data.asset_image}`,
          originalClass: "grid-card-img",
          originalHeight: "100%",
          originalWidth: "100%",
        })),
      );
    }
  }, [item?.all_images]);

  // useEffect(() => {
  //   if (item?.auction == 1 && _currentTime?.get) {
  //     setTimer(
  //       getTimeInterval(
  //         item["date_added"],
  //         item["date_closed"],
  //         _currentTime?.get,
  //         item["market_status"],
  //         "Auction"
  //       )
  //     );
  //   }
  //   if (item?.offer == 1 && _currentTime?.get) {
  //     setOfferTimer(
  //       getTimeInterval(
  //         item["o_date_added"],
  //         item["o_date_closed"],
  //         _currentTime.get,
  //         item["market_status"],
  //         "Offer"
  //       )
  //     );
  //   }
  //   if (item?.buynow == 1 && _currentTime?.get) {
  //     setBuyTimer(
  //       getTimeInterval(
  //         item["b_date_added"],
  //         item["b_date_closed"],
  //         _currentTime.get,
  //         item["market_status"],
  //         "Buy Now"
  //       )
  //     );
  //   }
  // }, [_currentTime?.get]);

  const [bidHistoryModal, setBidHistoryModal] = useState(false);
  const [bidHistory, setBidHistory] = useState([]);

  const handleBidHistory = async () => {
    let data = await getBidHistory({ id: item.id }, props.site_id);
    let bidsArr = data.result;
    setBidHistoryModal(true);
    setBidHistory(bidsArr);
  };

  // const title = item?.sell_location
  //   ? item.sell_location?.split(" ").join("-")
  //   : `${item.loc_address.split(" ").join("-")}-${item?.loc_city
  //       ?.split(" ")
  //       .join("-")}-${
  //       abbrState(item.state, "abbr")
  //         ? abbrState(item.state, "abbr")
  //         : item.state
  //     }-${item.zipcode}`;

  const cleanString = (str) => {
    if (!str) return "";
    return str.replace(/\s+/g, "-").replace(/,/g, "");
  };

  const title = [
    cleanString(item.loc_address),
    cleanString(item.loc_city),
    abbrState(item.state, "abbr") || item.state,
    item.zipcode,
  ]
    .filter(Boolean)
    .join("-");

  return item?.id ? (
    <>
      <div className="upcomAuc" style={{ cursor: "pointer" }}>
        <div className="sc-top-wrap">
          <div className="timer-class">
            <Timer item={item} />
          </div>
          {propertyPhotos && propertyPhotos.length !== 0 && (
            <ImageGallery
              items={[...propertyPhotos].reverse()}
              showThumbnails={false}
              showNav={true}
              showBullets={propertyPhotos.length > 1 ? true : false}
              showFullscreenButton={false}
              showPlayButton={false}
              autoPlay={false}
              onErrorImageURL="/images/noimage.png"
              disableSwipe={true}
              onClick={() => {
                if (item?.site_id === props.site_id) {
                  if (props.showTimer) {
                    history.push({
                      pathname: `/property/${item.id}/${title}`,
                      search: "",
                    });
                  } else {
                    window.open(
                      `${window.location.origin}/property/${item.id}/${title}`,
                    );
                  }
                } else {
                  window.open(
                    `${
                      item?.domain !== "" ? item?.domain : "/" + item?.store_id
                    }/property/${item?.id}/${title}`,
                  );
                }
              }}
            />
          )}
        </div>

        <div className="cardDesc">
          <div className="favoriteList">
            <FavoriteCheckbox
              project_id={item.id}
              setLoginOpen={setLoginOpen}
            />
          </div>
          <div className="listingType">
            {item.product_type !== "" && item.product_type != "None" ? (
              <span className="assetType">
                {item.product_type.charAt(0).toUpperCase() +
                  item.product_type.slice(1)}
              </span>
            ) : null}
            {item.cash_only_flag === 1 ? (
              <span className="featuedType">Cash Only</span>
            ) : null}
          </div>
          <div className="d-flex justify-content-between">
            <h3>
              {item.loc_address ? item.loc_address.split(",")[0] : ""},
              <br />
              {item.loc_city && `${item.loc_city},`}{" "}
              {abbrState(item.state, "abbr")
                ? abbrState(item.state, "abbr")
                : item.state}
              {item.zipcode ? `, ${item.zipcode}` : ""}
              {/* {item.county ? `, ${item.county}` : ""} */}
            </h3>
            {(item.offer_type === "Auction" && item.auction === 1) ||
            (item.offer_type === "Buynow" && item.buynow === 1) ? (
              <>
                {item.offer_type !== "Auction" && item.auction !== 1 ? (
                  <div className="pricings">
                    <span className="currBid">Buy Now Price</span>
                    <span className="price">
                      ${setDecimalPoint(item.bprice)}
                    </span>
                  </div>
                ) : (
                  <div className="pricings">
                    <span className="currBid">
                      {item.bidCount ? "Current Bid" : "Starting Bid"}
                    </span>
                    <span className="price">
                      $
                      {item.bidCount
                        ? setDecimalPoint(item.wprice)
                        : setDecimalPoint(item.sprice)}
                    </span>
                  </div>
                )}
              </>
            ) : null}
          </div>

          <div className="features">
            <ul className="d-flex align-items-center pl-0 justify-content-between mb-0">
              {item.bedroom_type && (
                <li className="d-flex align-items-center">
                  <img src="/images/sleep.svg" alt="Sleep Icon" />
                  {item.bedroom_type} Beds
                </li>
              )}
              {item.bathroom && (
                <li className="d-flex align-items-center">
                  <img src="/images/bathtub.svg" alt="Bathtub Icon" />
                  {item.bathroom} Bath
                </li>
              )}
              <li className="d-flex align-items-center">
                <img src="/images/square.svg" alt="Square Icon" />
                {item.sqrtarea ? numberWithCommas(item.sqrtarea) : 0}{" "}
                {item.listing_type === 2 ? "Acres" : "sq ft."}
              </li>
            </ul>
          </div>
        </div>

        <div className="cardBottom">
          <div className="d-flex align-items-center justify-content-between">
            <PrimaryButton
              onClick={() => {
                if (item?.site_id === props.site_id) {
                  if (props.showTimer) {
                    history.push({
                      pathname: `/property/${item.id}/${title}`,
                      search: "",
                    });
                  } else {
                    window.open(
                      `${window.location.origin}/property/${item.id}/${title}`,
                    );
                  }
                } else {
                  window.open(
                    `${
                      item?.domain !== "" ? item?.domain : "/" + item?.store_id
                    }/property/${item.id}/${title}`,
                  );
                }
              }}
            >
              {item.auction === 1 && item.buynow === 1
                ? "Bid Now"
                : item.auction === 1
                  ? "Bid Now"
                  : "View Property"}
            </PrimaryButton>
            <div
              className="d-flex align-items-center noOfBids"
              onClick={handleBidHistory}
            >
              <Gavel /> {item.bidCount} Bids
            </div>
          </div>
        </div>
        {bidHistoryModal && (
          <CustomDialog
            title="Bid History"
            open={bidHistoryModal}
            dialogClass="bidHistoryModal"
            handleClose={() => setBidHistoryModal(false)}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Proposed amount</th>
                </tr>
              </thead>
              <tbody>
                {bidHistory?.length ? (
                  <>
                    {bidHistory?.map((data, index) => (
                      <tr key={index}>
                        <td>{data.name}</td>
                        <td>
                          {moment(data.created_at).format("MMM DD")} at{" "}
                          {moment(data.created_at).format("hh:mm A")}
                        </td>
                        <td>
                          {data.proposed_amount
                            ? `$${setDecimalPoint(
                                parseInt(data.proposed_amount, 10),
                              )}`
                            : "-"}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </CustomDialog>
        )}
      </div>
    </>
  ) : null;
};

export default SearchCard;
