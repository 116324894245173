import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import {
  numberWithCommas,
  setDecimalPoint,
  onImageError,
  getTimeInterval,
} from "../../../utils";
import { abbrState } from "../../../utils/stateToAbbr";
import moment from "moment";
import "./grid-card.css";

const GridCard = ({ item, from }) => {
  let history = useHistory();
  const { currentTime } = useContext(GlobalContext);
  const [timer, setTimer] = useState({});
  const store_id = window.location.pathname.split("/")[1];
  useEffect(() => {
    currentTime.get &&
      setTimer(
        getTimeInterval(
          item.date_added,
          item.date_closed,
          currentTime.get,
          item.market_status,
        ),
      );
  }, [currentTime.get]);

  const cleanString = (str) => {
    if (!str) return "";
    return str.replace(/\s+/g, "-").replace(/,/g, "");
  };

  // const title = `${item.loc_address.split(" ").join("-")}-${item.loc_city}-${
  //   abbrState(item.state, "abbr") ? abbrState(item.state, "abbr") : item.state
  // }-${item.zipcode}`;

  const title = [
    cleanString(item.loc_address),
    cleanString(item.loc_city),
    abbrState(item.state, "abbr") || item.state,
    item.zipcode,
  ]
    .filter(Boolean)
    .join("-");

  return (
    <div
      onClick={() =>
        from != "review" && history.push(`/property/${item.id}/${title}`)
      }
      className={`clearfix card grid-card mb-2 ${
        Object.keys(timer).length !== 0 && timer.text === "Starts In"
          ? "upc-grid"
          : ""
      }`}
    >
      <img
        src={
          item.avatar === null
            ? "/images/noimage.png"
            : `${process.env.REACT_APP_IMAGE_URL}uploads/product/${item.avatar}`
        }
        onError={onImageError}
        alt=""
        className="grid-card-img"
      />
      {from != "review" && (
        <div className="nf-list">
          {/* {Object.keys(timer) !== 0 &&
            (timer.text !== "Starts In" ? (
              <div className="uc-label">
                {Object.keys(timer) !== 0 && timer.isCompleted === false
                  ? timer.text
                  : ""}
              </div>
            ) : (
              ""
            ))} */}
          <div
            className={`grid-timer ${
              from !== "resently"
                ? item.winning !== undefined
                  ? item.winning === true
                    ? "green-back"
                    : "pink-back"
                  : ""
                : ""
            }`}
          >
            <i className="far fa-clock"></i>{" "}
            {Object.keys(timer) !== 0 && timer.isCompleted
              ? "Closed"
              : timer.text === "Bidding Starts In"
                ? "Bids start " +
                  moment(item.date_added).format("MM/DD/YY") +
                  " at " +
                  moment(item.date_added).format("LT")
                : ` ${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}
          </div>
        </div>
      )}
      <div className="card-body">
        <div className="grid-bottom">
          <div className="itmPrc">
            $
            {item.auction === 1
              ? setDecimalPoint(item.wprice)
              : item.buynow === 1
                ? setDecimalPoint(item.bprice)
                : item.offer === 1
                  ? setDecimalPoint(item.oprice)
                  : "-"}
          </div>
          {/* <div className="itmAdrs">{item.title}</div> */}
          <div className="itmAdrs">{item.loc_address.split(",")[0]}</div>
          <div className="itmCty">
            {item.loc_city},{" "}
            {abbrState(item.state, "abbr")
              ? abbrState(item.state, "abbr")
              : item.state}{" "}
            {item.zipcode || ""}{" "}
          </div>
          <div className="itmSpecs">
            {item.listing_type !== 2 && item.bedroom_type !== ""
              ? `${item.bedroom_type} Bed | `
              : null}
            {item.listing_type !== 2 && item.bathroom !== ""
              ? `${item.bathroom} Bath | `
              : null}
            {item.listing_type && item.sqrtarea
              ? `${numberWithCommas(item.sqrtarea)} ${
                  item.listing_type == 2 ? "Acres" : "sq. ft."
                }`
              : null}
            {/* {item.listing_type !== 2
              ? `${item.bedroom_type} Bed | ${item.bathroom} Bath | `
              : null}
            {item.sqrtarea ? numberWithCommas(item.sqrtarea) : 0}{" "}
            {item.listing_type == 2 ? "Acres" : "sq. ft."} */}
          </div>
        </div>
      </div>
      {from !== "resently" && from != "review" && item.scheduled && (
        <div className="schedule">
          <div className="upcmTtle">Monday, Jan 11, 2021 @ 3:30 PM</div>
          <div className="sc-links">
            <a>Reschedule</a>
            <a>Cancel</a>
            <a>+ Calender</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridCard;
