import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { getTimeInterval } from "../../../utils";
import socket from "../../../utils/socket";
import moment from "moment";

// Initialize server time
let serverTime = new Date();
if (socket) {
  socket.on("sliservertime", (data) => {
    if (moment(data.dTime).isValid()) {
      serverTime = new Date(data.dTime);
    }
  });
}

const Timer = (props) => {
  const { currentTime } = useContext(GlobalContext);
  const [timers, setTimers] = useState({
    auction: null,
    buy: null,
    offer: null,
  });
  const [item, setItem] = useState(props.item || {});
  const [initialized, setInitialized] = useState(false);

  // Update item when props change
  useEffect(() => {
    if (props?.item) {
      setItem(props.item);
      // Initialize timers immediately when item is set
      if (props.item.id && serverTime) {
        const initialTimers = {};

        if (props.item.auction === 1) {
          initialTimers.auction = getTimeInterval(
            props.item.date_added,
            props.item.date_closed,
            serverTime,
            props.item.market_status,
            "Auction",
            props.item.id,
          );
        }

        if (props.item.buynow === 1) {
          initialTimers.buy = getTimeInterval(
            props.item.b_date_added,
            props.item.b_date_closed,
            serverTime,
            props.item.market_status,
            "Buy Now",
            props.item.id,
          );
        }

        if (props.item.offer === 1) {
          initialTimers.offer = getTimeInterval(
            props.item.o_date_added,
            props.item.o_date_closed,
            serverTime,
            props.item.market_status,
            "Offer",
            props.item.id,
          );
        }

        setTimers(initialTimers);
        setInitialized(true);
      }
    }
  }, [props.item]);

  // Handle timer updates
  useEffect(() => {
    if (!item.id || !serverTime || !initialized) return;

    const intervals = [];

    // Handle auction timer
    if (item.auction === 1) {
      const auctionInterval = setInterval(() => {
        setTimers((prev) => ({
          ...prev,
          auction: getTimeInterval(
            item.date_added,
            item.date_closed,
            serverTime,
            item.market_status,
            "Auction",
            item.id,
          ),
        }));
      }, 1000);
      intervals.push(auctionInterval);
    }

    // Handle buy now timer
    if (item.buynow === 1) {
      const buyInterval = setInterval(() => {
        setTimers((prev) => ({
          ...prev,
          buy: getTimeInterval(
            item.b_date_added,
            item.b_date_closed,
            serverTime,
            item.market_status,
            "Buy Now",
            item.id,
          ),
        }));
      }, 1000);
      intervals.push(buyInterval);
    }

    // Handle offer timer
    if (item.offer === 1) {
      const offerInterval = setInterval(() => {
        setTimers((prev) => ({
          ...prev,
          offer: getTimeInterval(
            item.o_date_added,
            item.o_date_closed,
            serverTime,
            item.market_status,
            "Offer",
            item.id,
          ),
        }));
      }, 1000);
      intervals.push(offerInterval);
    }

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [
    item.id,
    item.date_closed,
    item.b_date_closed,
    item.o_date_closed,
    initialized,
  ]);

  const formatTime = (timeObj) => {
    if (!timeObj) return "";

    const { days, hours, minutes, seconds } = timeObj;
    if (!days && !hours && !minutes && !seconds) return "";

    if (hours < 24 && days < 1) {
      return `${hours}H : ${minutes}M : ${seconds}S`;
    }
    return `${days}D : ${hours}H : ${minutes}M`;
  };

  const renderTimer = () => {
    if (!initialized) return null;

    const { auction, buy, offer } = timers;

    // Show clock icon if any timer is active
    const showClock =
      props.showClock &&
      (auction?.isCompleted === false ||
        buy?.isCompleted === false ||
        offer?.isCompleted === false);

    return (
      <>
        {showClock && <i className="far fa-clock" />} {/* Auction Timer */}
        {item.auction === 1 && auction && !auction.isCompleted
          ? auction.text === "Bidding Starts In"
            ? moment(item?.start_date).isValid()
              ? moment(item?.start_date).format("MM/DD/YY at LT")
              : ""
            : `${props.showText ? auction.status : ""} ${formatTime(auction)}`
          : null}
        {/* Buy Now Timer */}
        {item.buynow === 1 && buy && !buy.isCompleted
          ? buy.text === "Bidding Starts In"
            ? moment(item?.b_date_added).isValid()
              ? moment(item?.b_date_added).format("MM/DD/YY at LT")
              : ""
            : `${props.showText ? buy.status : ""} ${formatTime(buy)}`
          : null}
        {/* Offer Timer */}
        {item.offer === 1 && offer && !offer.isCompleted
          ? offer.text === "Bidding Starts In"
            ? moment(item?.o_date_added).isValid()
              ? moment(item?.o_date_added).format("MM/DD/YY at LT")
              : ""
            : `${props.showText ? offer.status : ""} ${formatTime(offer)}`
          : null}
        {/* Status Display */}
        {(auction?.isCompleted ||
          // !buy ||
          // buy?.isCompleted ||
          // !offer ||
          // offer?.isCompleted ||
          (!auction && !buy && !offer)) && (
          <span className="text-capitalize">
            {item.auction === 0
              ? ""
              : item.market_status === "open"
                ? "Ended"
                : item.market_status === "sold"
                  ? "Product Sold"
                  : item.listing_status === "expired"
                    ? "Listing Closed"
                    : item.listing_status === "under_contract"
                      ? "Under Contract"
                      : item.listing_status}
          </span>
        )}
      </>
    );
  };

  return item?.id ? renderTimer() : null;
};

export default Timer;
