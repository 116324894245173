import React, { useEffect, useRef, useState, useContext } from "react";
import SearchCard from "../../../components/atoms/SearchCard";
import GlobalContext from "../../../context/GlobalContext";
import ReactDOM from "react-dom";
import { Button } from "@material-ui/core";
import { throttle } from "lodash";

let map = null;
let markers = [];
let poly = null;
let isMouseDown = false;
let shapes = [];

const GoogleMap = (props) => {
  const mapRef = useRef(null);

  const site_id = global?.storeDetails?.site_id;
  const { currentTime, headerLocation } = useContext(GlobalContext);

  const [propertys, setPropertys] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const currentZoomRef = useRef(null);
  const isUserInteractionRef = useRef(null);
  const initMapLoad = () => {
    if (!map) {
      const urlParams = new URLSearchParams(window.location.search);

      const savedZoom =
        parseInt(urlParams.get("zoom_level")) || props?.zoomLevel || 13;

      const mapOptions = {
        zoom: savedZoom,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
        mapTypeControl: false,
        gestureHandling: "greedy",
      };

      map = new window.google.maps.Map(
        document.getElementById("map"),
        mapOptions,
      );

      currentZoomRef.current = savedZoom;
      // currentZoomRef.current = map.getZoom();

      // if (props.selectedLocationBound) {
      //   applyBoundsDebounced(props.selectedLocationBound);
      // }

      applyBoundsDebounced(null, true);

      // setTimeout(() => {
      //   map.setZoom(savedZoom);
      //   // Force the zoom to stick
      //   const checkZoom = () => {
      //     if (map.getZoom() !== savedZoom) {
      //       map.setZoom(savedZoom);
      //     }
      //   };
      //   // Check zoom multiple times to ensure it sticks
      //   setTimeout(checkZoom, 100);
      //   setTimeout(checkZoom, 500);
      // }, 100);

      map.addListener("dragstart", () => {
        isUserInteractionRef.current = true;
      });

      map.addListener("dragend", () => {
        if (isUserInteractionRef.current) {
          let newPos = map.getCenter()?.toJSON();
          let newBounds = map.getBounds()?.toJSON();
          let currentZoom = map.getZoom();
          if (newPos && newBounds && !isDrawing) {
            props.handleMapCenterChange(newPos, newBounds, currentZoom);
          }
        }
        isUserInteractionRef.current = false;
      });

      map.addListener("zoom_changed", () => {
        const newZoom = map.getZoom();
        if (newZoom !== currentZoomRef.current) {
          if (!isUserInteractionRef.current) {
            isUserInteractionRef.current = true;
          }
          if (isUserInteractionRef.current) {
            let newPos = map.getCenter()?.toJSON();
            let newBounds = map.getBounds()?.toJSON();
            if (newPos && newBounds && !isDrawing) {
              props.handleMapCenterChange(newPos, newBounds, newZoom);
            }
            isUserInteractionRef.current = false;
          }
          currentZoomRef.current = newZoom;
        }
      });
    }

    if (markers.length) {
      markers.forEach((marker) => marker.setMap(null));
      markers = [];
    }

    if (props.multipleLocation && locations.length > 0 && propertys.length) {
      let currentInfoWindow = null;

      locations.map((location) => {
        const marker = new window.google.maps.Marker({
          position: location.latlng,
          map: map,
          title: location.name,
        });
        markers.push(marker);

        const infoWindow = new window.google.maps.InfoWindow();
        const div = document.createElement("div");

        // const property = propertys.find(
        //   (property) => property.id === location.id
        // );

        // if (property) {
        //   ReactDOM.render(
        //     <SearchCard
        //       item={property}
        //       timercurrent={currentTime}
        //       site_id={site_id}
        //     />,
        //     div
        //   );
        //   infoWindow.setContent(div);
        // }

        if (location) {
          ReactDOM.render(
            <SearchCard
              item={location}
              timercurrent={currentTime}
              site_id={site_id}
              from={"map"}
            />,
            div,
          );
          infoWindow.setContent(div);
        }

        marker.addListener("click", () => {
          if (currentInfoWindow) {
            currentInfoWindow.close();
          }
          infoWindow.open(map, marker);
          currentInfoWindow = infoWindow;
        });

        map.addListener("click", function () {
          infoWindow.close();
        });
      });
    }
  };

  const applyBoundsDebounced = (bounds, getBoundFromURL) => {
    if (map && (bounds || getBoundFromURL)) {
      let mapBounds;

      if (getBoundFromURL) {
        const urlParams = new URLSearchParams(window.location.search);
        mapBounds = {
          south: parseFloat(urlParams.get("south")),
          west: parseFloat(urlParams.get("west")),
          north: parseFloat(urlParams.get("north")),
          east: parseFloat(urlParams.get("east")),
        };
      } else if (bounds) {
        mapBounds = {
          south: Number(bounds.south),
          west: Number(bounds.west),
          north: Number(bounds.north),
          east: Number(bounds.east),
        };
      }

      if (mapBounds) {
        const urlParams = new URLSearchParams(window.location.search);
        const savedZoom =
          parseInt(urlParams.get("zoom_level")) || props?.zoomLevel || 13;

        isUserInteractionRef.current = false;
        map.fitBounds(mapBounds);

        setTimeout(() => {
          map.setZoom(savedZoom);
          currentZoomRef.current = savedZoom;
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (window.google && window?.google?.maps) {
      initMapLoad();
    } else {
      if (!map) {
        window.initMapLoad = initMapLoad;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMapLoad`;
        script.async = true;
        script.defer = true;
        script.onerror = () => {
          console.error("Google Maps script loading error");
        };
        document.head.appendChild(script);
      }
    }

    return () => {
      if (map) {
        const googleMapsScript = document.querySelector(
          'script[src*="maps.googleapis.com"]',
        );
        if (googleMapsScript) {
          googleMapsScript.remove();
        }
        // if (window.google) {
        //   delete window.google;
        // }
        map = null;
      }
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     props.selectedLocationBound &&
  //     Object.keys(props.selectedLocationBound).length !== 0
  //   ) {
  //     applyBoundsDebounced(props.selectedLocationBound);
  //   }
  // }, [props.selectedLocationBound]);

  // useEffect(() => {
  //   if (headerLocation.get && Object.keys(headerLocation.get).length !== 0) {
  //     applyBoundsDebounced(props.selectedLocationBound);
  //   }
  // }, [headerLocation.get]);

  useEffect(() => {
    const checkResetAndApplyBounds = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const reset = urlParams.get("reset");

      if (reset === "true") {
        applyBoundsDebounced(null, true);
        urlParams.delete("reset");
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${urlParams.toString()}`,
        );
      }
    };

    checkResetAndApplyBounds();
  }, [window.location.search]);

  useEffect(() => {
    if (window.google && window.google.maps) {
      props.setLoaded(true);
      initMapLoad();
    } else {
      props.setLoaded(false);
    }
  }, [locations, propertys]);

  useEffect(() => {
    setLocations(props.locations);
  }, [props.locations]);

  useEffect(() => {
    if (props.viewProperties) {
      setPropertys(props.viewProperties);
    }
  }, [props.viewProperties]);

  const startDrawing = (event) => {
    if (!isDrawing) return;
    isMouseDown = true;

    const path = [event.latLng];
    poly = new window.google.maps.Polyline({
      path: path,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
      map: map,
    });
    window.google.maps.event.addListener(map, "mousemove", continueDraw);
  };

  const stopDrawing = (event) => {
    if (!isDrawing || !poly || !isMouseDown) return;

    isMouseDown = false;
    google.maps.event.clearListeners(map, "mousemove"); // Remove the mousemove listener

    const path = poly.getPath();
    if (event.latLng) {
      path.push(event.latLng);
    }

    // Check if the shape is nearly closed
    const startPoint = path.getAt(0);
    const endPoint = path.getAt(path.getLength() - 1);
    const threshold = 0.0001; // Adjust this value to change the auto-close sensitivity

    if (
      Math.abs(startPoint.lat() - endPoint.lat()) < threshold &&
      Math.abs(startPoint.lng() - endPoint.lng()) < threshold
    ) {
      // Close the shape
      path.push(startPoint);
    } else {
      // Auto-complete the shape
      path.push(startPoint);
    }

    // Convert Polyline to Polygon
    const polygon = new google.maps.Polygon({
      paths: path.getArray(),
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: map,
    });

    shapes.push(polygon);

    // Remove the polyline
    poly.setMap(null);
    poly = null;

    // // Reset drawing state
    // setIsDrawing(false);
    // map.setOptions({ draggable: true });

    // const bound = new google.maps.LatLngBounds();
    // const boundobject = {};
    // path.forEach((latLng) => {
    //   bound.extend(latLng);
    // });

    // const northEast = bound.getNorthEast();
    // const southWest = bound.getSouthWest();

    // boundobject.north = northEast.lat();
    // boundobject.east = northEast.lng();
    // boundobject.south = southWest.lat();
    // boundobject.west = southWest.lng();

    // map.fitBounds(bound)
  };

  // useEffect(() => {
  //   if (props.viewProperties) {
  //     setPropertys(props.viewProperties);
  //   }
  // }, [props.viewProperties]);

  const handleMouseUp = (event) => {
    if (isMouseDown) {
      stopDrawing(event);
    }
  };

  const continueDraw = (event) => {
    if (!isDrawing || !poly || !isMouseDown) return;

    const path = poly.getPath();
    path.push(event.latLng);
  };
  const initMap = () => {
    // let newPos = mapRef.current.getCenter()?.toJSON();
    // map = new google.maps.Map(document.getElementById("map"), {
    //   center: { lat: -34.397, lng: 150.644 },
    //   zoom: 8,
    // });
    if (map) {
      window.google.maps.event.addListener(map, "mousedown", startDrawing);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const resetDrawing = () => {
    // Remove all shapes from the map
    shapes.forEach((shape) => shape.setMap(null));
    shapes = [];

    // Reset any ongoing drawing
    if (poly) {
      poly.setMap(null);
      poly = null;
    }
    // props.resetLocations(headerLocation.get);
    applyBoundsDebounced(null, true);
    setIsDrawing(false);
    isMouseDown = false;
    if (map) {
      map.setOptions({ draggable: true });
    }
  };

  useEffect(() => {
    if (isDrawing && map) {
      initMap();
    }
  }, [isDrawing, map]);

  const toggleDrawing = () => {
    setIsDrawing(true);
    if (map) {
      map.setOptions({ draggable: isDrawing });
    }
  };

  const getAllShapeBounds = (shapes) => {
    return shapes.map((shape) => {
      let bounds = new google.maps.LatLngBounds();
      shape.getPath().forEach((latLng) => {
        bounds.extend(latLng);
      });
      return {
        north: bounds.getNorthEast().lat(),
        east: bounds.getNorthEast().lng(),
        south: bounds.getSouthWest().lat(),
        west: bounds.getSouthWest().lng(),
      };
    });
  };

  const applyBound = () => {
    if (shapes.length > 0) {
      const finalShapes = getAllShapeBounds(shapes);
      props.handleMultipleBounds(finalShapes);
    }
  };

  return (
    <>
      {isDrawing ? (
        <div className="drawPropertyBtns">
          <p className="drawPara">
            Draw a shape around the region(s) you would like to live in
          </p>
          <div className="d-flex align-items-center justify-content-end">
            <Button onClick={resetDrawing} className="mr-2">
              Cancel
            </Button>
            <Button onClick={applyBound}>Apply</Button>
          </div>
        </div>
      ) : (
        <div className="drawSection">
          <Button onClick={toggleDrawing}>
            {isDrawing ? "Cancel Drawing" : "Draw"}
          </Button>
          {/* <Button onClick={resetDrawing}>Reset</Button> */}
        </div>
      )}
      {/* <button onClick={applyBound}>Apply</button> */}
      <div
        id="map"
        ref={mapRef}
        style={{
          width: props?.width || "auto",
          height: "100%",
        }}
      ></div>
    </>
  );
};

export default GoogleMap;
