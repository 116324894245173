import React, { useContext, useState, useEffect } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import MenuLinks from "../DashboardLayout/MenuLinks";
import GlobalContext from "../../../context/GlobalContext";
import { logout } from "../../../API/userData";
import "./Header.css";
import Badge from "@material-ui/core/Badge";
import { ModalContext } from "../../../context/ModalContext";
import { Button, ListItem, Popover } from "@material-ui/core";
import { LockOutlined, PersonAdd } from "@material-ui/icons";
const WebLinks = ({ store_data, props }) => {
  const { storeDetails } = useContext(GlobalContext);
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const location = useLocation();
  const history = useHistory();
  const [markAnchormenu, setMarkAnchormenu] = useState(null);
  const [helpAnchormenu, setHelpAnchormenu] = useState(null);
  const {
    userLoggedIn,
    userDetails,
    userLocation,
    agentVerification,
    sentInvites,
    receivedInvites,
    financeDetails,
    billingDetails,
    agentVerificationDetails,
    verificationStatus,
  } = useContext(GlobalContext);
  const [verificationCount, setVerificationCount] = useState(0);

  const calculateVerificationCount = () => {
    let count = 0;
    let notificationStatusPayload =
      userDetails.get["profiletype"] === "agent"
        ? agentVerification.get
        : userDetails.get["profiletype"] === "customer" ||
            userDetails.get["profiletype"] === "builder" ||
            userDetails.get["profiletype"] === "bidder"
          ? verificationStatus.get
          : "";
    if (notificationStatusPayload) {
      for (const property in notificationStatusPayload) {
        if (!notificationStatusPayload[property]) {
          count += 1;
        }
      }
    }
    setVerificationCount(count);
  };

  function refreshPage() {
    window.location.reload();
  }
  const handleMarkMenu = (event) => {
    setMarkAnchormenu(event.currentTarget);
  };
  const handleMarkClose = () => {
    setMarkAnchormenu(null);
  };
  const handleHelpMenu = (event) => {
    setHelpAnchormenu(event.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchormenu(null);
  };

  useEffect(() => {
    if (userDetails && userDetails.get) {
      calculateVerificationCount();
    }
  }, [verificationStatus.get, agentVerification.get, userDetails.get]);

  return (
    <ul className="navbar-nav ml-auto align-items-md-center nvOptns">
      {store_data != (null || undefined) &&
        storeDetails?.headers
          ?.filter((item) => item.label !== "")
          .map((item) => {
            return (
              <li key={item.id}>
                <a href={item.url} target="_blank">
                  {item.label}
                </a>
              </li>
            );
          })}
      <li className="nav-item">
        <Button
          aria-controls="marketDrop"
          aria-haspopup="true"
          className="marketPlaceButton"
          onClick={handleMarkMenu}
        >
          Marketplace <span className="material-icons">expand_more</span>
        </Button>
        <Popover
          id={"marketDrop"}
          open={Boolean(markAnchormenu)}
          anchorEl={markAnchormenu}
          onClose={handleMarkClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ul className="marketDropMenu">
            <NavLink
              exact
              to={`/search?${global.defaultLocation}&product_type=Bank+Owned%2CBank+Owned%2CForeclosure%2CShort+Sale%2CTraditional%2Call%2Cauction%2Call`}
              activeClassName={location.search?.includes("all") ? "active" : ""}
            >
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">All Real Estate</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink
              exact
              to={`/search?${global.defaultLocation}&product_type=auction`}
              activeClassName={
                location.search?.includes("auction") ? "active" : ""
              }
            >
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Auctions</h4>
                </div>
              </ListItem>
            </NavLink>
            {/* {searchData.filter((item) => item.product_type === "Bank Owned")
                      .length > 0 && ( */}
            <NavLink
              exact
              to={`/search?${global.defaultLocation}&product_type=Bank+Owned`}
              activeClassName={
                location.search?.includes("Bank+Owned") ? "active" : ""
              }
            >
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Bank Owned</h4>
                </div>
              </ListItem>
            </NavLink>
            {/* )} */}
            {/* {searchData.filter((item) => item.product_type === "Foreclosure")
                      .length > 0 && ( */}
            <NavLink
              exact
              to={`/search?${global.defaultLocation}&product_type=Foreclosure`}
              activeClassName={
                location.search == "?product_type=Foreclosure" ? "active" : ""
              }
            >
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Foreclosure</h4>
                </div>
              </ListItem>
            </NavLink>
            {/* )} */}
            {/* {searchData.filter((item) => item.product_type === "Short Sale")
                      .length > 0 && ( */}
            <NavLink
              exact
              to={`/search?${global.defaultLocation}&product_type=Short+Sale`}
              activeClassName={
                location.search == "?product_type=Short+Sale" ? "active" : ""
              }
            >
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Short Sale</h4>
                </div>
              </ListItem>
            </NavLink>
            {/* )} */}
            {/* {searchData.filter((item) => item.product_type === "Traditional")
                      .length > 0 && ( */}
            <NavLink
              exact
              to={`/search?${global.defaultLocation}&product_type=Traditional`}
              activeClassName={
                location.search == "?product_type=Traditional" ? "active" : ""
              }
            >
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Traditional</h4>
                </div>
              </ListItem>
            </NavLink>
            {/* )} */}
          </ul>
        </Popover>
      </li>
      <li className="nav-item">
        <Button
          aria-controls="helpDrop"
          aria-haspopup="true"
          className="marketPlaceButton"
          onClick={handleHelpMenu}
        >
          Help Center <span className="material-icons">expand_more</span>
        </Button>
        <Popover
          id={"helpDrop"}
          open={Boolean(helpAnchormenu)}
          anchorEl={helpAnchormenu}
          onClose={handleHelpClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ul className="helpDropMenu">
            <NavLink exact to="/faq" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">FAQs</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/about_us" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">About Us</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/contact_us" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Contact Us</h4>
                </div>
              </ListItem>
            </NavLink>
          </ul>
        </Popover>
      </li>

      {userLoggedIn.get === true ? (
        <>
          {store_data !== (null || undefined) ? (
            store_data?.headers?.map((item) => {
              return (
                <li key={item.id}>
                  <a href={item.url}>{item.label}</a>
                </li>
              );
            })
          ) : (
            <>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={`/search?${global.defaultLocation}`}
                >
                  View all listings
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href="/submit-a-home"
                  className={
                    window.location.pathname.includes("/choose-plan") ||
                    window.location.pathname.includes("/post-listing")
                      ? "active"
                      : null
                  }
                >
                  Submit a home
                </a>
              </li>
            </>
          )}
          <li className="dropdown nav-item">
            <a className="dropdown-toggle d-flex align-items-center">
              <span className="user-circle">
                <i className="fa fa-user"></i>
              </span>{" "}
              {userDetails.get.first_name}
            </a>
            <div className="dropdown-menu">
              <NavLink to={`/profile`} activeClassName="active">
                Profile
              </NavLink>
              <NavLink to={`/watchlist`} activeClassName="active">
                Watchlist
              </NavLink>
              <Link
                to=""
                onClick={async () => {
                  logout();
                  localStorage.removeItem("userToken");
                  userLoggedIn.set(false);
                  userDetails.set({});
                  sentInvites.set([]);
                  receivedInvites.set([]);
                  financeDetails.set({});
                  billingDetails.set([]);
                  agentVerificationDetails.set({});
                  // refreshPage();
                }}
              >
                Logout
              </Link>
            </div>
          </li>
        </>
      ) : (
        <div className="d-flex pl-3" style={{ gap: "15px" }}>
          <li className="nav-item">
            <div className="d-flex align-items-center loginReg">
              <LockOutlined />
              <li className="nav-item">
                <a onClick={() => setLoginOpen(true)}>Login</a>
              </li>
              {/* <span>/</span> */}
            </div>
          </li>
          <li className="nav-item">
            <div className="d-flex align-items-center loginReg">
              <PersonAdd />
              <li className="nav-item">
                <a onClick={() => setSignupOpen(true)}>Register</a>
              </li>
            </div>
          </li>
          {/* <li className="nav-item">
            <Link
              to={{ pathname: "/signup", state: location }}
              className="btn btn-rg borderViolet"
            >
              Signup
            </Link>
          </li> */}
        </div>
      )}
    </ul>
  );
};

export default WebLinks;
