import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../../components/molecules/AuthLayout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import GlobalContext from "../../../context/GlobalContext";
import axios from "../../../utils/axiosconfig";
import { showSuccess, showWaring } from "../../../utils";

const ForgotPassword = (props) => {
  const forgotSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required!"),
  });
  const { loading } = useContext(GlobalContext);
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const handleForgotPass = async (body) => {
    loading.set(true);
    let { data } = await axios.post("forgetPasswordReset", body, {
      headers: { site_id },
    });
    loading.set(false);
    if (data.success === true) {
      showSuccess(data.reason);
      setTimeout(() => {
        history.push(`/`);
      }, 2000);
    } else {
      showWaring(data.reason || "Something went wrong");
    }
  };

  return (
    <AuthLayout title="Forgot Password">
      <p>Enter your email address to receive a reset password link</p>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={forgotSchema}
        onSubmit={(value) => handleForgotPass(value)}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            className="form py-2 m-0"
            onSubmit={handleSubmit}
            noValidate
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            <div className="form-group">
              <CustomInput
                label="Email Address"
                value={values.email}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.email && touched.email && "is-invalid"
                }`}
                placeholder="Enter Your Email Address"
                required
              />
              {errors.email && touched.email ? (
                <div className="invalid-feedback">{errors.email}</div>
              ) : null}
            </div>
            <PrimaryButton
              label="Submit"
              type="submit"
              disabled={loading.get}
            />
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ForgotPassword;
