import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Layout from "../../../components/molecules/Layout";
import SearchGrid from "../../../components/molecules/Search/SearchGrid";
import SearchCard from "../../../components/atoms/SearchCard";
import GlobalContext from "../../../context/GlobalContext";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import qs from "qs";
import "./search.css";
import { getSearch, saveSearch, updateSavedSearch } from "../../../API/search";
import { toast } from "react-toastify";
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Popover,
  Slider,
  Tab,
  Tabs,
  useForkRef,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CheckBox from "../../../components/atoms/CheckBox";
import { indexOf, min } from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useToggle from "../../../utils/useToggle";
import { stateCode } from "../../../utils";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import PriceInput from "../../../components/atoms/Inputs/PriceInput";
import { numberWithCommas, callNumber } from "../../../utils";
import { getLocation } from "../../../API/userData";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useLocation, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Pagination from "../../../components/atoms/Pagination";
import SearchFilter from "../../../components/molecules/Search/SearchFilter";
import CustomSelect from "../../../components/atoms/Inputs/CustomSelect";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import RadioBox from "../../../components/atoms/RadioBox";
import MapComponent from "../../../components/molecules/Search/mapRefer";
import SecondaryButton from "../../../components/atoms/SecondaryButton";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import Featured from "../Featured";
import { debounce } from "lodash";
import socket from "../../../utils/socket";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "275px",
    marginBottom: "10px",
    height: "45px",
  },
  mobileFormControl: {
    margin: theme.spacing(1),
    width: "163px",
    marginBottom: "5px",
  },
  formControldiv: {
    width: "300px",
  },
  mobileFormControldiv: {
    width: "170px",
    marginBottom: "3px",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 100;
  const left = 98;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Search = (props) => {
  let propertyType_val = "";
  let productType_val = "";
  const location = useLocation();
  const classes = useStyles();
  const [drawSearch, setDrawSearch] = useState(true);
  const [viewProperties, setViewProperties] = useState([]);
  const [viewAllProperties, setViewAllProperties] = useState([]);
  const [viewPropertyLocations, setViewPropertyLocations] = useState([]);
  const [propertyType, setPropertyType] = useState("");
  const [triggered, setTriggered] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [isBoundSet, setIsBoundSet] = useState(false);
  const [mobileSelectOpen, setMobileSelectOpen] = useState(false);
  const [mobilePropertyPopupOpen, setMobilePropertyPopupOpen] = useState(false);
  const [mobileSelectedPropertyId, setMobileSelectedPropetyId] = useState("");
  const [selectedLocationBound, setSelectedLocationBound] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isbathChecked, setIsbathChecked] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const history = useHistory();

  const [sqt, setSqt] = useState({
    minSize: "",
    maxSize: "",
  });

  const [beds, setBeds] = useState({
    minBeds: "",
    maxBeds: "",
  });

  const [baths, setBaths] = useState({
    minBaths: "",
    maxBaths: "",
  });

  const [body, setBody] = useState(undefined);
  const [isLoaded, setLoaded] = useState(false);
  const [getCount, setCount] = useState(0);
  const [propertyLoc, setPropertyLoc] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [page, setPage] = useState(1);
  const { userLocation, userLoggedIn, headerLocation, storeDetails } =
    useContext(GlobalContext);
  const [state, setState] = useState({
    right: false,
  });
  const width = useMediaQuery("(min-width:992px)");
  const [map, toggleMap] = useToggle(false);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const [open, setOpen] = useState({
    proType: false,
    priceRange: false,
    count: false,
    homeType: false,
    more: false,
  });
  const anchorRef = useRef(null);
  const anchorRefPR = useRef(null);
  const anchorRefCount = useRef(null);
  const anchorRefHomeType = useRef(null);
  const anchorRefMore = useRef(null);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemv2, setSelectedItemv2] = useState();
  const [selectedItemv3, setSelectedItemv3] = useState();
  const [selectedItemv4, setSelectedItemv4] = useState();
  const [selectedItemv5, setSelectedItemv5] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [btnColor, setBtnColor] = useState({ btn_color: "", btn_type: "" });
  const [btnColor1, setBtnColor1] = useState({ btn_color: "", btn_type: "" });
  const [searchQuery, setSearchQuery] = useState("");

  // const handleSearchClick = () => {
  //   const cities = searchQuery;
  //   const state = searchQuery;
  //   const page = 1;

  //   history.push(
  //     `/search?searchbar=${searchQuery}&cities=&state=&page=1&sortby=1&status=coming_soon%2Copen&type=Single+Family%2CMulti+Family%2CCondominium+%2F+Townhome%2CMobile+%2F+Manufactured&radius=0&load=true&zoom=false&listing_type=3%2C2%2C1"`
  //   );
  // };
  // useEffect(() => {
  //   if (site_id != (null || undefined)) {
  //     propertyLocation();
  //   }
  // }, [site_id]);

  // const propertyLocation = async () => {
  //   let data = await getLocation(site_id);

  //   if (data && data.length > 0) {
  //     setPropertyLoc(data);
  //   }
  // if (location && location.search === "") {
  //   props.history.replace({
  //     pathname: location.pathname,
  //     search: `?cities=${data[0].city}&state=${data[0].state}&page=1&sortby=1&status=accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium,Mobile / Manufactured&lat=${data[0].lat}&long=${data[0].lng}`,
  //   });
  // }
  // };

  // useEffect(() => {
  //   if (propertyLoc.length > 0 && Object.keys(selectedLocation).length > 0) {
  //     let headerLocationToBeSelected = propertyLoc.filter(
  //       (location) =>
  //         location.lat === selectedLocation.lat &&
  //         location.lng === selectedLocation.lng &&
  //         location.coming_soon === "false"
  //     )[0];
  //     if (headerLocationToBeSelected) {
  //       headerLocation.set(headerLocationToBeSelected);
  //     }
  //   }
  // }, [propertyLoc, selectedLocation]);

  // useEffect(() => {
  //   if (Object.keys(userLocation.get).length !== 0) {
  //     setCenter([
  //       Number(userLocation.get["lon"]) || 0.0,
  //       Number(userLocation.get["lat"]) || 0.0,
  //     ]);
  //     if (!props.location.search) {
  //       props.history.replace({
  //         pathname: props.location.pathname,
  //         search: `?cities=${userLocation.get["city"]}&state=${userLocation.get["state"]}&page=1&sortby=1&status=accepting_offers,coming_soon`,
  //       });
  //     }
  //   }
  // }, [userLocation.get]);

  useEffect(() => {
    if (props.location.search) {
      let query = qs.parse(window.location.search?.slice(1));

      setPage(Number(query.page) || 1);
      setBody({
        page: query.page || 1,
        limit: 6,
        // cities: query.cities ? query.cities.split(",") : "",
        title: query.title || "",
        ids: query.ids ? query.ids.split(",") : "",
        sortby: query.sortby || "1",
        maxPrice: query.maxPrice || "",
        minPrice: query.minPrice || "",
        status: query.status ? query.status.split(",") : "",
        listing_type: query.listing_type ? query.listing_type.split(",") : "",
        occupency_type: query.occupency_type
          ? query.occupency_type.split(",")
          : "",
        minbeds: query.minBed || "",
        maxSqft: query.maxSqft || "",
        minSqft: query.minSqft || "",
        minLotSize: query.minLotSize || "",
        maxLotSize: query.maxLotSize || "",
        minYearbuilt: query.minYearbuilt || "",
        maxYearbuilt: query.maxYearbuilt || "",
        parking: query.parking || "",
        exact_bathroom: query.exact_bathroom ? query.exact_bathroom : false,
        exact_bedroom: query.exact_bedroom ? query.exact_bedroom : false,
        maxbeds: query.maxBed || "",
        garage: query.garage == "yes" ? "yes" : "no",
        is_open_house: query.is_open_house == "yes" ? "yes" : "no",
        is_3D_Tour: query.is_3D_Tour == "yes" ? "yes" : "no",
        keywords: query.keywords ? query.keywords : "",
        minbathroom: query.minBath || "",
        bedroom: query.bedroom || "",
        bathroom: query.bathroom || "",
        maxbathroom: query.maxBath || "",
        property_type: query.property_type
          ? query.property_type.split(",")
          : "",
        product_type: query.product_type ? query.product_type.split(",") : "",
        minSize: query.minSize ? query.minSize.toString() : "",
        maxSize: query.maxSize ? query.maxSize.toString() : "",
        type: query.type ? query.type.split(",") : "",
        rPrice: query.rPrice,
        bound:
          query.north && query.south && query.east && query.west
            ? {
                north: query.north,
                south: query.south,
                east: query.east,
                west: query.west,
              }
            : {},
        zoom_level: query.zoom_level || "13",
        initial: true,
        searchbar: query.searchbar || "",
        search_url: window.location.href,
      });
      setIsChecked(query.exact_bedroom == "true" ? true : false);
      setIsbathChecked(query.exact_bathroom == "true" ? true : false);
      setSelectedLocationBound({
        north: query.north,
        south: query.south,
        east: query.east,
        west: query.west,
      });
    }
  }, [props.location.search]);

  useEffect(() => {
    if (body?.product_type == "all") {
      let query = new URLSearchParams(props.location.search);
      query?.set(
        "product_type",
        [
          "Bank Owned",
          "Foreclosure",
          "Short Sale",
          "Traditional",
          "all",
          "auction",
        ].toString(),
      );
      props.history.replace({
        pathname: props.location.pathname,
        search: "?" + query.toString(),
      });
    }
  }, [body]);

  const searchURL = window.location.href;

  const copy = () => window.navigator.clipboard.writeText(searchURL);

  useEffect(() => {
    if (props.location && props.location.search) {
      let url = new URLSearchParams(window.location.search);
      let urlValue = {};
      urlValue["lat"] = url.get("lat");
      urlValue["lng"] = url.get("long");
      urlValue["title"] = url.get("title");
      urlValue["cities"] = url.get("cities");
      urlValue["state"] = url.get("state");
      urlValue["radius"] = url.get("radius");
      if (
        url.get("north") &&
        url.get("south") &&
        url.get("east") &&
        url.get("west") &&
        url.get("initial") === true
      ) {
        setIsBoundSet(true);
      } else {
        setIsBoundSet(false);
      }
      setSelectedLocation(urlValue);
    }
  }, [props.location.search]);

  useEffect(() => {
    if (location.search == "") {
      props.history.replace({
        search: "?" + global.defaultLocation,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (body?.product_type == "all") {
      let query = new URLSearchParams(props.location.search);
      query?.set(
        "product_type",
        [
          "Bank Owned",
          "Foreclosure",
          "Short Sale",
          "Traditional",
          "all",
          "auction",
        ].toString(),
      );
      props.history.replace({
        pathname: props.location.pathname,
        search: "?" + query.toString(),
      });
    }
  }, [body]);

  useEffect(() => {
    if (site_id != null && body !== undefined) {
      getAllProperties();
    }
  }, [body, site_id]);

  const getChecked = (name, value) => {
    if (name === "rPrice") {
      if (body && Object.keys(body).length !== 0) {
        return body[name] == value;
      } else {
        return false;
      }
    } else {
      if (body && Object.keys(body).length !== 0) {
        let data = body[name] ? body[name] : [];
        return indexOf(data, value) !== -1;
      } else {
        return false;
      }
    }
  };

  const searchPageChange = (event, page) => {
    setPage(page);
    let url = new URLSearchParams(window.location.search);
    url.set("page", page);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const saveClose = (e) => setAnchorEl(null);

  const [searchDetails, setSearchDetails] = useState({
    searchName: "",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const searchName = queryParams.get("searchbar");
    if (searchName) {
      setSearchDetails((prevDetails) => ({ ...prevDetails, searchName }));
    }
  }, [history.location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const [openMenu1, setOpenMenu] = useState(false);

  const validationSchema = Yup.object({
    searchName: Yup.string()
      .required("Search name cannot be empty")
      .min(3, "Search name should be at least 3 characters"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchName: searchDetails.searchName || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (userLoggedIn.get === false) {
        formik.setFieldError("searchName", "Login to save search");
        return;
      }

      const body = {
        title: values.searchName,
        site_id,
        search_url: window.location.href,
      };
      const data =
        values.searchName != ("" || undefined)
          ? await saveSearch(body, site_id)
          : await updateSavedSearch(body, site_id);

      if (data.status === true) {
        toast.success("Search saved successfully");
        setAnchorEl(null);
      } else {
        formik.setFieldError("searchName", "Unable to save search");
      }
    },
  });

  const onKeyDown = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        anchorEl &&
        !anchorEl.contains(event.target) &&
        !event.target.closest(".saved-menu")
      ) {
        setAnchorEl(null);
      }
    };

    if (openMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenu, anchorEl]);

  const searchData = useSelector((state) => state.search?.searchData);

  const getAllProperties = async (boundsArray) => {
    let property = { ...body };
    if (boundsArray) {
      property.boundsArray = boundsArray;
    } else if (body.bound) {
      property.bound = body.bound;
    }

    let data = await getSearch(property, site_id);
    if (data.status === true) {
      setCount(data["total_count"]);
      setViewProperties([...data.result["productdetails"]]);
      setViewAllProperties([...data.result["mapProperties"]]);
      setTriggered(true);
    }
  };

  const handlePropertyUpdate = useCallback((data) => {
    setViewProperties((currentProperties) => {
      const index = currentProperties.findIndex(
        (s) => s.id === parseInt(data.productId, 10),
      );

      if (index === -1) return currentProperties;

      const updatedProperties = [...currentProperties];
      const productToChange = { ...updatedProperties[index] };

      if (Number(data.belowFive) === 1) {
        productToChange.date_closed = data.belowFiveIncrement;
      }

      updatedProperties[index] = {
        ...productToChange,
        bidcount: data.bidCount,
        bidCount: data.bidCount,
        wprice: data.wPrice,
      };

      return updatedProperties;
    });

    setViewAllProperties((currentProperties) => {
      const index = currentProperties.findIndex(
        (s) => s.id === parseInt(data.productId, 10),
      );

      if (index === -1) return currentProperties;

      const updatedProperties = [...currentProperties];
      const productToChange = { ...updatedProperties[index] };

      if (Number(data.belowFive) === 1) {
        productToChange.date_closed = data.belowFiveIncrement;
      }

      updatedProperties[index] = {
        ...productToChange,
        bidcount: data.bidCount,
        bidCount: data.bidCount,
        wprice: data.wPrice,
      };

      return updatedProperties;
    });
  }, []);

  useEffect(() => {
    // Set up socket listener
    const handleBidAddTime = (data) => {
      handlePropertyUpdate(data);
    };

    socket.on("bidAddtime", handleBidAddTime);

    // Cleanup socket listener
    return () => {
      socket.off("bidAddtime", handleBidAddTime);
    };
  }, [handlePropertyUpdate]);

  const onSortBy = (e) => {
    let query = new URLSearchParams(props.location.search);
    query.set("page", 1);
    query.set("sortby", e.target.value);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
    return false;
  };

  const onChangePrice = (e) => {
    let query = new URLSearchParams(props.location.search);
    query.set("page", 1);
    query.set(e.target.name, e.target.value);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onChange = (e) => {
    if (props.location.search) {
      if (e.target.name === "rPrice") {
        let url = new URLSearchParams(props.location.search);
        url.set("page", 1);
        url.set(e.target.name, e.target.checked ? e.target.value : "");
        props.history.replace({
          pathname: props.location.pathname,
          search: "?" + url.toString(),
        });
      } else {
        let query = qs.parse(props.location.search, {
          ignoreQueryPrefix: true,
        });
        let data = query[e.target.name] ? query[e.target.name].split(",") : [];
        if (e.target.checked) {
          data = [e.target.value, ...data];
        } else {
          const position = indexOf(data, e.target.value);
          position !== -1 && data.splice(position, 1);
        }
        let url = new URLSearchParams(props.location.search);
        url.set("page", 1);
        url.set(e.target.name, data.toString());
        props.history.replace({
          pathname: props.location.pathname,
          search: "?" + url.toString(),
        });
      }
    }
  };

  const onRangeChange = (e, newValue) => {
    let newSqt = { ...sqt };
    newSqt[e.target.name] = e.target.value;
    setSqt(newSqt);
    let url = new URLSearchParams(props.location.search);
    url.set("page", 1);
    url.set("minSize", newSqt.minSize.length !== 0 ? newSqt.minSize : "");
    url.set("maxSize", newSqt.maxSize.length !== 0 ? newSqt.maxSize : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const filterChangeFunc = (e, key, type) => {
    let query = new URLSearchParams(window.location.search);
    if (key === "bedroom") {
      setBtnColor({ btn_color: e, btn_type: key });
      if (e !== "") {
        query.set("exact_bedroom", isChecked);
      }
    } else if (key === "bathroom") {
      setBtnColor1({ btn_color: e, btn_type: key });
    }

    query.set("page", 1);
    let allProperty = "";
    if (type == "check") {
      allProperty = body[key] == "yes" ? "no" : "yes";
    } else if (type == "button") {
      allProperty = e;
    } else {
      allProperty = e.target.value;
    }

    query.set(key, allProperty);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };

  const onchangeBedroom = (e) => {
    let fieldValue = e.target.value.replace(/[^\d]/g, "");
    let newBedroom = { ...beds };
    newBedroom[e.target.name] = fieldValue;
    setBeds(newBedroom);

    let url = new URLSearchParams(props.location.search);
    url.set("page", 1);
    url.set("minBed", newBedroom.minBeds.length ? newBedroom.minBeds : "");
    url.set("maxBed", newBedroom.maxBeds.length ? newBedroom.maxBeds : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const onChangeBathroom = (e) => {
    let fieldValue = e.target.value.replace(/[^\d]/g, "");
    let newBathroom = { ...baths };
    newBathroom[e.target.name] = fieldValue;
    setBaths(newBathroom);
    let url = new URLSearchParams(props.location.search);
    url.set("page", 1);
    url.set("minBath", newBathroom.minBaths.length ? newBathroom.minBaths : "");
    url.set("maxBath", newBathroom.maxBaths.length ? newBathroom.maxBaths : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const getRangeP = useMemo(() => {
    return body
      ? `${body.minSize ? body.minSize + " Sq.Ft" : "0 Sq.Ft"} to ${
          body.maxSize ? body.maxSize + " Sq.Ft" : "1000+ Sq.Ft"
        }`
      : "0 Sq.Ft to 1000+ Sq.Ft";
  }, [body]);

  const getRange = useMemo(() => {
    return body
      ? [
          body.minSize ? Number(body.minSize) / 10 : 0,
          body.maxSize ? Number(body.maxSize) / 10 : 100,
        ]
      : [0, 100];
  }, [body]);

  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    function onScroll() {
      let currentPosition =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 480;
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (viewAllProperties && triggered) {
      let mapLocations = [];
      viewAllProperties.length > 0 &&
        window.google &&
        window.google.maps &&
        viewAllProperties.map((property) =>
          mapLocations.push({
            ...property,
            // id: property.id,
            // city: property.loc_city,
            // state: property.state,
            latlng: new window.google.maps.LatLng(
              parseFloat(property.lat ? property.lat : 0),
              parseFloat(property.lng ? property.lng : 0),
            ),
          }),
        );
      setViewPropertyLocations(mapLocations);
      viewAllProperties.length > 0 && setMobileSelectOpen(false);
    }
  }, [viewAllProperties, triggered]);

  const handleMapCenterChange = (newCenter, newBound, changeZoom) => {
    if (!newBound || !newCenter) return;

    let url = new URLSearchParams(window.location.search);
    let updatedBody = { ...body };

    if (newCenter) {
      url.set("lat", newCenter.lat);
      url.set("long", newCenter.lng);

      updatedBody = {
        ...updatedBody,
        lat: newCenter.lat,
        lng: newCenter.lng,
      };
    }

    if (newBound) {
      url.set("north", newBound.north);
      url.set("south", newBound.south);
      url.set("east", newBound.east);
      url.set("west", newBound.west);

      updatedBody = {
        ...updatedBody,
        bound: {
          north: newBound.north,
          south: newBound.south,
          east: newBound.east,
          west: newBound.west,
        },
      };
    }

    if (changeZoom) {
      url.set("zoom_level", changeZoom);

      updatedBody = {
        ...updatedBody,
        zoom_level: changeZoom,
      };
    }

    url.set("cities", "");
    url.set("state", "");
    url.set("status", body.status);
    url.set("initial", true);

    setBody(updatedBody);

    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const handleMultipleBounds = (boundsArray) => {
    getAllProperties(boundsArray);
  };

  const calculateNewLatLong = (startingPoint, radiusMiles, bearing) => {
    //bearing for (n, e, s, w) = (0, 90, 180, 270)

    let numDegrees = Number(radiusMiles) / 69;

    if (bearing === 0) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 90) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 180) {
      return Number(startingPoint) - numDegrees;
    } else if (bearing === 270) {
      return Number(startingPoint) - numDegrees;
    }
  };

  const handleLocationChng = (e) => {
    let selectedValue = propertyLoc.filter((ele) => {
      if (ele.id === Number(e.target.value)) {
        return ele;
      }
    });
    setSelectedLocation(selectedValue);
    let url = new URLSearchParams(location.search);
    url.set("cities", "");
    url.set("state", "");
    url.set("page", 1);
    url.set("title", selectedValue[0].title);
    url.set("lat", selectedValue[0].lat);
    url.set("long", selectedValue[0].lng);
    url.set(
      "north",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 0),
    );
    url.set(
      "south",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 180),
    );
    url.set(
      "east",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 90),
    );
    url.set(
      "west",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 270),
    );
    url.set("initial", true);
    url.set("load", false);
    headerLocation.set(selectedValue);

    if (location.pathname.includes(`/search`)) {
      history.replace({
        pathname: location.pathname,
        search: "?" + url.toString(),
        state: {
          path: location.pathname,
        },
      });
    } else {
      history.push({
        pathname: `/search`,
        // search: `?cities=${selectedValue[0].city}&state=${selectedValue[0].state}&page=1&sortby=1&status=past,accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${selectedValue[0].lat}&long=${selectedValue[0].lng}`,
        search: `?cities=&state=&title=${
          selectedValue[0].title
        }&page=1&sortby=1&listing_type=1,2,3&occupency_type=""&status=coming_soon,open&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${
          selectedValue[0].lat
        }&long=${selectedValue[0].lng}&&radius=${
          selectedValue[0].radius
        }&north=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          0,
        )}&south=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          180,
        )}&east=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          90,
        )}&west=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          270,
        )}&initial=true&zoom=false&load=false`,
        state: {
          path: location.pathname,
        },
      });
    }
  };

  const resetLocations = (selectedValue) => {
    let url = new URLSearchParams(window.location.search);
    url.set("cities", "");
    url.set("state", "");
    url.set("page", 1);
    url.set("title", selectedValue.title);
    url.set("lat", selectedValue.lat);
    url.set("long", selectedValue.lng);
    url.set(
      "north",
      calculateNewLatLong(selectedValue.lat, selectedValue.radius, 0),
    );
    url.set(
      "south",
      calculateNewLatLong(selectedValue.lat, selectedValue.radius, 180),
    );
    url.set(
      "east",
      calculateNewLatLong(selectedValue.lng, selectedValue.radius, 90),
    );
    url.set(
      "west",
      calculateNewLatLong(selectedValue.lng, selectedValue.radius, 270),
    );
    url.set("initial", true);
    url.set("load", false);
    headerLocation.set(selectedValue);
    setSelectedLocation(selectedValue);
    if (location.pathname.includes(`/search`)) {
      history.replace({
        pathname: location.pathname,
        search: "?" + url.toString(),
        state: {
          path: location.pathname,
        },
      });
      let query = qs.parse(window.location.search);
      setPage(Number(query.page) || 1);
      setBody({
        page: query.page || 1,
        limit: 6,
        // cities: query.cities ? query.cities.split(",") : "",
        //title: query.title || "",
        ids: query.ids ? query.ids.split(",") : "",
        sortby: query.sortby || "1",
        maxPrice: query.maxPrice || "",
        minPrice: query.minPrice || "",
        status: query.status ? query.status.split(",") : "",
        listing_type: query.listing_type ? query.listing_type.split(",") : "",
        occupency_type: query.occupency_type
          ? query.occupency_type.split(",")
          : "",
        minbeds: query.minBed || "",
        maxSqft: query.maxSqft || "",
        minSqft: query.minSqft || "",
        minLotSize: query.minLotSize || "",
        maxLotSize: query.maxLotSize || "",
        minYearbuilt: query.minYearbuilt || "",
        maxYearbuilt: query.maxYearbuilt || "",
        parking: query.parking || "",
        exact_bathroom: query.exact_bathroom ? query.exact_bathroom : false,
        exact_bedroom: query.exact_bedroom ? query.exact_bedroom : false,
        maxbeds: query.maxBed || "",
        garage: query.garage == "yes" ? "yes" : "no",
        is_open_house: query.is_open_house == "yes" ? "yes" : "no",
        is_3D_Tour: query.is_3D_Tour == "yes" ? "yes" : "no",
        keywords: query.keywords ? query.keywords : "",
        minbathroom: query.minBath || "",
        bedroom: query.bedroom || "",
        bathroom: query.bathroom || "",
        maxbathroom: query.maxBath || "",
        property_type: query.property_type
          ? query.property_type.split(",")
          : "",
        product_type: query.product_type ? query.product_type.split(",") : "",
        minSize: query.minSize ? query.minSize.toString() : "",
        maxSize: query.maxSize ? query.maxSize.toString() : "",
        type: query.type ? query.type.split(",") : "",
        rPrice: query.rPrice,
        bound:
          query.north && query.south && query.east && query.west
            ? {
                north: calculateNewLatLong(
                  selectedValue.lat,
                  selectedValue.radius,
                  0,
                ),
                south: calculateNewLatLong(
                  selectedValue.lat,
                  selectedValue.radius,
                  180,
                ),
                east: calculateNewLatLong(
                  selectedValue.lng,
                  selectedValue.radius,
                  90,
                ),
                west: calculateNewLatLong(
                  selectedValue.lng,
                  selectedValue.radius,
                  270,
                ),
              }
            : {},
        initial: true,
      });
      setSelectedLocationBound({
        north: calculateNewLatLong(selectedValue.lat, selectedValue.radius, 0),
        south: calculateNewLatLong(
          selectedValue.lat,
          selectedValue.radius,
          180,
        ),
        east: calculateNewLatLong(selectedValue.lng, selectedValue.radius, 90),
        west: calculateNewLatLong(selectedValue.lng, selectedValue.radius, 270),
      });
      setBtnColor({ btn_color: query.bedroom, btn_type: "bedroom" });
      setBtnColor1({ btn_color: query.bathroom, btn_type: "bathroom" });
    } else {
      history.push({
        pathname: `/search`,
        // search: `?cities=${selectedValue[0].city}&state=${selectedValue[0].state}&page=1&sortby=1&status=past,accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${selectedValue[0].lat}&long=${selectedValue[0].lng}`,
        search: `?cities=&state=&title=${
          selectedValue[0].title
        }&page=1&sortby=1&listing_type=1,2,3&occupency_type=""&status=coming_soon,open&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${
          selectedValue[0].lat
        }&long=${selectedValue[0].lng}&&radius=${
          selectedValue[0].radius
        }&north=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          0,
        )}&south=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          180,
        )}&east=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          90,
        )}&west=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          270,
        )}&initial=true&zoom=false&load=false`,
        state: {
          path: location.pathname,
        },
      });
    }
  };

  const handleModalClose = () => {
    setMobilePropertyPopupOpen(false);
  };

  // const handlePropertyFilters = (dataType) => {
  //   switch (dataType) {
  //     case "proType":
  //       setOpen({ ...open, proType: true });
  //       break;
  //     case "priceRange":
  //       setOpen({ ...open, priceRange: true });
  //       break;
  //     case "count":
  //       setOpen({ ...open, count: true });
  //       break;
  //     case "homeType":
  //       setOpen({ ...open, homeType: true });
  //       break;
  //     case "more":
  //       setOpen({ ...open, more: true });
  //       break;
  //     default:
  //       // Handle other cases or do nothing
  //       break;
  //   }
  // };

  // const handlePropertyType = () => {
  //   setOpen((prevOpen) => ({
  //     ...prevOpen,
  //     proType: !prevOpen.proType,
  //   }));
  // };

  // const handlePriceRange = () => {
  //   setOpen((prevOpen) => ({
  //     ...prevOpen,
  //     priceRange: !prevOpen.priceRange,
  //   }));
  // };

  // const handleCount = () => {
  //   setOpen((prevOpen) => ({
  //     ...prevOpen,
  //     count: !prevOpen.count,
  //   }));
  // };

  // const handleHomeType = () => {
  //   setOpen({
  //     ...open,
  //     homeType: true,
  //   });
  // };

  // const handleMoreOption = () => {
  //   setOpen({
  //     ...open,
  //     more: true,
  //   });
  // };

  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setOpen({
  //       proType: false,
  //       priceRange: false,
  //       count: false,
  //       homeType: false,
  //       more: false,
  //     }); // Close the menu
  //   }
  // }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = useRef([
  //   open.proType,
  //   open.priceRange,
  //   open.count,
  //   open.homeType,
  //   open.more,
  // ]);

  // useEffect(() => {
  //   if (prevOpen.current.proType === true && open.proType === false) {
  //     anchorRef.current.focus();
  //   }
  //   if (prevOpen.current.priceRange === true && open.priceRange === false) {
  //     anchorRefPR.current.focus();
  //   }
  //   if (prevOpen.current.count === true && open.count === false) {
  //     anchorRefCount.current.focus();
  //   }
  //   if (prevOpen.current.homeType === true && open.homeType === false) {
  //     anchorRefHomeType.current.focus();
  //   }
  //   if (prevOpen.current.more === true && open.more === false) {
  //     anchorRefMore.current.focus();
  //   }
  //   prevOpen.current = open;
  // }, [open]);

  // const handleMenuItemClick = (event, item) => {
  //   setSelectedItem(item); // Update the selected item
  //   setOpen({
  //     proType: false,
  //     priceRange: false,
  //     count: false,
  //     homeType: false,
  //     more: false,
  //   }); // Close the menu
  // };
  // const handleMenuItemClickl2 = (event, item) => {
  //   setSelectedItemv2(item); // Update the selected item
  //   setOpen({
  //     proType: false,
  //     priceRange: false,
  //     count: false,
  //     homeType: false,
  //     more: false,
  //   }); // Close the menu
  // };
  // const handleMenuItemClickl3 = (event, item) => {
  //   setSelectedItemv3(item); // Update the selected item
  //   setOpen({
  //     proType: false,
  //     priceRange: false,
  //     count: false,
  //     homeType: false,
  //     more: false,
  //   }); // Close the menu
  // };
  // const handleMenuItemClickl4 = (event, item) => {
  //   setSelectedItemv4(item); // Update the selected item
  // };
  // const handleMenuItemClickl5 = (event, item) => {
  //   setSelectedItemv5(item); // Update the selected item
  //   setOpen({
  //     proType: false,
  //     priceRange: false,
  //     count: false,
  //     homeType: false,
  //     more: false,
  //   }); // Close the menu
  // };

  const bathroomPartions = [
    {
      label: "Any",
      name: "",
    },
    {
      label: "1+",
      name: 1,
    },
    {
      label: "1.5+",
      name: 1.5,
    },
    {
      label: "2",
      name: 2,
    },
    {
      label: "3",
      name: 3,
    },
    {
      label: "4",
      name: 4,
    },
  ];
  const bedroomPartions = [
    {
      label: "Any",
      name: "",
    },
    {
      label: "1",
      name: 1,
    },
    {
      label: "2",
      name: 2,
    },
    {
      label: "3",
      name: 3,
    },
    {
      label: "4",
      name: 4,
    },
    {
      label: "5",
      name: 5,
    },
  ];

  const houseTypes = [
    {
      label: "Single Family",
      name: "Single Family",
    },
    {
      label: "Condo/Townhouse/Co-Op",
      name: "Condo/Townhouse/Co-Op",
    },
    {
      label: "Duplex",
      name: "Duplex",
    },
    {
      label: "Multifamily",
      name: "Multifamily",
    },
    {
      label: "Mobile",
      name: "Mobile",
    },
    {
      label: "Manufactured",
      name: "Manufactured",
    },
    {
      label: "Modular",
      name: "Modular",
    },
    {
      label: "Lots/Lands ",
      name: "Lots/Lands ",
    },
  ];

  const propertyStatus = [
    {
      label: "Coming Soon",
      name: "coming_soon",
    },
    {
      label: "Active",
      name: "open",
    },
    {
      label: "Offer Pending",
      name: "pending",
    },
    {
      label: "Under Contract",
      name: "under_contract",
    },
    {
      label: "Sold",
      name: "sold",
    },
  ];

  const occupancyOptions = [
    {
      label: "Vacant",
      name: "vacant",
    },
    {
      label: "Occupied",
      name: "occupied",
    },
    {
      label: "Unknown",
      name: "unknown",
    },
  ];

  const assetTypeItems = [
    { name: "Bank Owned", label: "Bank Owned" },
    { name: "Foreclosure", label: "Foreclosure" },
    { name: "Short Sale", label: "Short Sale" },
    { name: "Traditional", label: "Traditional" },
  ];
  // Filter Button section Functions
  const anchorRefs = useRef({});
  const [value, setValue] = useState(0);
  const [anchorSetEl, setAnchorSetEl] = useState(null);
  const [checkedradioItems, setCheckedRadioItems] = useState("");
  const [checkedItems, setCheckedItems] = useState(houseTypes);
  const [checkedAssetItems, setCheckedAssetItems] = useState(assetTypeItems);

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    // setOpen(false);
    setAnchorSetEl(null);
  };

  const handleCheckboxChange = (e) => {
    let query = new URLSearchParams(window.location.search);
    query.set("page", 1);
    let allProperty = [];
    if (body.property_type.includes(e.target.value)) {
      allProperty = body.property_type.filter(
        (data) => data !== e.target.value,
      );
    } else {
      allProperty = [...body.property_type, e.target.value];
    }

    propertyType_val = allProperty;

    query.set(
      "property_type",
      allProperty.length ? allProperty.toString() : "",
    );
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };

  const handleAssetChange = (e) => {
    let query = new URLSearchParams(window.location.search);
    let allProduct = [];
    if (body.product_type.includes(e.target.value)) {
      if (e.target.value == "all") {
        allProduct = [];
      } else {
        allProduct = body.product_type.filter(
          (data) => data !== e.target.value,
        );
      }
    } else {
      if (e.target.value == "all") {
        let assets = [
          "Bank Owned",
          "Foreclosure",
          "Short Sale",
          "Traditional",
          "all",
          "auction",
        ];
        allProduct = [...body.product_type, ...assets, e.target.value];
      } else {
        allProduct = [...body.product_type, e.target.value];
      }
    }

    productType_val = allProduct;
    query.set("product_type", allProduct.length ? allProduct.toString() : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };

  const handleSelectAllChange = () => {
    const newSelectAllState = !selectAll;
    setSelectAll(newSelectAllState);

    let allProperties = newSelectAllState
      ? checkedItems.map((item) => item.name)
      : [];
    setBody({ ...body, property_type: allProperties });

    let query = new URLSearchParams(window.location.search);
    query.set("page", 1);
    query.set(
      "property_type",
      allProperties.length ? allProperties.toString() : "",
    );
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };

  const handleCheckbox = (e) => {
    setIsChecked(!isChecked);
  };
  const handlebathCheckbox = (e) => {
    setIsbathChecked(!isbathChecked);
  };
  const handleStatusChange = (e) => {
    let query = new URLSearchParams(window.location.search);
    query.set("page", 1);
    let allProperty = [];
    if (body.status.includes(e.target.value)) {
      allProperty = body.status.filter((data) => data !== e.target.value);
    } else {
      allProperty = [...body.status, e.target.value];
    }
    query.set("status", allProperty.length ? allProperty.toString() : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };
  const handleOccupencyChange = (e) => {
    let query = new URLSearchParams(window.location.search);
    query.set("page", 1);
    let allOccupency = [];
    if (body.occupency_type.includes(e.target.value)) {
      allOccupency = body.occupency_type.filter(
        (data) => data !== e.target.value,
      );
    } else {
      allOccupency = [...body.occupency_type, e.target.value];
    }
    query.set(
      "occupency_type",
      allOccupency.length ? allOccupency.toString() : "",
    );
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };
  const checkedCount = checkedItems.filter((item) => item.checked).length;
  // Handle toggle handleRadioChange
  const handleRadioChange = (event) => {
    setCheckedRadioItems(event.target.value);
  };
  const moreFilterHandleChange = (event, newValue) => {
    setValue(newValue);
  };
  const moreFiltrehandleChangeIndex = (index) => {
    setValue(index);
  };
  const handlePropertyFilters = (e, key) => {
    anchorRefs.current[key] = e.key;
    setAnchorSetEl(e);
  };

  const googleMapFilterButtons = [
    { label: "Asset Type", content: "asset_type" },
    { label: "Price Range", content: "price" },
    { label: "Beds & Baths", content: "bed" },
    { label: "Property Type", content: "home" },
    { label: "More", content: "additional" },
  ];

  const resetMore = () => {
    let url = new URLSearchParams(global.defaultLocation);
    url.set("reset", "true");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  // End
  return (
    <Layout>
      <div className="srt-wrapper topMapFilterBox">
        <div className="search-filters-wrap">
          {!width && (
            <FormControl
              variant="outlined"
              className={`${
                !width ? classes.mobileFormControl : classes.formControl
              } mblSrchFrm`}
            >
              {/* <Select
                id="demo-simple-select-outlined"
                value={headerLocation.get["id"]}
                onChange={handleLocationChng}
                displayEmpty
                style={{ height: "45px" }}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                className={classes.selectEmpty}
                open={mobileSelectOpen}
                onOpen={() => setMobileSelectOpen(true)}
                onClose={() => setMobileSelectOpen(false)}
              >
                <MenuItem value="" disabled>
                  View Listings
                </MenuItem>
                {propertyLoc &&
                  propertyLoc.length > 0 &&
                  propertyLoc.map((ele) => (
                    <MenuItem
                      key={ele.id}
                      value={ele.id}
                      disabled={ele.coming_soon === "true" ? true : false}
                    >
                      {ele.coming_soon === "true" ? (
                        <em>{`${ele.title} (Coming Soon)`}</em>
                      ) : (
                        `${ele.title}`
                      )}
                    </MenuItem>
                  ))}
              </Select> */}
            </FormControl>

            // <div className="selectWrapper ooooooo">
            //   <select
            //     className="form-control wd-240"
            //     value={headerLocation.get["id"]}
            //     onChange={handleLocationChng}
            //   >
            //     <option>View Lisitngs</option>
            //     {propertyLoc &&
            //       propertyLoc.length > 0 &&
            //       propertyLoc.map((ele) => (
            //         <option
            //           key={ele.id}
            //           value={ele.id}
            //           disabled={ele.coming_soon === "true" ? true : false}
            //         >
            //           {ele.city},{stateCode(ele.state)}{" "}
            //           {ele.coming_soon === "true" ? "(Coming Soon)" : null}
            //         </option>
            //       ))}
            //   </select>
            // </div>
          )}
          <div className="w-100">
            <div className="d-flex align-item-center justify-content-start PropertyfilterButtonGroup">
              {googleMapFilterButtons.map((data, index) => (
                <div key={index} className="PropertyfilterButton">
                  <Button
                    onClick={() => handlePropertyFilters(data.label, index)}
                    aria-controls={
                      anchorSetEl == data.label ? "menu-list-grow" : undefined
                    }
                    aria-haspopup="true"
                    className="filterBtn"
                    // ref={(el) => {
                    //   console.warn(el, "anchor===>");
                    //   if (el) anchorRefs.current[label] = el;
                    // }}
                    ref={(el) => (anchorRefs.current[data.label] = el)}
                  >
                    {data.label}
                    <span className="material-icons-outlined">
                      keyboard_arrow_down
                    </span>
                  </Button>
                  <Popper
                    open={anchorSetEl == data.label}
                    anchorEl={anchorRefs.current[data.label]}
                    placement={
                      data.label == "More" ? "bottom-end" : "bottom-start"
                    }
                    style={{ minWidth: "200px", zIndex: 99 }}
                    onClose={(e) => handleClose(e)}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <div className="PropertyfilterButtonGroup">
                        {" "}
                        {data.content == "asset_type" ? (
                          <div id="menu-list-grow">
                            <h5 className="filterMenuTit">Asset Type</h5>
                            <MenuList className="priceRangeMenuBody p-3">
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="all-real-estate"
                                  label="All Real Estate"
                                  value="all"
                                  checked={
                                    body && body?.product_type.includes("all")
                                      ? true
                                      : false
                                  }
                                  onchange={(e) => handleAssetChange(e)}
                                />
                              </MenuItem>
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="auction"
                                  label="Auctions"
                                  value="auction"
                                  checked={
                                    body &&
                                    body?.product_type.includes("auction")
                                      ? true
                                      : false
                                  }
                                  onchange={(e) => handleAssetChange(e)}
                                />
                              </MenuItem>
                              {/* {searchData.filter(
                                (item) => item.product_type === "Bank Owned"
                              ).length > 0 && ( */}
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="Bank Owned"
                                  label="Bank Owned"
                                  value="Bank Owned"
                                  checked={
                                    body &&
                                    body?.product_type.includes("Bank Owned")
                                      ? true
                                      : false
                                  }
                                  onchange={(e) => handleAssetChange(e)}
                                />
                              </MenuItem>
                              {/* )} */}
                              {/* {searchData.filter(
                                (item) => item.product_type === "Foreclosure"
                              ).length > 0 && ( */}
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="Foreclosure"
                                  label="Foreclosure"
                                  value="Foreclosure"
                                  checked={
                                    body &&
                                    body?.product_type.includes("Foreclosure")
                                      ? true
                                      : false
                                  }
                                  onchange={(e) => handleAssetChange(e)}
                                />
                              </MenuItem>
                              {/* )} */}
                              {/* {searchData.filter(
                                (item) => item.product_type === "Short Sale"
                              ).length > 0 && ( */}
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="Short Sale"
                                  label="Short Sale"
                                  value="Short Sale"
                                  checked={
                                    body &&
                                    body?.product_type.includes("Short Sale")
                                      ? true
                                      : false
                                  }
                                  onchange={(e) => handleAssetChange(e)}
                                />
                              </MenuItem>
                              {/* )} */}
                              {/* {searchData.filter(
                                (item) => item.product_type === "Traditional"
                              ).length > 0 && ( */}
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="Traditional"
                                  label="Traditional"
                                  value="Traditional"
                                  checked={
                                    body &&
                                    body?.product_type.includes("Traditional")
                                      ? true
                                      : false
                                  }
                                  onchange={(e) => handleAssetChange(e)}
                                />
                              </MenuItem>
                              {/* )} */}
                              {/* <PrimaryButton
                                className="filterSectionBtns mt-3"
                                onClick={() => handleClose()}
                              >
                                Apply
                              </PrimaryButton> */}
                            </MenuList>
                          </div>
                        ) : data.content == "price" ? (
                          <div
                            id="menu-list-grow"
                            className="priceRangeMenuBody"
                          >
                            <div
                              autoFocusItem={open.proType}
                              id="menu-list-grow"
                              // onKeyDown={handleListKeyDown}
                            >
                              <h5 className="filterMenuTit">Price Range</h5>
                              <div className="priceRangeMenuBody">
                                <div className="priceGridBox">
                                  <div className="suitable">
                                    <CustomSelect
                                      selectLabel={true}
                                      customLabel="Min Price"
                                      name="pricemin"
                                      value={body?.minPrice}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "minPrice")
                                      }
                                    >
                                      <option value={""}>Any Price</option>
                                      <option value={10000}>$10,000</option>
                                      <option value={25000}>$25,000</option>
                                      <option value={50000}>$50,000</option>
                                      <option value={75000}>$75,000</option>
                                      <option value={100000}>$100,000</option>
                                      <option value={150000}>$150,000</option>
                                      <option value={200000}>$200,000</option>
                                      <option value={300000}>$300,000</option>
                                      <option value={500000}>$500,000</option>
                                    </CustomSelect>
                                  </div>
                                  <span className="material-icons-outlined px-1 mt-4">
                                    remove
                                  </span>
                                  <div className="suitable">
                                    <CustomSelect
                                      selectLabel={true}
                                      customLabel="Max Price"
                                      name="pricemax"
                                      value={body?.maxPrice}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "maxPrice")
                                      }
                                    >
                                      <option value={10000}>$10,000</option>
                                      <option value={25000}>$25,000</option>
                                      <option value={50000}>$50,000</option>
                                      <option value={75000}>$75,000</option>
                                      <option value={100000}>$100,000</option>
                                      <option value={150000}>$150,000</option>
                                      <option value={200000}>$200,000</option>
                                      <option value={300000}>$300,000</option>
                                      <option value={500000}>$500,000</option>

                                      <option value={""}>Any Price</option>
                                    </CustomSelect>
                                  </div>
                                </div>
                                {/* <PrimaryButton
                                  className="filterSectionBtns mt-3"
                                  onClick={() => handleClose()}
                                >
                                  Apply
                                </PrimaryButton> */}
                              </div>
                            </div>
                          </div>
                        ) : data.content == "bed" ? (
                          <div
                            id="menu-list-grow"
                            className="priceRangeMenuBody"
                          >
                            <h5 className="filterMenuTit">
                              Number of Bedrooms
                            </h5>
                            <div className="priceRangeMenuBody">
                              <label className="dropHeadings">Bedrooms</label>
                              <div className="etOtIZ">
                                {bedroomPartions.map((data, index) => (
                                  <Button
                                    key={index}
                                    className={
                                      data.name == body?.bedroom
                                        ? "selectedBtn"
                                        : null
                                    }
                                    onClick={(e) =>
                                      filterChangeFunc(
                                        data.name,
                                        "bedroom",
                                        "button",
                                      )
                                    }
                                  >
                                    {!isChecked && data.label === "Any"
                                      ? "Studio"
                                      : data.label}
                                    {!isChecked && data.label !== "Any"
                                      ? "+"
                                      : null}
                                  </Button>
                                ))}
                              </div>
                            </div>
                            <div
                              className="ps-2 pl-2"
                              style={{ width: "fit-content" }}
                            >
                              <MenuItem className="homeTypeMultiCheck">
                                <CheckBox
                                  name="Use Exact"
                                  label="Use Exact Match"
                                  checked={isChecked}
                                  onchange={(e) => handleCheckbox(e)}
                                />
                              </MenuItem>
                            </div>
                            <h5 className="filterMenuTit">
                              Number of Bathrooms
                            </h5>
                            <div className="priceRangeMenuBody">
                              <label className="dropHeadings">Bathrooms</label>
                              <div className="etOtIZ">
                                {bathroomPartions.map((data, index) => (
                                  <Button
                                    key={index}
                                    className={
                                      data.name == body?.bathroom
                                        ? "selectedBtn"
                                        : null
                                    }
                                    onClick={() =>
                                      filterChangeFunc(
                                        data.name,
                                        "bathroom",
                                        "button",
                                      )
                                    }
                                  >
                                    {data.label}
                                  </Button>
                                ))}
                              </div>
                              <div
                                className="ps-2 pl-2"
                                style={{ width: "fit-content" }}
                              >
                                <MenuItem className="homeTypeMultiCheck">
                                  <CheckBox
                                    name="Use Exact"
                                    label="Use Exact Match"
                                    checked={isbathChecked}
                                    onchange={(e) => handlebathCheckbox(e)}
                                  />
                                </MenuItem>
                              </div>
                              {/* <PrimaryButton
                                className="filterSectionBtns mt-3"
                                onClick={() => handleClose()}
                              >
                                Apply
                              </PrimaryButton> */}
                            </div>
                          </div>
                        ) : data.content == "home" ? (
                          <div id="menu-list-grow">
                            <h5 className="filterMenuTit">Property Type</h5>
                            <MenuList className="priceRangeMenuBody p-3">
                              <div
                                className="selectAllChekbox"
                                onClick={handleSelectAllChange}
                              >
                                <span className="material-icons">
                                  check_circle
                                </span>{" "}
                                <div>
                                  {!selectAll ? "Select All" : "Deselect All"}
                                </div>
                              </div>
                              {checkedItems.map((data, index) => (
                                <MenuItem
                                  key={index}
                                  className="homeTypeMultiCheck"
                                >
                                  <CheckBox
                                    name={data.name}
                                    label={data.label}
                                    value={data.name}
                                    checked={
                                      body &&
                                      body?.property_type.includes(data.name)
                                        ? true
                                        : false
                                    }
                                    onchange={(e) => handleCheckboxChange(e)}
                                  />
                                </MenuItem>
                              ))}
                              {/* <PrimaryButton
                                className="filterSectionBtns mt-3"
                                onClick={() => handleClose()}
                              >
                                Apply
                              </PrimaryButton> */}
                            </MenuList>
                          </div>
                        ) : data.content == "additional" ? (
                          <div id="menu-list-grow">
                            <h5 className="filterMenuTit">
                              {data.content === "additional"
                                ? "Filters"
                                : "Filters"}
                            </h5>
                            <div className="priceRangeMenuBody p-0">
                              <div
                                className="innerScrollerBody"
                                style={{ padding: "0 10px" }}
                              >
                                {/* All Filter Sections Combined into One Dropdown */}
                                {/* <div>
                                  <label className="dropHeadings">
                                    All Purchase Options
                                  </label>
                                  <div className="listingtabTypes">
                                    {purchaseOptions.map((data, index) => (
                                      <MenuItem
                                        key={index}
                                        className="homeTypeMultiCheck"
                                      >
                                        <CheckBox
                                          name={data.name}
                                          label={data.label}
                                          value={data.name}
                                          checked={
                                            body &&
                                            body?.status.includes(data.name)
                                          }
                                          onchange={handleStatusChange}
                                        />
                                      </MenuItem>
                                    ))}
                                  </div>
                                </div> */}

                                <div>
                                  <label className="dropHeadings">
                                    Occupancy
                                  </label>
                                  <div className="listingtabTypes">
                                    {occupancyOptions.map((data, index) => (
                                      <MenuItem
                                        key={index}
                                        className="homeTypeMultiCheck"
                                      >
                                        <CheckBox
                                          name={data.name}
                                          label={data.label}
                                          value={data.name}
                                          checked={
                                            body &&
                                            body?.occupency_type.includes(
                                              data.name,
                                            )
                                          }
                                          onchange={handleOccupencyChange}
                                        />
                                      </MenuItem>
                                    ))}
                                  </div>
                                </div>

                                {/* <div>
                                  <label className="dropHeadings">
                                    Additional Options
                                  </label>
                                  <div className="listingtabTypes">
                                    {additionalOptions.map((data, index) => (
                                      <MenuItem
                                        key={index}
                                        className="homeTypeMultiCheck"
                                      >
                                        <CheckBox
                                          name={data.name}
                                          label={data.label}
                                          value={data.name}
                                          checked={
                                            body &&
                                            body?.status.includes(data.name)
                                          }
                                          onchange={handleStatusChange}
                                        />
                                      </MenuItem>
                                    ))}
                                  </div>
                                </div> */}

                                {/* More Filters Section */}
                                <div>
                                  <label className="dropHeadings">
                                    Property Status
                                  </label>
                                  <div className="listingtabTypes">
                                    {propertyStatus.map((data, index) => (
                                      <MenuItem
                                        key={index}
                                        className="homeTypeMultiCheck"
                                      >
                                        <CheckBox
                                          name={data.name}
                                          label={data.label}
                                          value={data.name}
                                          checked={
                                            body &&
                                            body?.status.includes(data.name)
                                          }
                                          onchange={handleStatusChange}
                                        />
                                      </MenuItem>
                                    ))}
                                  </div>
                                </div>

                                <div>
                                  <label className="filterLabel dropHeadings">
                                    Square Feet (sq. ft)
                                  </label>
                                  <div className="propertyFieldsGrid mb-2">
                                    <CustomSelect
                                      selectLabel={true}
                                      customLabel="No Min"
                                      name="bathrooms"
                                      value={body?.minSqft}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "minSqft")
                                      }
                                    >
                                      <option value={""}>No Min</option>
                                      <option value={500}>500 sq. ft</option>
                                      <option value={750}>750 sq. ft</option>
                                      <option value={1000}>1000 sq. ft</option>
                                      <option value={1250}>1250 sq. ft</option>
                                      <option value={1500}>1500 sq. ft</option>
                                      <option value={1750}>1750 sq. ft</option>
                                      <option value={2000}>2000 sq. ft</option>
                                      <option value={2250}>2250 sq. ft</option>
                                      <option value={2500}>2500 sq. ft</option>
                                      <option value={2750}>2750 sq. ft</option>
                                      <option value={3000}>3000 sq. ft</option>
                                      <option value={3500}>3500 sq. ft</option>
                                      <option value={4000}>4000 sq. ft</option>
                                      <option value={5000}>5000 sq. ft</option>
                                      <option value={6000}>6000 sq. ft</option>
                                      <option value={7000}>7000 sq. ft</option>
                                    </CustomSelect>
                                    <CustomSelect
                                      selectLabel={true}
                                      customLabel="No Max"
                                      name="bathrooms"
                                      value={body?.maxSqft}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "maxSqft")
                                      }
                                    >
                                      <option value={500}>500 sq. ft</option>
                                      <option value={750}>750 sq. ft</option>
                                      <option value={1000}>1000 sq. ft</option>
                                      <option value={1250}>1250 sq. ft</option>
                                      <option value={1500}>1500 sq. ft</option>
                                      <option value={1750}>1750 sq. ft</option>
                                      <option value={2000}>2000 sq. ft</option>
                                      <option value={2250}>2250 sq. ft</option>
                                      <option value={2500}>2500 sq. ft</option>
                                      <option value={2750}>2750 sq. ft</option>
                                      <option value={3000}>3000 sq. ft</option>
                                      <option value={5000}>5000 sq. ft</option>
                                      <option value={3500}>3500 sq. ft</option>
                                      <option value={7000}>7000 sq. ft</option>
                                      <option value={4000}>4000 sq. ft</option>
                                      <option value={6000}>6000 sq. ft</option>
                                      <option value={""}>No Max</option>
                                    </CustomSelect>
                                  </div>

                                  <label className="filterLabel dropHeadings">
                                    Lot Size
                                  </label>
                                  <div className="propertyFieldsGrid mb-2">
                                    <CustomSelect
                                      selectLabel={true}
                                      customLabel="No Min"
                                      name="minlot"
                                      value={body?.minLotSize}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "minLotSize")
                                      }
                                    >
                                      <option value={""}>No Min</option>
                                      <option value={1000}>1000 acres</option>
                                      <option value={2000}>2000 acres</option>
                                      <option value={3000}>3000 acres</option>
                                      <option value={4000}>4000 acres</option>
                                      <option value={5000}>5000 acres</option>
                                      <option value={7500}>7500 acres</option>
                                      <option value={4}>1/4 acres</option>
                                      <option value={4}>1/2 acres</option>
                                      <option value={4}>1 acres</option>
                                      <option value={4}>2 acres</option>
                                      <option value={4}>5 acres</option>
                                      <option value={4}>10 acres</option>
                                      <option value={4}>20 acres</option>
                                      <option value={4}>50 acres</option>
                                      <option value={4}>100 acres</option>
                                    </CustomSelect>
                                    <CustomSelect
                                      selectLabel={true}
                                      customLabel="No Max"
                                      name="maxlot"
                                      value={body?.maxLotSize}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "maxLotSize")
                                      }
                                    >
                                      <option value={1000}>1000 acres</option>
                                      <option value={2000}>2000 acres</option>
                                      <option value={3000}>3000 acres</option>
                                      <option value={4000}>4000 acres</option>
                                      <option value={5000}>5000 acres</option>
                                      <option value={7500}>7500 acres</option>
                                      <option value={4}>1/4 acres</option>
                                      <option value={4}>1/2 acres</option>
                                      <option value={4}>1 acres</option>
                                      <option value={4}>2 acres</option>
                                      <option value={4}>5 acres</option>
                                      <option value={4}>10 acres</option>
                                      <option value={4}>20 acres</option>
                                      <option value={4}>50 acres</option>
                                      <option value={4}>100 acres</option>
                                      <option value={""}>No Max</option>
                                    </CustomSelect>
                                  </div>

                                  <label className="filterLabel dropHeadings">
                                    Year Built
                                  </label>
                                  <div className="propertyFieldsGrid">
                                    <CustomInput
                                      placeholder="No Min"
                                      name={"min"}
                                      value={body?.minYearbuilt}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "minYearbuilt")
                                      }
                                    />
                                    <CustomInput
                                      placeholder="No Max"
                                      value={body?.maxYearbuilt}
                                      name={"max"}
                                      onChange={(e) =>
                                        filterChangeFunc(e, "maxYearbuilt")
                                      }
                                    />
                                  </div>
                                </div>

                                {/* Keywords Filter */}
                                <CustomInput
                                  customLabel="Keywords"
                                  inputLabel={true}
                                  name={"keyword"}
                                  value={body?.keywords}
                                  onChange={(e) =>
                                    filterChangeFunc(e, "keywords")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </ClickAwayListener>
                  </Popper>
                </div>
              ))}
              {/* <div className="PropertyfilterButton">
                <Button
                  ref={anchorRef}
                  aria-controls={open.proType ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handlePropertyType}
                  className="filterBtn"
                  // onClick={handlePropertyFilters("proType")}
                >
                  {checkedradioItems ? checkedradioItems : "Type"}
                  <span className="material-icons-outlined">
                    keyboard_arrow_down
                  </span>
                </Button>

                <Popper
                  open={open.proType}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement="bottom-start"
                  style={{ minWidth: "200px", zIndex: 99 }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div
                      autoFocusItem={open.proType}
                      id="menu-list-grow"
                      // onKeyDown={handleListKeyDown}
                      className="priceRangeMenuBody"
                    >
                      <RadioBox
                        name={"sale_type"}
                        items={propertyTypes}
                        value={checkedradioItems}
                        onchange={(e) => handleRadioChange(e)}
                        int={2}
                      />

                      <PrimaryButton className="filterSectionBtns mt-3">
                        Apply
                      </PrimaryButton>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div> */}
              {/* <div className="PropertyfilterButton">
                <Button
                  ref={anchorRefPR}
                  aria-controls={open.priceRange ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handlePropertyFilters("priceRange")}
                  Price Range
                  onClick={handlePriceRange}
                  className="filterBtn"
                >
                  {selectedItemv2 ? selectedItemv2 : "Price Range"}
                  Price Range
                  <span className="material-icons-outlined">
                    keyboard_arrow_down
                  </span>
                </Button>

                <Popper
                  open={open.priceRange}
                  anchorEl={anchorRefPR.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement="bottom-start"
                  style={{ maxWidth: "375px" }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div
                      autoFocusItem={open.proType}
                      id="menu-list-grow"
                      // onKeyDown={handleListKeyDown}
                    >
                      <h5 className="filterMenuTit">Price Range</h5>
                      <div className="priceRangeMenuBody">
                        <div className="priceGridBox">
                          <div className="suitable">
                            <CustomInput
                              value={
                                body
                                  ? callNumber(
                                      body.minPrice.split(",").join("")
                                    ) == "NaN"
                                    ? ""
                                    : Number(
                                        callNumber(
                                          body.minPrice.split(",").join("")
                                        )
                                      ) === 0
                                    ? ""
                                    : callNumber(
                                        body.minPrice.split(",").join("")
                                      )
                                  : ""
                              }
                              name="minPrice"
                              size="small"
                              label="Minimum"
                              onChange={onChangePrice}
                              placeholder="Min. Price"
                              className={`hh-input`}
                              startadornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                            />
                          </div>
                          <span class="material-icons-outlined px-1 mt-4">
                            remove
                          </span>
                          <div className="suitable">
                            <CustomInput
                              value={
                                body
                                  ? callNumber(
                                      body.maxPrice.split(",").join("")
                                    ) == "NaN"
                                    ? ""
                                    : Number(
                                        callNumber(
                                          body.maxPrice.split(",").join("")
                                        )
                                      ) === 0
                                    ? ""
                                    : callNumber(
                                        body.maxPrice.split(",").join("")
                                      )
                                  : ""
                              }
                              name="maxPrice"
                              size="small"
                              label="Maximum"
                              onChange={onChangePrice}
                              placeholder="Max. Price"
                              className={`hh-input`}
                              startadornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                            />
                          </div>
                        </div>
                        <PrimaryButton className="filterSectionBtns mt-3">
                          Apply
                        </PrimaryButton>
                      </div>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div> */}
              {/* <div className="PropertyfilterButton">
                <Button
                  ref={anchorRefCount}
                  aria-controls={open.count ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handlePropertyFilters("count")}
                  onClick={handleCount}
                  className="filterBtn"
                >
                  {selectedItemv3 ? selectedItemv3 : "Beds & Baths"}{" "}
                  Beds & Baths
                  <span className="material-icons-outlined">
                    keyboard_arrow_down
                  </span>
                </Button>

                <Popper
                  open={open.count}
                  anchorEl={anchorRefCount.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement="bottom-start"
                  style={{ maxWidth: "415px", zIndex: 99 }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div
                      autoFocusItem={open.count}
                      id="menu-list-grow"
                      // onKeyDown={handleListKeyDown}
                    >
                      <h5 className="filterMenuTit">Number of Bedrooms</h5>
                      <div className="priceRangeMenuBody">
                        <label>Bedrooms</label>
                        <div className="etOtIZ">
                          {bedroomPartions.map((data, index) => (
                            <Button key={index}>{data}</Button>
                          ))}
                        </div>
                      </div>
                      <h5 className="filterMenuTit">Number of Bathrooms</h5>
                      <div className="priceRangeMenuBody">
                        <label>Bathrooms</label>
                        <div className="etOtIZ">
                          {bathroomPartions.map((data, index) => (
                            <Button key={index}>{data}</Button>
                          ))}
                        </div>
                        <PrimaryButton className="filterSectionBtns mt-3">
                          Apply
                        </PrimaryButton>
                      </div>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div> */}
              {/* <div className="PropertyfilterButton">
                <Button
                  ref={anchorRefHomeType}
                  aria-controls={open.homeType ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handlePropertyFilters("homeType")}
                  onClick={handleHomeType}
                  className="filterBtn"
                >
                  Home Type{checkedCount != 0 ? checkedCount : null}
                  <span className="material-icons-outlined">
                    keyboard_arrow_down
                  </span>
                </Button>

                <Popper
                  open={open.homeType}
                  anchorEl={anchorRefHomeType.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement="bottom-start"
                  style={{ maxWidth: "fit-content", zIndex: 99 }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div
                      autoFocusItem={open.homeType}
                      id="menu-list-grow"
                      // onKeyDown={handleListKeyDown}
                    >
                      <h5 className="filterMenuTit">Home Type</h5>
                      <MenuList className="priceRangeMenuBody">
                        {checkedItems.map((data, index) => (
                          <MenuItem key={index} className="homeTypeMultiCheck">
                            <CheckBox
                              name={data.name}
                              label={data.label}
                              value={data.name}
                              checked={data.checked}
                              onchange={() => handleCheckboxChange(index)}
                            />
                          </MenuItem>
                        ))}
                        <PrimaryButton className="filterSectionBtns mt-3">
                          Apply
                        </PrimaryButton>
                      </MenuList>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div> */}
              {/* <div className="PropertyfilterButton">
                <Button
                  ref={anchorRefMore}
                  aria-controls={open.more ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handlePropertyFilters("more")}
                  onClick={handleMoreOption}
                  className="filterBtn"
                >
                  {selectedItemv5 ? selectedItemv5 : "More"}{" "}
                  More
                  <span className="material-icons-outlined">
                    keyboard_arrow_down
                  </span>
                </Button>

                <Popper
                  open={open.more}
                  anchorEl={anchorRefMore.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement="bottom-end"
                  style={{ width: "min(450px)", zIndex: 999 }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div
                      autoFocusItem={open.more}
                      id="menu-list-grow"
                      // onKeyDown={handleListKeyDown}
                    >
                      <h5 className="filterMenuTit">More Filters</h5>
                      <div className="priceRangeMenuBody p-0">
                        <div className="innerScrollerBody">
                          <CustomSelect
                            customLabel="Max HOA"
                            selectLabel={true}
                          />
                          <div>
                            <label>Listing type</label>
                            <div className="listingtabTypes">
                              <AppBar position="static" color="default">
                                <Tabs
                                  value={value}
                                  onChange={moreFilterHandleChange}
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="fullWidth"
                                  aria-label="full width tabs example"
                                >
                                  <Tab label="By Agent" {...a11yProps(0)} />
                                  <Tab
                                    label="By Owner and Others"
                                    {...a11yProps(1)}
                                  />
                                </Tabs>
                              </AppBar>
                              <div
                                className="moreFilterTabContent"
                                index={value}
                                onChangeIndex={moreFiltrehandleChangeIndex}
                              >
                                <div
                                  role="tabpanel"
                                  hidden={value !== 0}
                                  id={`full-width-tabpanel-${0}`}
                                  aria-labelledby={`full-width-tab-${0}`}
                                >
                                  {value === 0 && (
                                    <div className="listingTabContent">
                                      <CheckBox
                                        name="agent"
                                        label="Agent Listing"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div
                                  role="tabpanel"
                                  hidden={value !== 1}
                                  id={`full-width-tabpanel-${1}`}
                                  aria-labelledby={`full-width-tab-${1}`}
                                >
                                  {value === 1 && (
                                    <div className="listingTabContent">
                                      <CheckBox
                                        name="owner"
                                        label="Owner Posting"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <CustomSelect
                              customLabel="Parking spots"
                              selectLabel={true}
                            />
                            <p>Must have garage</p>
                          </div>
                          <div className="propertyAnglesCalulation mb-2">
                            <label className="filterLabel">Square feet</label>
                            <div className="propertyFieldsGrid mb-2">
                              <CustomSelect label="No Min" />
                              <CustomSelect label="No Max" />
                            </div>
                            <label className="filterLabel">Lot size</label>
                            <div className="propertyFieldsGrid mb-2">
                              <CustomSelect label="No Min" />
                              <CustomSelect label="No Max" />
                            </div>
                            <label className="filterLabel">Year built</label>
                            <div className="propertyFieldsGrid">
                              <CustomInput placeholder="No Min" />
                              <CustomInput placeholder="No Max" />
                            </div>
                          </div>
                          <CustomSelect
                            customLabel="Days on Zillow"
                            selectLabel={true}
                          />
                          <CustomInput
                            customLabel="Keywords"
                            inputLabel={true}
                          />
                        </div>
                        <div className="fixedFilterBottom p-3">
                          <SecondaryButton className="filterSectionBtns">
                            Reset all filters
                          </SecondaryButton>
                          <PrimaryButton className="filterSectionBtns">
                            Apply
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div> */}
              {width ? (
                <div className="ml-2">
                  <div>
                    <a
                      className="btn btn-primary"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      Save Search
                    </a>

                    <Menu
                      open={openMenu}
                      onClose={() => setAnchorEl(null)}
                      anchorEl={anchorEl}
                      id="saved-menu"
                      className="saved-menu"
                      variant="menu"
                    >
                      <MenuItem className="search-saved">
                        <div className="d-flex">
                          <i className="material-icons mr-2">check_circle</i>
                          Search Saved!
                        </div>
                        <a className="ss-copy-link" onClick={copy}>
                          Copy Link
                        </a>
                      </MenuItem>

                      <MenuItem>
                        <div className="d-flex flex-column">
                          <CustomInput
                            placeholder="Enter Search"
                            name="searchName"
                            label="Name your search"
                            value={formik.values.searchName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={onKeyDown}
                            error={
                              formik.touched.searchName &&
                              Boolean(formik.errors.searchName)
                            }
                            helperText={
                              formik.touched.searchName &&
                              formik.errors.searchName
                            }
                          />
                          {/* {formik.touched.searchName &&
                            formik.errors.searchName && (
                              <div
                                className="validation-message"
                                style={{color: "red", fontSize: "12px"}}
                              >
                                {formik.errors.searchName}
                              </div>
                            )} */}
                        </div>
                      </MenuItem>

                      <MenuItem>
                        <a
                          className="btn btn-primary"
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                        >
                          Update
                        </a>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              ) : (
                ""
              )}
              <SecondaryButton
                className="filterSectionBtns"
                onClick={resetMore}
              >
                Reset All Filters
              </SecondaryButton>
            </div>
            <div className="searchBarHere">
              {/* <FormControl>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Search address, city, state, county or zip code"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon onClick={handleSearchClick} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl> */}
            </div>
            {/* <div className="d-flex align-item-center justify-content-start">
              <div className="suitable">
                <a
                  className="btn btn-border"
                  onClick={toggleDrawer("right", true)}
                >
                  Filters{"  "}
                  {filterCount > 0 ? (
                    <span className="filterBadge">{filterCount}</span>
                  ) : (
                    ""
                  )}
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="search-wrapper">
        <div className={`search-left ${"mobile-sg-wrapper show-map"}`}>
          {selectedLocation && Object.keys(selectedLocation).length !== 0 && (
            // <SearchMap
            //   location={viewPropertyLocations}
            //   isDraw={drawSearch}
            //   userLocation={selectedLocation}
            //   viewProperties={viewProperties}
            //   handleMapCenterChange={handleMapCenterChange}
            //   mobileSelectedPropertyId={mobileSelectedPropertyId}
            //   setMobileSelectedPropetyId={setMobileSelectedPropetyId}
            //   setMobilePropertyPopupOpen={setMobilePropertyPopupOpen}
            //   mobilePropertyPopupOpen={mobilePropertyPopupOpen}
            //   width={width}
            //   selectedLocationBound={selectedLocationBound}
            // />
            <MapComponent
              multipleLocation={true}
              locations={viewPropertyLocations}
              fullscreenControl={true}
              // zoomLevel={40}
              viewProperties={viewPropertyLocations}
              resetLocations={resetMore}
              setLoaded={setLoaded}
              selectedLocation={selectedLocation}
              handleMapCenterChange={handleMapCenterChange}
              selectedLocationBound={selectedLocationBound}
              handleMultipleBounds={handleMultipleBounds}
              height={"100%"}
            />
          )}
          {/* <div
            className="search-bottom"
            onClick={() => setDrawSearch((preSearch) => !preSearch)}
            style={{ cursor: "pointer" }}
          >
            <a>
              <i className="fa fa-pencil-alt"></i> Draw Boundary
            </a>
          </div> */}
        </div>
        {/* {isLoaded ? ( */}
        <div className={`search-right ${"show-map"}`}>
          <div className="sr-top">
            {/* {width && (
              <> */}
            <h3 className="sr-top-head">
              {Object.keys(selectedLocation).length !== 0 &&
              !isBoundSet &&
              selectedLocation["title"] !== null
                ? `Property for Sale`
                : // `${selectedLocation["cities"]}, ${selectedLocation["state"]} Homes for Sale`
                  `Property for Sale`}
            </h3>
            <div className="d-flex glv-wrap">
              <div className="no-results">{`${getCount} Results`}</div>
              <div className="selectWrapper ss-wrap">
                <span>Sort by</span>
                <select
                  value={body ? body.sortby : "1"}
                  onChange={onSortBy}
                  className="form-control"
                >
                  <option value="1">Ending Soon</option>
                  <option value="2">Current Bid (High to Low)</option>
                  <option value="3">Current Bid (Low to High)</option>
                  <option value="4">Newest</option>
                  <option value="5">Most Comments</option>
                </select>
              </div>
            </div>
            {/* </>
            )} */}
          </div>
          {getCount == 0 && (
            <div className="card card-body text-center  p-3 mt-3 mb-3">
              No Products Found
            </div>
          )}
          <div
            className={width ? "sg-wrapper" : "sg-wrapper mobile-sg-wrapper"}
          >
            <SearchGrid properties={viewProperties} />
          </div>
          {!!viewProperties.length && getCount > 6 && (
            <div>
              <Pagination
                count={Math.ceil(getCount / 6)}
                page={page}
                onChange={searchPageChange}
                siblingCount={3}
                boundaryCount={2}
              />
            </div>
          )}
          {!width && !map ? (
            <div className={`map-sort ${scrollTop ? "bottom" : "top"}`}>
              <div className="map-toggle" onClick={toggleMap}>
                <i className="far fa-map"></i> Map
              </div>
              <div className="selectWrapper ss-wrap">
                <span className="sort-icon">
                  <i className="fas fa-sort-amount-down"></i>
                </span>
                <select
                  value={body ? body.sortby : "1"}
                  onChange={onSortBy}
                  className="form-control"
                >
                  <option value="1">Ending Soon</option>
                  <option value="2">Current Bid (High to Low)</option>
                  <option value="3">Current Bid (Low to High)</option>
                  <option value="4">Newest</option>
                  <option value="5">Most Comments</option>
                </select>
              </div>
            </div>
          ) : (
            <div
              className={`map-sort list-toggle ${scrollTop ? "bottom" : "top"}`}
            >
              <div className="map-toggle" onClick={toggleMap}>
                <i className="far fa-map"></i> List
              </div>
            </div>
          )}
        </div>
        {/* ) : null} */}
      </div>
      <Featured />
      <Modal
        open={mobilePropertyPopupOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {mobileSelectedPropertyId &&
          viewProperties.filter(
            (property) => property.id == mobileSelectedPropertyId,
          ).length > 0 ? (
            <SearchCard
              item={
                viewProperties.filter(
                  (property) => property.id == mobileSelectedPropertyId,
                )[0]
              }
              showTimer={true}
            />
          ) : (
            ""
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default Search;
