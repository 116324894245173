import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import MenuLinks from "../DashboardLayout/MenuLinks";
import GlobalContext from "../../../context/GlobalContext";
import { logout } from "../../../API/userData";
import CustomDialog from "../../atoms/CustomDialog";
import "./Header.css";
import { ModalContext } from "../../../context/ModalContext";
import { showSuccess, showWaring } from "../../../utils";
import { Button, ListItem, Popover } from "@material-ui/core";
import axiosInstance from "../../../utils/axiosconfig";
import { LockOutlined } from "@material-ui/icons";
import { getSearch } from "../../../API/search";
import { useDispatch, useSelector } from "react-redux";
import { filteredSearch } from "../../../store/slices/search";
import { getMyWishlist } from "../../../store/slices/wishlist";
const MobileLinks = ({ store_data, menu, props }) => {
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [markAnchormenu, setMarkAnchormenu] = useState(null);
  const [helpAnchormenu, setHelpAnchormenu] = useState(null);
  const handleHelpClose = () => {
    setHelpAnchormenu(null);
  };
  const handleHelpMenu = (event) => {
    setHelpAnchormenu(event.currentTarget);
  };
  const handleMarkClose = () => {
    setMarkAnchormenu(null);
  };
  const handleMarkMenu = (event) => {
    setMarkAnchormenu(event.currentTarget);
  };
  const dispatch = useDispatch();
  const searchData = useSelector((state) => state.search?.searchData);
  const wishlist = useSelector((state) => state.wishlist?.wishListItems);

  // useEffect(() => {
  //   if (!searchData || searchData.length === 0) {
  //     dispatch(filteredSearch());
  //   }
  // }, [dispatch, searchData]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const {
    userLoggedIn,
    userDetails,
    sentInvites,
    receivedInvites,
    financeDetails,
    billingDetails,
    agentVerificationDetails,
    loading,
  } = useContext(GlobalContext);
  const site_id = global?.storeDetails?.site_id;
  const location = useLocation();

  const handleLogin = async (formData) => {
    const body = {
      autologin: false,
      username:
        formData?.email?.trim() || "abdul.auctionsoftware+24hours1@gmail.com",
      password: formData?.password || "12345678",
      role: "not required",
    };
    loading.set(true);
    let { data } = await axiosInstance.post("/userLogin", body, {
      headers: {
        site_id,
      },
    });
    if (data.success === true) {
      showSuccess("Login Successful");
      localStorage.setItem("userToken", data.token);
      userLoggedIn.set(true);
      loading.set(false);
      setLoginOpen(false);
      setSignupOpen(false);
    } else {
      showWaring(data.reason || "Something went wrong");
      loading.set(false);
    }
  };

  useEffect(() => {
    if (
      userLoggedIn.get == true &&
      wishlist.length == (undefined || null || 0)
    ) {
      dispatch(getMyWishlist());
    }
  }, [dispatch]);

  return (
    <>
      <div
        className={`collapse navbar-collapse top-menu ${menu && "show"}`}
        id="navbar-nav"
      >
        <ul className=" nvOptns nav-mob-btns m-0 navbar-nav header-fst justify-content-end px-3">
          <li className="nav-item">
            <Button
              aria-controls="marketDrop"
              aria-haspopup="true"
              className="marketPlaceButton"
              onClick={handleMarkMenu}
            >
              Marketplace <span className="material-icons">expand_more</span>
            </Button>
            <Popover
              id={"marketDrop"}
              open={Boolean(markAnchormenu)}
              anchorEl={markAnchormenu}
              onClose={handleMarkClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ul className="marketDropMenu">
                <NavLink
                  exact
                  to={`/search?${global.defaultLocation}&product_type=Bank+Owned%2CBank+Owned%2CForeclosure%2CShort+Sale%2CTraditional%2Call%2Cauction%2Call`}
                  activeClassName={
                    location.search?.includes("all") ? "active" : ""
                  }
                >
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">All Real Estate</h4>
                    </div>
                  </ListItem>
                </NavLink>
                <NavLink
                  exact
                  to={`/search?${global.defaultLocation}&product_type=auction`}
                  activeClassName={
                    location.search?.includes("auction") ? "active" : ""
                  }
                >
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">Auctions</h4>
                    </div>
                  </ListItem>
                </NavLink>
                {/* {searchData.filter((item) => item.product_type === "Bank Owned")
              .length > 0 && ( */}
                <NavLink
                  exact
                  to={`/search?${global.defaultLocation}&product_type=Bank+Owned`}
                  activeClassName={
                    location.search?.includes("Bank+Owned") ? "active" : ""
                  }
                >
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">Bank Owned</h4>
                    </div>
                  </ListItem>
                </NavLink>
                {/* )} */}
                {/* {searchData.filter((item) => item.product_type === "Foreclosure")
              .length > 0 && ( */}
                <NavLink
                  exact
                  to={`/search?${global.defaultLocation}&product_type=Foreclosure`}
                  activeClassName={
                    location.search == "?product_type=Foreclosure"
                      ? "active"
                      : ""
                  }
                >
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">Foreclosure</h4>
                    </div>
                  </ListItem>
                </NavLink>
                {/* )} */}
                {/* {searchData.filter((item) => item.product_type === "Short Sale")
              .length > 0 && ( */}
                <NavLink
                  exact
                  to={`/search?${global.defaultLocation}&product_type=Short+Sale`}
                  activeClassName={
                    location.search == "?product_type=Short+Sale"
                      ? "active"
                      : ""
                  }
                >
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">Short Sale</h4>
                    </div>
                  </ListItem>
                </NavLink>
                {/* )} */}
                {/* {searchData.filter((item) => item.product_type === "Traditional")
              .length > 0 && ( */}
                <NavLink
                  exact
                  to={`/search?${global.defaultLocation}&product_type=Traditional`}
                  activeClassName={
                    location.search == "?product_type=Traditional"
                      ? "active"
                      : ""
                  }
                >
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">Traditional</h4>
                    </div>
                  </ListItem>
                </NavLink>
                {/* )} */}
              </ul>
            </Popover>
          </li>
          <li className="nav-item">
            <Button
              aria-controls="helpDrop"
              aria-haspopup="true"
              className="marketPlaceButton"
              onClick={handleHelpMenu}
            >
              Help Center <span className="material-icons">expand_more</span>
            </Button>
            <Popover
              id={"helpDrop"}
              open={Boolean(helpAnchormenu)}
              anchorEl={helpAnchormenu}
              onClose={handleHelpClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ul className="helpDropMenu">
                <NavLink exact to="/faq" activeClassName="active">
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">FAQs</h4>
                    </div>
                  </ListItem>
                </NavLink>
                <NavLink exact to="/about_us" activeClassName="active">
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">About Us</h4>
                    </div>
                  </ListItem>
                </NavLink>
                <NavLink exact to="/contact_us" activeClassName="active">
                  <ListItem button>
                    <div className="PmCategoryList-root">
                      <h4 className="PmCategoryTitle m-0">Contact Us</h4>
                    </div>
                  </ListItem>
                </NavLink>
              </ul>
            </Popover>
          </li>
        </ul>
      </div>
      <ul className=" nvOptns nav-mob-btns m-0 navbar-nav header-fst justify-content-end px-3 auth-btns-nav">
        <>
          {userLoggedIn.get === true ? (
            <li className="dropdown nav-item afterLogin">
              <a className="dropdown-toggle d-flex align-items-center">
                <span className="user-circle">
                  <i className="fa fa-user"></i>
                </span>{" "}
                <span className="hb-text">My Account</span>
                {/* {userDetails.get.user_name} */}
              </a>
              <div className="dropdown-menu">
                <a
                  href="/profile"
                  className={location.pathname == "/profile" ? "active" : ""}
                >
                  My Profile
                </a>
                <NavLink to={`/my_auction`} activeClassName="active">
                  My Auctions
                </NavLink>
                <NavLink to={`/saved_search`} activeClassName="active">
                  Saved Searches
                </NavLink>
                <NavLink to={`/product_history`} activeClassName="active">
                  History
                </NavLink>
                <NavLink to={`/watchlist`} activeClassName="active">
                  Watchlist
                </NavLink>
                {/* <NavLink to={`/cards`} activeClassName="active">
                  My Cards
                </NavLink> */}

                <Link
                  to=""
                  onClick={async () => {
                    logout();
                    localStorage.removeItem("userToken");
                    userLoggedIn.set(false);
                    userDetails.set({});
                    sentInvites.set([]);
                    receivedInvites.set([]);
                    financeDetails.set({});
                    billingDetails.set([]);
                    agentVerificationDetails.set({});
                    // refreshPage();
                  }}
                >
                  Logout
                </Link>
              </div>
            </li>
          ) : (
            <>
              <div className="d-flex align-items-center loginReg">
                <LockOutlined />
                <li className="nav-item">
                  <a onClick={() => setLoginOpen(true)}>Login</a>
                </li>
                <span>/</span>
                <li className="nav-item">
                  <a onClick={() => setSignupOpen(true)}>Register</a>
                </li>
              </div>
            </>
          )}
        </>
      </ul>
    </>
  );
};

export default MobileLinks;
