import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import Layout from "../../../components/molecules/Layout";
import PropertyLeftTop from "../../../components/molecules/Property/PropertyLeftTop";
import PropertyLeftTopMobile from "../../../components/molecules/Property/PropertyLeftTopMobile";
import PropertyLeftShow from "../../../components/molecules/Property/PropertyLeftShow";
import PropertyBids from "../../../components/molecules/Property/PropertyBids";
import PropertyComments from "../../../components/molecules/Property/PropertyComments";
import PropertyImageGallery from "../../../components/molecules/Property/PropertyImageGallery";
import MLSinformation from "../../../components/molecules/Property/MLSinformation";
import SimilarHomes from "../../../components/molecules/SimilarHomes";
import PropertyRight from "../../../components/molecules/Property/PropertyRight";
import socket from "../../../utils/socket";
import { getUserProfileData } from "../../../API/userData";
import ImageGallery from "react-image-gallery";
import RegisterToBidPopup from "../Signup/RegisterToBidPopup";
import {
  getPropertyDetails,
  getBidHistory,
  watchList,
  getOfferHistory,
  addtoCart,
  submitOffer,
  bidding,
  beforeReg,
} from "../../../API/propertyDetails";
import "./property.css";
import GlobalContext from "../../../context/GlobalContext";
import NotFound from "../NotFound";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RuumrModal from "../../../components/molecules/Modal";
import DOMPurify from "dompurify";
import {
  numberWithCommas,
  setDecimalPoint,
  getTimeInterval,
  onImageError,
  onPropertyImageError,
} from "../../../utils";
import { getAllNotes } from "../../../API/notes";
import {
  before,
  capitalize,
  filter,
  findIndex,
  flatten,
  orderBy,
  uniqBy,
} from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import CustomInputs from "../../../components/atoms/Inputs/CustomTextArea";
import { getSearch } from "../../../API/search";
import Button from "@material-ui/core/Button";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import fileSaver from "file-saver";
import axios from "../../../utils/axiosconfig";
import Dialog from "../../../components/atoms/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { object } from "yup/lib/locale";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { Formik, useFormik } from "formik";
import { ModalContext } from "../../../context/ModalContext";
import isAuthenticate from "../../../utils/auth";
import CheckBox from "../../../components/atoms/CheckBox";
import * as Yup from "yup";
import * as mainaxios from "axios";
import Bidding from "../../../components/atoms/Bidding";
import Buynow from "../../../components/atoms/Buynow";
import Timer from "../../../components/molecules/Timer/timer";
import PropertyRightShow from "../../../components/molecules/Property/PropertyRightShow";
import { Description, Share } from "@material-ui/icons";
import FavoriteCheckbox from "../../../components/atoms/FavoriteCheckbox";
import Featured from "../Featured";
import { useDispatch } from "react-redux";
import { getMyAuctions } from "../../../store/slices/auctions";
import ShareModel from "./ShareModel";

const PropertyView = (props) => {
  const { id } = props.match.params;
  const {
    currentTime,
    comment,
    userDetails,
    bid,
    userLoggedIn,
    userLocation,
    declinedOffer,
    propertyAwarded,
    bidIncrement,
  } = useContext(GlobalContext);
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const Clipboard = useRef();
  const history = useHistory();
  const location = useLocation();
  const [rtbCompleted, setRtbCompleted] = useState(false);
  const [biddingAmount, setBiddingAmount] = useState("");
  const [highestBidder, setHighestBidder] = useState("");
  const [rtbFiles, setRtbFiles] = useState({});
  const [getProductDetails, seyProductDetails] = useState({});
  const [productImage, setProductImage] = useState({});
  const [offerHistoryData, setOfferHistoryData] = useState([]);
  const [showComp, setShowComp] = useState(null);
  const [getMatterPort, setMatterPort] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showMatterport, setShowMatterport] = useState(null);
  const [agentDetails, setAgentDetails] = useState({});
  const [timer, setTimer] = useState({});
  const [buyTimer, setBuyTimer] = useState({});
  const [offerTimer, setOfferTimer] = useState({});
  const [getSellerData, setSellerData] = useState({});
  const [notes, setNotes] = useState([]);
  const [lable, setLable] = useState({});
  const [comments, setComments] = useState([]);
  const [noComments, setNoComments] = useState(0);
  const [nextBidAmount, setNextBidAmount] = useState("");
  const [follow, setFollow] = useState(false);
  const [isPropertyRemove, setIsPropertyRemove] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [bidPopup, setBidPopup] = useState(false);
  const [myClientsList, setMyClientsList] = useState([]);
  const [myAgent, setMyAgent] = useState([]);
  const [fav, setFav] = useState(0);
  const [shareFollow, setShareFollow] = useState(false);
  const [similarProperty, setSimilarProperty] = useState([]);
  const [isUserBid, setUserBid] = useState(0);
  const [error, setError] = useState("");
  const [mobileNumberVal, setMobileNumberVal] = useState(false);
  const [agentInfo, setAgentInfo] = useState({});
  const [modalopen, setModalOpen] = useState({});
  const [registerToBidOpen, setRegisterToBidOpen] = useState(false);
  const [currentPropCity, setCurrentPropCity] = useState({});
  const bidContainer = useRef();
  const bookTour = useRef();
  const commentsRef = useRef();
  const sellerTermsCon = useRef(null);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [hideBuy, setHideBuy] = useState(true);
  const [offlineMarked, setOfflineMarked] = useState(false);
  const [modalopenBid, setModelopenBid] = useState(false);
  const [tourCount, setTourCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const [soldDetails, setSoldDetails] = useState("");
  const [heightChange, setHeightChange] = useState("442");

  const [docUri, setDocUri] = useState("");
  const [beforePopup, setBeforePopup] = useState(false);
  const [submittedPopup, setSubmittedPopup] = useState(false);
  const [validMessage, setMessage] = useState({
    message: "",
    type: "",
  });
  const [submitBidPopup, setSubmitBidPopup] = useState(false);

  const validationArray = Yup.object({
    email: Yup.string().required(`Enter Email`).email("Enter Valid Email"),
    isAgent: Yup.string().required(`Required`),
    need_agent: Yup.string().when("isAgent", {
      is: (isAgent) => isAgent == "false",
      then: Yup.string().required("Required"),
    }),
  });
  const [selectedClient, setSelectedClient] = useState({
    id: "-1",
    first_name: "Buyer does not have a Ruumr account",
    last_name: "",
    email: "",
    property_id: "",
    purchase_power: "",
  });
  const store_id = global?.storeDetails?.id;
  const site_id = global?.storeDetails?.site_id;
  const [ruumrFeeChecked, setRuumrFeeChecked] = useState(false);
  const [refundAmt, setRefundAmt] = useState(false);
  const beforeFormik = useFormik({
    initialValues: {
      email: "",
      isAgent: "",
      rtbCheck: "true",
      propertyID: id,
      listing_tier: "",
      rtbType: "",
      need_agent: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      values.rtbType =
        beforePopup == "home_inspection"
          ? "View home inspection"
          : beforePopup == "schedule_tour"
            ? "Schedule tour"
            : beforePopup == "bidding_update"
              ? "Get bidding updates"
              : beforePopup == "view_docs"
                ? "View documents"
                : beforePopup == "ask_question"
                  ? "Ask a question"
                  : beforePopup == "seller_question"
                    ? "Ask about terms"
                    : "";
      values.listing_tier = getProductDetails?.listing_tier;
      let data = await beforeReg(values, site_id);
      if (data && data.success === true) {
        if (data.message != "Account created successfully!") {
          toast.success(data.message);
        }
        setBeforePopup(false);
        localStorage.setItem("userToken", data.token);
        userLoggedIn.set(true);
        switch (beforePopup) {
          case "home_inspection":
            openFile(
              `uploads/cerify_product/${getProductDetails.certify_name}`,
            );
            break;
          case "schedule_tour":
            if (getProductDetails.book_tour) {
              addTourCount(getProductDetails?.id);
              window.open(encodeURI(getProductDetails.book_tour));
            } else {
              clickSchedule();
            }
            break;
          case "bidding_update":
            addFav({
              project_id: id,
              mobile: mobile.length === 0 ? "" : mobile,
            });
            break;
          case "view_docs":
            window.open(encodeURI(docUri));
            break;
          case "ask_question":
            setSubmittedPopup("ask_question");
            break;
          case "seller_question":
            setSubmittedPopup("seller_question");
            break;
        }
      }
    },
  });

  useEffect(() => {
    let url = new URLSearchParams(location.search);
    if (url.get("showDocs") == "true") {
      setOpen(true);
    }
    if (url.get("showComment") == "true") {
      setTimeout(() => {
        clickAskQuestion();
      }, 2500);
    }
  }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (beforePopup == false) {
      beforeFormik.setFieldValue("email", "");
      beforeFormik.setFieldValue("isAgent", "");
      beforeFormik.setFieldValue("need_agent", "");
      beforeFormik.handleReset();
    }
  }, [beforePopup]);

  useEffect(() => {
    if (beforeFormik?.values?.isAgent === "true") {
      beforeFormik.setFieldValue("need_agent", "");
    }
  }, [beforeFormik.values?.isAgent]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const width = useMediaQuery("(min-width:1200px)");
  useEffect(() => {
    if (
      bidIncrement.get &&
      !!Object.keys(bidIncrement.get).length &&
      id === bidIncrement.get.productId
    ) {
      setNextBidAmount(bidIncrement.get["nextBid"]);
    }
  }, [bidIncrement.get, id]);

  const fetchOfferHistory = async () => {
    let offerResult = await getOfferHistory({ id: id + "" }, site_id);
    if (offerResult.status === true) {
      setOfferHistoryData(offerResult.result);
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      setIsLoading(true);
      getData();
      getBidHistory({ id }, site_id), getNotes();
      fetchOfferHistory();
    }
  }, [id]);

  useEffect(() => {
    if (
      declinedOffer.get &&
      declinedOffer.get?.winner &&
      declinedOffer.get.project_id == id
    ) {
      fetchOfferHistory();
      declinedOffer.set({});
    }
  }, [declinedOffer, id]);

  useEffect(() => {
    if (follow === false) {
      setStep(1);
    }
  }, [follow]);

  useEffect(() => {
    if (
      Object.keys(propertyAwarded.get).length !== 0 &&
      propertyAwarded.get["project_id"] == id
    ) {
      if (propertyAwarded.get["winner"] == userDetails.get["id"]) {
        toast.success(
          `Congratulations! You won the property for $${setDecimalPoint(
            propertyAwarded.get["amount"],
          )}`,
        );
      } else {
        if (isUserBid !== 0) {
          toast.warning(`Unfortunately! You have lost this property`);
        }
      }
      seyProductDetails((prv) => ({
        ...prv,
        market_status: "sold",
      }));
      setSoldDetails({
        ...soldDetails,
        highest_amount: propertyAwarded.get["amount"],
        listingFormat: propertyAwarded.get["listingFormat"],
        user_id: propertyAwarded.get["winner"],
      });
      setHighestBidder(propertyAwarded.get["winner"]);
      propertyAwarded.set({});
    }
  }, [userDetails.get, propertyAwarded.get]);

  useEffect(() => {
    if (
      Object.keys(bid.get).length !== 0 &&
      Number(bid.get.belowFive) === 1 &&
      bid.get["productId"] == id
    ) {
      getProductDetails["date_closed"] = bid.get.belowFiveIncrement;
      // seyProductDetails((prv) => ({
      //   ...prv,
      //   date_closed: bid.get.belowFiveIncrement,
      // }));
    }
    if (imgRef.current?.complete) {
      setIsImageLoaded(true);
    }

    Object.keys(getProductDetails).length !== 0 &&
      currentTime.get &&
      setTimer(
        getTimeInterval(
          getProductDetails["date_added"],
          getProductDetails["date_closed"],
          currentTime.get,
          getProductDetails["market_status"],
        ),
      );

    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      if (getProductDetails.buynow == 1) {
        setBuyTimer(
          getTimeInterval(
            getProductDetails["b_date_added"],
            getProductDetails["b_date_closed"],
            currentTime.get,
            getProductDetails["market_status"],
          ),
        );
      }
    }

    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      if (getProductDetails.offer == 1) {
        setOfferTimer(
          getTimeInterval(
            getProductDetails["o_date_added"],
            getProductDetails["o_date_closed"],
            currentTime.get,
            getProductDetails["market_status"],
          ),
        );
      }
    }
  }, [getProductDetails, currentTime.get, bid.get]);

  useEffect(() => {
    let aTimer = "";
    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      aTimer = getTimeInterval(
        getProductDetails["date_added"],
        getProductDetails["date_closed"],
        currentTime.get,
        getProductDetails["market_status"],
      );
    }
    if (offerHistoryData.length && highestBidder !== "" && aTimer.isCompleted) {
      let filterOffer = offerHistoryData
        .filter((data) => data.user_id == highestBidder)
        .sort((a, b) => a.id - b.id)
        .map((val) => {
          return val;
        });
      let index = filterOffer.length - 1;
      if (
        filterOffer[index] &&
        filterOffer[index]?.id &&
        filterOffer[index]?.declined
      ) {
        setHideBuy(false);
      } else {
        setHideBuy(true);
      }
    }
  }, [offerHistoryData, highestBidder, getProductDetails]);

  // useEffect(() => {
  //   if (
  //     (Object.keys(comment.get).length !== 0) &
  //     (comment.get["product_id"] === id)
  //   ) {
  //     let newComment = comment.get;
  //     if (newComment.comment_id !== "0") {
  //       let position = comments.findIndex(
  //         (comment) => parseInt(comment.id) === parseInt(newComment.comment_id)
  //       );
  //       if (position !== -1) {
  //         let existingComments = comments;
  //         let existingComment = existingComments[position];
  //         let rply = existingComment["rply"];
  //         if (rply) {
  //           let rplyIndex = rply.findIndex(
  //             (rply) => parseInt(rply.id) === parseInt(newComment.id)
  //           );
  //           if (rplyIndex !== -1) {
  //             rply[rplyIndex].status = newComment.status;
  //             existingComment["rplyCount"] =
  //               newComment.status === "true"
  //                 ? existingComment["rplyCount"] + 1
  //                 : existingComment["rplyCount"] - 1;
  //           } else {
  //             rply = [{ from: "comments", ...newComment }, ...rply];
  //             existingComment["rply"] = rply;
  //             existingComment["rplyCount"] = existingComment["rplyCount"] + 1;
  //           }
  //           existingComments[position] = existingComment;
  //           setComments([...existingComments]);
  //         } else {
  //           existingComment["rplyCount"] = existingComment["rplyCount"] + 1;
  //           let newrply = [{ from: "comments", ...newComment }];
  //           existingComment["rply"] = newrply;
  //           existingComments[position] = existingComment;
  //           setComments([...existingComments]);
  //         }
  //       } else {
  //         let flatArray = flatten(
  //           filter(
  //             filter(comments, { from: "comments" }).map((ind) => ind.rply),
  //             (en) => en !== undefined
  //           )
  //         );
  //         let position = flatArray.findIndex(
  //           (flatArray) =>
  //             parseInt(flatArray.id) === parseInt(newComment.comment_id)
  //         );
  //         if (position !== -1) {
  //           let rplyCommetParent = flatArray[position];
  //           let parentCommentPosition = comments.findIndex(
  //             (comment) =>
  //               parseInt(comment.id) ===
  //                 parseInt(rplyCommetParent.comment_id) &&
  //               comment.from === "comments"
  //           );
  //           if (parentCommentPosition !== -1) {
  //             let existingComments = comments;
  //             let existingComment = existingComments[parentCommentPosition];
  //             let rply = existingComment["rply"] || [];
  //             let rplyposition = rply.findIndex(
  //               (rply) => parseInt(rply.id) === parseInt(newComment.comment_id)
  //             );
  //             if (rplyposition !== -1) {
  //               let parentRplyComments = rply[rplyposition];
  //               let replay = parentRplyComments.rply;
  //               if (replay) {
  //                 let replayIndex = replay.findIndex(
  //                   (replay) => parseInt(replay.id) === parseInt(newComment.id)
  //                 );
  //                 if (replayIndex !== -1) {
  //                   replay[replayIndex].status = newComment.status;
  //                   parentRplyComments["rplyCount"] =
  //                     newComment.status === "true"
  //                       ? parentRplyComments["rplyCount"] + 1
  //                       : parentRplyComments["rplyCount"] - 1;
  //                 } else {
  //                   replay = [newComment, ...replay];
  //                   parentRplyComments["rply"] = replay;
  //                   parentRplyComments["rplyCount"] =
  //                     parentRplyComments["rplyCount"] + 1;
  //                 }
  //                 rply[rplyposition] = parentRplyComments;
  //                 existingComment["rply"] = rply;
  //                 existingComments[position] = existingComment;
  //                 setComments([...existingComments]);
  //               } else {
  //                 let newreplay = [newComment];
  //                 parentRplyComments["rply"] = newreplay;
  //                 parentRplyComments["rplyCount"] = 1;
  //                 rply[rplyposition] = parentRplyComments;
  //                 existingComment["rply"] = rply;
  //                 existingComments[position] = existingComment;
  //                 setComments([...existingComments]);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     } else {
  //       let position = comments.findIndex(
  //         (comment) => parseInt(comment.id) === parseInt(newComment.id)
  //       );
  //       if (position !== -1) {
  //         let existingComments = comments;
  //         existingComments[position].status = newComment.status;
  //         if (newComment.status === "true") {
  //           setNoComments((preCount) => preCount + 1);
  //         } else {
  //           setNoComments((preCount) => preCount - 1);
  //         }
  //         setComments(existingComments);
  //       } else {
  //         setComments((existingComments) => [
  //           { from: "comments", rplyCount: 0, ...newComment },
  //           ...existingComments,
  //         ]);
  //         setNoComments((preCount) => preCount + 1);
  //       }
  //     }
  //   }
  // }, [comment.get]);

  const toggleFollow = () => {
    setFollow((follow) => !follow);
  };

  const [copied, setCopied] = useState(false);

  const copyToClipBoard = (e) => {
    copy(window.location.href);
    setCopied(true);
  };

  const limit = "unlimited";

  const toggleShare = () => {
    setShareFollow((prv) => !prv);
    setCopied(false);
  };

  const getBidHistorys = async () => {
    let data = await getBidHistory({ id }, site_id);
    let bidsArr = data.result;

    seyProductDetails((prv) => ({
      ...prv,
      bidcount: bidsArr.length,
      uniqueBidders: data.uniqueBidders,
    }));
    bidsArr = bidsArr.map((element) => ({
      ...element,
      from: "bids",
    }));

    // setComments((prevData) =>
    //   orderBy(
    //     [...filter(prevData, { from: "comments" }), ...bidsArr],
    //     (element) => new Date(element.created_at),
    //     ["desc"]
    //   )
    // );
    setComments(bidsArr);
  };

  const getSimilarProperty = async (body) => {
    let data = await getSearch(body, site_id);
    if (data) {
      if (data.status === true) {
        let allProducts = data.result["productdetails"];
        let position = findIndex(allProducts, { id: Number(id) });
        if (position !== -1) {
          allProducts.splice(position, 1);
        } else {
          allProducts.splice(0, 1);
        }
        setSimilarProperty(allProducts);
      }
    }
  };

  const getNotes = async () => {
    if (site_id != (null || undefined)) {
      const data = await getAllNotes({ project_id: id }, site_id);
      if (data.status === true) {
        setNotes(data.result);
      }
    }
  };
  const getData = async () => {
    if (site_id != (null || undefined)) {
      let data = await getPropertyDetails(id, site_id);

      if (data && data.status === true) {
        setProductImage(data);
        setRtbCompleted(data.result.RtB_Completed);
        setSoldDetails(data.result?.highBidder[0]);
        setHighestBidder(
          data.result?.highBidder?.length &&
            Object.keys(data.result?.highBidder[0]).length
            ? data.result.highBidder[0].user_id
            : "",
        );
        if (!!data?.result?.RtB_Completed === false) {
          setRtbFiles(
            !!data?.result?.previous_RtB.length &&
              data?.result?.previous_RtB[0] !== null &&
              Object.keys(data?.result?.previous_RtB[0]).length !== 0
              ? data.result.previous_RtB[0]
              : {},
          );
        } else if (!!data?.result?.RtB_Completed === true) {
          setRtbFiles(
            !!data?.result?.existing_RtB.length &&
              data?.result?.existing_RtB[0] !== null &&
              Object.keys(data?.result?.existing_RtB[0]).length !== 0
              ? data.result.existing_RtB[0]
              : {},
          );
        }
        setCurrentPropCity({
          lat: data.result.propertyDetails.lat,
          lng: data.result.propertyDetails.lng,
          city: data.result.propertyDetails.loc_city,
          state: data.result.propertyDetails.state,
        });
        setIsPropertyRemove(data.is_removed === "true");
        setNextBidAmount(data.result.propertyDetails["next_bid"]);
        let propertyDetails = data.result.propertyDetails;
        propertyDetails["matterport"] = propertyDetails["matterport"]
          ? JSON.parse(propertyDetails["matterport"])
          : {};
        propertyDetails["listing_video"] =
          data.result &&
          data.result.propertyDetails &&
          data.result.propertyDetails.listing_video &&
          JSON.parse(data.result.propertyDetails.listing_video).length > 0
            ? JSON.parse(data.result.propertyDetails.listing_video)
            : [];
        setUserBid(propertyDetails.userBidCount);
        setTourCount(propertyDetails.tour_clicks);
        if (data.result.my_clients)
          setMyClientsList(
            data.result.my_clients.filter(
              (client) => client.purchase_power > 0,
            ),
          );
        if (data.result.my_agent) setMyAgent(data.result.my_agent);
        propertyDetails["views"] = data.result.views || 0;
        propertyDetails["uniqueBidders"] = data.result.uniqueBidders || 0;
        setFav(
          data.result.fav ? (data.result.fav["status"] === "true" ? 1 : 0) : 0,
        );
        setAgentInfo(
          data.result.agent === "" || data.result.agent === null
            ? {}
            : data.result.agent,
        );
        setMobile(
          data.result.fav
            ? data.result.fav["mobile"] !== "" ||
              data.result.fav["mobile"] !== null ||
              data.result.fav["mobile"] !== null
              ? data.result.fav["mobile"]
              : ""
            : "",
        );

        let more = JSON.parse(propertyDetails.resource);

        if (more && Array.isArray(more) && more.length !== 0) {
          let dynamicData = more[0] !== undefined ? more[0] : {};
          let staticData = more[1] !== undefined ? more[1] : {};
          propertyDetails["resource"] = { ...staticData, ...dynamicData };
        }

        let terms = JSON.parse(propertyDetails.sellerTerms);
        if (terms && Array.isArray(terms) && terms.length !== 0) {
          let dynamicData = terms[0] !== undefined ? terms[0] : {};
          let staticData = terms[1] !== undefined ? terms[1] : {};
          let sellerToolTips = Object.values(terms[1]);
          let sellerTermsKeys = Object.keys(terms[0]);
          let sellerTermsValues = Object.values(terms[0]);
          let sellerTermsArr = sellerTermsKeys.map((ele, i) => {
            let sellTerm = {};
            sellTerm["sellerKey"] = ele;
            sellTerm["sellerValue"] = sellerTermsValues[i];
            sellTerm["sellerTooltip"] = sellerToolTips[i];
            sellTerm["checked"] = false;
            return sellTerm;
          });
          propertyDetails["sellerTerms"] = { ...staticData, ...dynamicData };
          propertyDetails["sellerTermsTooltip"] = { ...terms[1] };
          propertyDetails["sellerTermValues"] = sellerTermsArr;
        }

        let aboutProperty = JSON.parse(propertyDetails.aboutProperty);
        if (
          aboutProperty &&
          Array.isArray(aboutProperty) &&
          aboutProperty.length !== 0
        ) {
          let dynamicData =
            aboutProperty[0] !== undefined ? aboutProperty[0] : {};
          let staticData =
            aboutProperty[1] !== undefined ? aboutProperty[1] : {};
          propertyDetails["aboutProperty"] = { ...staticData, ...dynamicData };
        }
        seyProductDetails(propertyDetails);
        setIsLoading(false);
        setAgentDetails(
          data.result.propertyDetails["agentOfProperty"]
            ? {
                ...JSON.parse(data.result.propertyDetails["agentOfProperty"]),
                agentUserId: data.result.agent ? data.result.agent.id : "",
              }
            : {},
        );
        let comm = data.result["comments"].map((element) => ({
          ...element,
          from: "comments",
        }));
        let bids = data.result["bids"].map((element) => ({
          ...element,
          from: "bids",
        }));
        // setComments(
        //   orderBy(
        //     [...comm, ...bids],
        //     (element) => new Date(element.created_at),
        //     ["desc"]
        //   )
        // );
        setComments(bids);
        setNoComments(data.result["commentCount"]);
        setSellerData(
          data.result.propertyDetails["userDetails"]
            ? JSON.parse(data.result.propertyDetails["userDetails"])
            : {},
        );

        const resourceData = data.result.propertyDetails.resource;

        if (typeof resourceData === "object" && resourceData !== null) {
          const resourceList = [];
          for (var v in resourceData) {
            resourceList.push({
              name: v,
              url: resourceData[v],
            });
          }
          setDocuments(resourceList);
        } else {
          setDocuments([]);
        }

        let allPhotos = data.result.images.map((ri) => ({
          original: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri.asset_image}`,
          thumbnail: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri.asset_image}`,
          asset_label: ri.asset_label,
        }));

        setLable(allPhotos);

        let matterPort = data.result.matterport.map((mp) => ({
          ...mp,
          imgSrc: mp.asset_image.split(","),
        }));
        setMatterPort(matterPort);
        let show = JSON.parse(data.result.propertyDetails.show_matterport);
        setShowMatterport(show);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("online", () => {
      if (offlineMarked) {
        getData();
      }
    });

    window.addEventListener("offline", () => {
      if (!offlineMarked) {
        setOfflineMarked(true);
      }
    });
  }, [offlineMarked]);

  useEffect(() => {
    if (highestBidder) {
    }
  }, [highestBidder]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(bid.get).length !== 0) {
      if (bid.get["productId"] == id) {
        getBidHistorys();
      }
      if (bid.get["productId"] == id) {
        if (!!Object.keys(bid.get).length) {
          setHighestBidder(bid.get.highBidder);
        }

        if (
          !!Object.keys(bid.get).length &&
          !!Object.keys(userDetails.get).length
        ) {
          if (bid.get["outbiddedUser"] == userDetails.get["id"]) {
            toast.info("You are losing.");
            dispatch(getMyAuctions({ action: "won_history" }));
            bid.set({});
          } else if (bid.get["highBidder"] === userDetails.get["id"]) {
            if (bid.get.message) {
              toast.info(bid.get.message, {
                preventDuplicate: true,
              });
            }
            toast.success("Bid placed successfully!", {
              preventDuplicate: true,
            });
          }
        }
        setNextBidAmount(bid.get["nextBid"]);
        seyProductDetails((prv) => ({
          ...prv,
          wprice: bid.get["wPrice"],
        }));
      }

      if (
        bid.get["productId"] == id &&
        bid.get["userId"] == userDetails.get["id"]
      ) {
        setUserBid((prv) => prv + 1);
      }
      bid.set({});
    }
  }, [userDetails.get, bid.get, id, isUserBid]);

  const updateAfterBid = (data) => {
    setSubmitBidPopup(data);
  };

  const updateAfterBuy = (data) => {
    setBidPopup(data);
  };

  const modelUpdate = () => {
    setModelopenBid(!modalopenBid);
  };

  useEffect(() => {
    window.addEventListener("focus", () => {
      if (!socket.connected) {
        getData();
      }
    });

    if (!document.hidden) {
      if (!socket.connected) {
        getData();
      }
    }
  }, [socket.connected, site_id]);

  const openFile = (path) => {
    window.open(process.env.REACT_APP_IMAGE_URL + path, "_blank");
  };

  const addFav = async (body) => {
    let data = await watchList(body, site_id);
    if (data.status === true) {
      if (data.message === "Removed from watchlist") {
        setFav(0);
        toast.success("Property has been removed from watchlist", {
          preventDuplicate: true,
        });
      } else if (data.message.includes("Watchlist Added")) {
        setFav(1);
        toast.success("Property has been watchlisted", {
          preventDuplicate: true,
        });
      } else if (data.message.includes("Added to watchlist")) {
        setFav(1);
        toast.success("Property has been watchlisted", {
          preventDuplicate: true,
        });
      }
    } else {
      toast.error("Something went wrong");
    }
    setFollow(false);
  };

  const favouriteAdd = (e) => {
    setMobile(e.target.value);
    setMobileNumberVal(false);
  };
  const productScroll = () => {
    history.push(`/property/${id}/#bids`);
    location.hash === "#bids" &&
      bidContainer.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleModalBid = () => {
    setModelopenBid(!modalopenBid);
  };
  const rtbFormConfirmation = async () => {
    let res = await axios.post(
      "userproperty/Rtb_bak",
      {
        propertyID: id,
        listing_tier: getProductDetails.listing_tier,
      },
      {
        headers: { site_id },
      },
    );
    try {
      if (res && res.data.success) {
        return res.data;
      }
    } catch (err) {
      console.error(err, "this is err from user property bid");
    }
  };
  // const submittingBid = async () => {

  //   if (userLoggedIn.get) {

  //     if (userDetails.get["id"] == getProductDetails.user_id) {
  //       toast.warning("Can't Bid on own property");
  //     } else {
  //       if (
  //         timer.isCompleted === false &&
  //         timer.text === "Auction Ends In" &&
  //         rtbCompleted
  //       ) {
  //         setSubmitBidPopup(true);
  //       } else if (
  //         (timer.isCompleted === false &&
  //           timer.text === "Auction Ends In" &&
  //           !rtbCompleted) ||
  //         (timer.isCompleted === false &&
  //           timer.text === "Bidding Starts In" &&
  //           !rtbCompleted) ||
  //         (timer.isCompleted === false &&
  //           timer.text === "Bidding Starts In" &&
  //           rtbCompleted &&
  //           userDetails.get &&
  //           !!Object.keys(userDetails.get).length)
  //       ) {
  //         rtbFormConfirmation();
  //         if (userDetails.get.customerid == "") {
  //           // setRegisterToBidOpen(true);
  //         } else {
  //           history.push({
  //             pathname:
  //               (getProductDetails.listing_tier === 1 &&
  //                 userDetails.get.profiletype === "agent") ||
  //               (getProductDetails.listing_tier === 1 &&
  //                 userDetails.get.profiletype === "builder") ||
  //               (getProductDetails.listing_tier === 1 &&
  //                 userDetails.get.profiletype === "customer") ||
  //               (getProductDetails.listing_tier === 2 &&
  //                 userDetails.get.profiletype === "agent")
  //                 ? `/registertobid-success`
  //                 : // : `/registertobid-success`,
  //                   `/registertobid-success`,
  //             state: {
  //               productType: "bidding",
  //               path: location,
  //               tier: getProductDetails.listing_tier,
  //               propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
  //             },
  //           });
  //         }
  //       } else {
  //         if (timer.isCompleted === true) {
  //           toast.warning("Closed");
  //         } else {
  //           toast.warning("Auction not yet started");
  //         }
  //       }
  //     }
  //   } else {
  //     if (!userLoggedIn.get && timer.isCompleted) {
  //       toast.warning("Closed");
  //     } else {
  //       setSignupOpen(true);
  //     }
  //   }
  // };
  const [hasRegistered, setHasRegistered] = useState(false);

  const submittingBid = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          timer.isCompleted === false &&
          timer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          toggleModalBid();
        } else if (
          (timer.isCompleted === false &&
            timer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();

          history.push({
            pathname:
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "agent") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "builder") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "customer") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "bidder") ||
              (getProductDetails.listing_tier === 2 &&
                userDetails.get.profiletype === "agent")
                ? `/registertobid-success`
                : `/registertobid-success`,
            state: {
              productType: "bidding",
              path: location,
              tier: getProductDetails.listing_tier,
              propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
            },
          });
        } else {
          if (timer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && timer.isCompleted) {
        toast.warning("Closed");
      } else {
        setLoginOpen(true);
      }
    }
  };

  const updateProfile = async () => {
    let { cardDetails: newCardInfo, userDetails: userInfo } =
      await getUserProfileData(site_id);
  };

  const nextStep = () => {
    updateProfile();
    history.push({
      pathname:
        (getProductDetails.listing_tier === 1 &&
          userDetails.get.profiletype === "agent") ||
        (getProductDetails.listing_tier === 1 &&
          userDetails.get.profiletype === "builder") ||
        (getProductDetails.listing_tier === 1 &&
          userDetails.get.profiletype === "customer") ||
        (getProductDetails.listing_tier === 2 &&
          userDetails.get.profiletype === "agent")
          ? `/registertobid-success`
          : `/registertobid-success`,
      state: {
        productType: "bidding",
        path: location,
        tier: getProductDetails.listing_tier,
        propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
      },
    });
  };

  const submittingOffer = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          offerTimer.isCompleted === false &&
          offerTimer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          toggleModalBid();
        } else if (
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          if (userDetails.get.customerid == "") {
            // setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (getProductDetails.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-success`,
              state: {
                path: location,
                tier: getProductDetails.listing_tier,
                propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (offerTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && offerTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        setSignupOpen(true);
      }
    }
  };

  const clickSchedule = () => {
    if (
      userLoggedIn.set &&
      userDetails.get["id"] == getProductDetails.user_id
    ) {
      history.push(`/sellercalendar`);
    } else {
      addTourCount(getProductDetails?.id);
      bookTour.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickAskQuestion = () => {
    if (commentsRef.current) {
      commentsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickBidNumber = () => {
    if (bidContainer.current) {
      bidContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const showSellerStatus = (ref) => {
    sellerTermsCon.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const addMobileNumberFollow = () => {
    if (mobile.length >= 8 && mobile.length <= 13) {
      mobile && addFav({ project_id: id, mobile: mobile });
    } else if (mobile.length < 8 || mobile.length > 13) {
      setMobileNumberVal(true);
    }
  };

  const changeText = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "").replace(/,/g, "");
    setBiddingAmount((prevData) =>
      !isNaN(value) && value <= 99999999 ? value : prevData,
    );
    setRefundAmt(
      !isNaN(value) && value <= 99999999 ? (parseFloat(value) * 5) / 100 : 0,
    );
  };

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber?.length) {
        return (
          <>
            <div className="addressLine1">
              <span className="address">{addressArray[0]}</span>
              {addressArray[0] && <span>,</span>} <br />
              <span className="place">
                {city && <>{city},</>} {state && <>{state}, </>}{" "}
                {zipcode && <>{zipcode}</>}
              </span>
            </div>
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">
            <span className="address">{addressArray[0].trim()}</span>
            {addressArray[0] && <span>,</span>} <br />
            <span className="place">
              {city && <>{city},</>} {state && <>{state}, </>}{" "}
              {zipcode && <>{zipcode}</>}
            </span>
          </div>
        );
      } else {
        return (
          <>
            <div className="addressLine1">
              <span className="address">{addressArray[0]}</span>,<br />{" "}
              <span className="place">
                {city && <>{city},</>} {state && <>{state}, </>}{" "}
                {zipcode && <>{zipcode}</>}
              </span>
            </div>{" "}
          </>
        );
      }
    } else {
      return address;
    }
  };

  const formatAddress = (getProductDetails) => {
    var tempArr = getProductDetails.loc_address.split(
      getProductDetails.loc_city,
    );
    var tempSubArr2 = tempArr[0]?.trim().split("");
    if (tempSubArr2[tempSubArr2.length - 1] === ",") {
      tempSubArr2.splice(tempSubArr2.length - 1, 1);
    }
    tempSubArr2.push(" " + getProductDetails.house_number + ", ");
    tempArr[0] = tempSubArr2.join("");
    return tempArr.join(getProductDetails.loc_city);
  };

  const onEdit = async (id, text) => {
    if (text && DOMPurify.sanitize(text)) {
      let { data } = await axios.post(
        "editComment",
        {
          id,
          text: DOMPurify.sanitize(text),
        },
        {
          headers: { site_id },
        },
      );
      if (data.status === true) {
        getData();
        toast.success("Comment updated successfully");
      } else {
        toast.success("Something went wrong");
      }
    }
  };

  const OnDelete = async (id) => {
    let { data } = await axios.post(
      "commentDelete",
      { id },
      {
        headers: { site_id },
      },
    );
    if (data.status === true) {
      getData();
      toast.success("Comment deleted successfully");
    } else {
      toast.success("Something went wrong");
    }
  };

  const addTourCount = async (id) => {
    try {
      let { data } = await axios.post(
        "api/tour_count",
        { id },
        {
          headers: { site_id },
        },
      );
      if (data.status) {
        setTourCount(data.tour_count);
      }
    } catch (err) {
      console.error(err, "add tour err");
    }
  };

  const addProductToCart = async (id, bprice) => {
    try {
      let res = await addtoCart(id, bprice, site_id);
      if (res && res.status === true) {
        history.push(`/checkout/${id}`);
      } else {
        if (res) {
          toast.warning(res.message);
        }
      }
    } catch (err) {
      toast.warning(err);
      console.error(err, "add tour err");
    }
  };

  const [cancelRequest, setCancelRequest] = useState(undefined);

  let sourceToken = mainaxios.CancelToken.source();

  const checkEmail = async (cancelToken) => {
    const config = {
      cancelToken: cancelToken?.token,
    };
    let { data } = await axios.post(
      process.env.REACT_APP_API + "checkEmailExists",
      {
        email: beforeFormik.values?.email,
      },
      config,
      {
        headers: { site_id },
      },
    );
    if (data.success === true) {
      setMessage({ message: "Email available", type: "success" });
    } else if (data.success === false) {
      setMessage({ message: "Email already taken", type: "error" });
    }
  };

  useEffect(() => {
    if (cancelRequest) {
      cancelRequest.cancel();
    }

    setCancelRequest(sourceToken);
    if (beforeFormik.values?.email != "") {
      checkEmail(sourceToken);
    }
  }, [beforeFormik.values?.email]);

  const handleExistingEmail = () => {
    history.push({
      pathname: `/login`,
      state: {
        fromPage: "productPage",
        values: {
          email: beforeFormik.values.email,
        },
      },
    });
  };

  const handleUpdateClick = (id, email, type) => {
    setSignupOpen(true);
  };
  const fallbackImage = "/assets/images/noImage.png";

  let avatar = getProductDetails?.avatar;
  if (!avatar) {
    avatar = fallbackImage;
  }
  //   let images1=productImage?.result?.images;
  //   const baseImageURL = `${process.env.REACT_APP_IMAGE_URL}uploads/product/`;
  //   const itms = images1?.map(image => ({
  //   original: `${baseImageURL}${image}`,
  //   thumbnail: `${baseImageURL}${image}`,
  // }));
  const [itms, setItms] = useState([]);

  useEffect(() => {
    if (productImage?.result?.images) {
      const images1 = productImage.result.images;
      const baseImageURL = `${process.env.REACT_APP_IMAGE_URL}uploads/product/`;

      const formattedImages = images1.map((image) => ({
        original: `${baseImageURL}${image.asset_image}`,
        thumbnail: `${baseImageURL}${image.asset_image}`,
      }));

      const finalImages = [
        {
          original: `${baseImageURL}${productImage.result.propertyDetails.avatar}`,
          thumbnail: `${baseImageURL}${productImage.result.propertyDetails.avatar}`,
        },
        ...formattedImages,
      ];

      setItms(finalImages);
    }
  }, [productImage]);

  const [openDialog, setOpenDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDownload = (url) => {
    window.open(url, "_blank");
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = url.split("/").pop();
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="circular-loader">
            <CircularProgress className="loading" />
          </div>
        </>
      ) : (
        <>
          {isAuthenticate() && userDetails.get["status"] == "incomplete" && (
            <div
              className={`${
                window.location.pathname.includes(`/search`) ? "srchPdg" : ""
              } usrRegCheck`}
            >
              <p>
                Your account is incomplete, please{" "}
                <a
                  onClick={() =>
                    handleUpdateClick(
                      userDetails.get["id"],
                      userDetails.get["email"],
                    )
                  }
                >
                  click here
                </a>{" "}
                to update your account info
              </p>
            </div>
          )}
          {isPropertyRemove ? (
            <NotFound />
          ) : (
            <>
              <Layout>
                <div
                  className={
                    isAuthenticate() ? "headerStickyCont" : "headerStickyCont"
                  }
                >
                  <div className="row btmStckyHdr product-view-top">
                    <div className="headerbtmShadSec">
                      <div
                        className="row pt-3 pb-3 alignChange"
                        style={{ fontWeight: "normal" }}
                      >
                        <div className="col-md-7 col-12">
                          <div className="d-flex justify-content-between align-items-end">
                            <div
                              className="property-sub-head"
                              onClick={() =>
                                window.open(
                                  `https://www.google.com/maps/search/?api=1&query=${getProductDetails.lat},${getProductDetails.lng}`,
                                  "_blank",
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {getProductDetails.house_number
                                ? constructAddressComponent(
                                    getProductDetails.loc_address,
                                    getProductDetails.zipcode,
                                    getProductDetails.house_number,
                                  )
                                : constructAddressComponent(
                                    getProductDetails.loc_address,
                                    getProductDetails.zipcode,
                                    "",
                                    getProductDetails.loc_city,
                                    getProductDetails.state,
                                  )}
                            </div>
                            <div className="bidComm">
                              {/* <div
                                onClick={() =>
                                  userLoggedIn.get === true
                                    ? addFav({
                                        project_id: id,
                                        mobile:
                                          mobile.length === 0 ? "" : mobile,
                                      })
                                    : toast.warning("Login to add follow")
                                }
                              >
                                {fav ? (
                                  <span className="material-icons">
                                    favorite
                                  </span>
                                ) : (
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 12.1002C5.82916 12.1002 5.66446 12.0301 5.5361 11.9026C5.05133 11.4222 4.58395 10.9707 4.1716 10.5725L4.16949 10.5704C2.96054 9.40274 1.91656 8.39435 1.19019 7.40101C0.378204 6.29052 0 5.23762 0 4.08739C0 2.96985 0.338104 1.93884 0.951965 1.18416C1.57315 0.420558 2.42551 0 3.35229 0C4.04498 0 4.67935 0.248205 5.23773 0.737663C5.51953 0.984727 5.77496 1.2871 6 1.63979C6.22513 1.2871 6.48047 0.984727 6.76236 0.737663C7.32074 0.248205 7.95511 0 8.6478 0C9.57449 0 10.4269 0.420558 11.0481 1.18416C11.662 1.93884 12 2.96985 12 4.08739C12 5.23762 11.6219 6.29052 10.8099 7.40091C10.0835 8.39435 9.03964 9.40263 7.83087 10.5702C7.41779 10.9691 6.94968 11.4213 6.46381 11.9028C6.33554 12.0301 6.17075 12.1002 6 12.1002ZM3.35229 0.796705C2.62418 0.796705 1.95529 1.12605 1.46869 1.72415C0.974854 2.33128 0.70285 3.17053 0.70285 4.08739C0.70285 5.05479 1.02008 5.91998 1.73135 6.89267C2.41882 7.83288 3.44138 8.82051 4.62534 9.9641L4.62753 9.96618C5.04144 10.366 5.51065 10.8192 5.99899 11.3032C6.49026 10.8183 6.96021 10.3643 7.37494 9.9639C8.55881 8.82031 9.58127 7.83288 10.2687 6.89267C10.9799 5.91998 11.2971 5.05479 11.2971 4.08739C11.2971 3.17053 11.0251 2.33128 10.5313 1.72415C10.0448 1.12605 9.37582 0.796705 8.6478 0.796705C8.11441 0.796705 7.62469 0.988877 7.19229 1.36783C6.80695 1.70568 6.53851 2.13278 6.38113 2.43162C6.3002 2.5853 6.15775 2.67702 6 2.67702C5.84225 2.67702 5.6998 2.5853 5.61887 2.43162C5.46158 2.13278 5.19315 1.70568 4.80771 1.36783C4.37531 0.988877 3.88559 0.796705 3.35229 0.796705Z"
                                      fill="black"
                                    />
                                  </svg>
                                )}
                                <span className="headTil">Watchlist</span>
                              </div> */}
                              <div onClick={toggleShare}>
                                <Share />
                                <span className="headTil">Share</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-12">
                          <div
                            className="bidComm justify-content-end"
                            style={{ gap: "20px" }}
                          >
                            <div>
                              <span className="headTil">
                                <div className="comp-doc-btn">
                                  <Button onClick={handleOpenDialog}>
                                    <Description />
                                    Documents
                                  </Button>
                                  <CustomDialog
                                    handleClose={handleCloseDialog}
                                    open={openDialog}
                                    title="Documents"
                                  >
                                    <div className="doc-list d-flex">
                                      {documents.length > 0 ? (
                                        documents.map((docUrl, index) => (
                                          <div key={index} className="doc-file">
                                            <a
                                              href={docUrl.url}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleDownload(docUrl.url);
                                              }}
                                              className="badge p-2"
                                            >
                                              <span className="fas fa-file-pdf mr-2"></span>
                                              {docUrl.name}
                                            </a>
                                          </div>
                                        ))
                                      ) : (
                                        <p>No documents available</p>
                                      )}
                                    </div>
                                  </CustomDialog>
                                  {/* {getProductDetails.enable_comps == "true" && (
                                    <Button
                                      className="comps-btn border-0"
                                      id="basic-button"
                                      ref={anchorRef}
                                      aria-controls={
                                        open ? "menu-list-grow" : undefined
                                      }
                                      aria-haspopup="true"
                                      onClick={handleToggle}
                                    >
                                      <span className="material-icons">
                                        download
                                      </span>
                                      Due Diligence
                                      <span className="material-icons">
                                        keyboard_arrow_down
                                      </span>
                                    </Button>
                                  )}
                                  {getProductDetails.resource &&
                                  Object.keys(getProductDetails.resource)
                                    .length > 0 ? (
                                    <Popper
                                      className={"doc-list-popper"}
                                      open={open}
                                      anchorEl={anchorRef.current}
                                      role={undefined}
                                      transition
                                      disablePortal
                                    >
                                      {({ TransitionProps, placement }) => (
                                        <Grow
                                          {...TransitionProps}
                                          style={{
                                            transformOrigin:
                                              placement === "bottom"
                                                ? "center top"
                                                : "center bottom",
                                          }}
                                        >
                                          <Paper>
                                            <ClickAwayListener
                                              onClickAway={handleClose}
                                            >
                                              <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                                className="docs-list"
                                              >
                                                {isAuthenticate() ? (
                                                  <>
                                                    {Object.keys(
                                                      getProductDetails.resource
                                                    ).map((ele) => {
                                                      if (
                                                        Object.keys(ele)
                                                          .length > 0
                                                      )
                                                        return (
                                                          <MenuItem
                                                            className="comps-docs"
                                                            onClick={(e) => {
                                                              handleClose(e);
                                                              window.open(
                                                                encodeURI(
                                                                  getProductDetails
                                                                    .resource[
                                                                    ele
                                                                  ]
                                                                )
                                                              );
                                                            }}
                                                          >
                                                            {ele}
                                                          </MenuItem>
                                                        );
                                                    })}
                                                  </>
                                                ) : (
                                                  <>
                                                    {Object.keys(
                                                      getProductDetails.resource
                                                    ).map((ele) => {
                                                      if (
                                                        Object.keys(ele)
                                                          .length > 0
                                                      )
                                                        return (
                                                          <MenuItem
                                                            className="comps-docs"
                                                            onClick={(e) => {
                                                              handleClose(e);
                                                              setDocUri(
                                                                getProductDetails
                                                                  .resource[ele]
                                                              );
                                                              setBeforePopup(
                                                                "view_docs"
                                                              );
                                                            }}
                                                          >
                                                            {ele}
                                                          </MenuItem>
                                                        );
                                                    })}
                                                  </>
                                                )}
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper>
                                        </Grow>
                                      )}
                                    </Popper>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                              </span>
                            </div>
                            {buyTimer &&
                            getProductDetails["market_status"] !== "sold" &&
                            Object.keys(buyTimer)?.length != 0 &&
                            getProductDetails.buynow == 1 &&
                            buyTimer?.isCompleted != true ? (
                              <>
                                {getProductDetails.auction == 1 ? (
                                  <>
                                    {(timer.isCompleted &&
                                      userDetails.get["id"] == highestBidder) ||
                                    (timer.isCompleted && !hideBuy) ? (
                                      <div>
                                        <span className="headTil">
                                          <PrimaryButton
                                            btnSize="small"
                                            onClick={() => {
                                              if (userLoggedIn.get) {
                                                setBidPopup(true);
                                              } else {
                                                setLoginOpen(true);
                                              }
                                            }}
                                          >
                                            Buy Now
                                          </PrimaryButton>
                                        </span>
                                      </div>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <span className="headTil">
                                        <PrimaryButton
                                          btnSize="small"
                                          onClick={() => {
                                            if (userLoggedIn.get) {
                                              setBidPopup(true);
                                            } else {
                                              setLoginOpen(true);
                                            }
                                          }}
                                        >
                                          Buy Now
                                        </PrimaryButton>
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : offerTimer &&
                              getProductDetails["market_status"] !== "sold" &&
                              Object.keys(offerTimer)?.length != 0 &&
                              getProductDetails.offer == 1 &&
                              offerTimer?.isCompleted != true ? (
                              <>
                                <div>
                                  <span className="headTil">
                                    <PrimaryButton
                                      btnSize="small"
                                      onClick={() => {
                                        if (userLoggedIn.get) {
                                          setBidPopup(true);
                                        } else {
                                          setLoginOpen(true);
                                        }
                                      }}
                                    >
                                      Buy Now
                                    </PrimaryButton>
                                  </span>
                                </div>
                              </>
                            ) : null}

                            {/* {timer &&
                            Object.keys(timer)?.length != 0 &&
                            getProductDetails.auction == 1 &&
                            getProductDetails.market_status !== "sold" &&
                            timer?.isCompleted !== true ? (
                              <div>
                                <span className="headTil">
                                  {userDetails.get["customerid"] == "" ? (
                                    <PrimaryButton
                                      btnSize="small"
                                      label={"Register for Auction"}
                                    />
                                  ) : userDetails.get["status"] ==
                                    "incomplete" ? (
                                    <PrimaryButton
                                      btnSize="small"
                                      onClick={() =>
                                        handleUpdateClick(
                                          userDetails.get["id"],
                                          userDetails.get["email"],
                                          "bidding"
                                        )
                                      }
                                      label={"Register for Auction"}
                                    />
                                  ) : (
                                    <PrimaryButton
                                      onClick={() => submittingBid()}
                                      btnSize="small"
                                      label={
                                        <>
                                          {userLoggedIn.get ? (
                                            userDetails.get["id"] ==
                                            getProductDetails.user_id ? (
                                              "Can't bid on own property"
                                            ) : timer.isCompleted === false &&
                                              timer.text === "Auction Ends In" &&
                                              rtbCompleted &&
                                              timer.text !== "Bidding Starts In" &&
                                              timer.isCompleted === false &&
                                              highestBidder &&
                                              !!Object.keys(userDetails.get)
                                                .length &&
                                              userDetails.get["id"] ==
                                                highestBidder ? (
                                              "You are High Bidder"
                                            ) : timer.isCompleted === false &&
                                              timer.text === "Auction Ends In" &&
                                              rtbCompleted &&
                                              timer.text !== "Bidding Starts In" &&
                                              timer.isCompleted === false ? (
                                              "Submit a Bid"
                                            ) : (timer.isCompleted === false &&
                                                timer.text === "Auction Ends In" &&
                                                !rtbCompleted) ||
                                              (timer.isCompleted === false &&
                                                timer.text ===
                                                  "Bidding Starts In" &&
                                                !rtbCompleted) ? (
                                              <>Register for Auction</>
                                            ) : timer.isCompleted === false &&
                                              timer.text === "Bidding Starts In" &&
                                              rtbCompleted ? (
                                              "Edit Registration"
                                            ) : (
                                              "Biddingclosed"
                                            )
                                          ) : !userLoggedIn.get &&
                                            timer.isCompleted === false ? (
                                            <>Register for Auction</>
                                          ) : !userLoggedIn.get &&
                                            timer.isCompleted ? (
                                            "Bidding Closed"
                                          ) : null}
                                        </>
                                      }
                                    />
                                  )}
                                </span>
                              </div>
                            ) : null} */}
                          </div>
                          {/* <div className="pd-op-center">
                            <div className="tmrConts">
                              <div className="currtmr">
                                <div className="trm">
                                  <span className="pv-timer">
                                    <Timer item={getProductDetails} />
                                  </span>
                                </div>
                              </div>
                              <div className="currbid">
                                {getProductDetails.bidcount == 0 &&
                                !timer.isCompleted ? (
                                  <>
                                    {getProductDetails.enable_start_price ==
                                    "false" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <span className="bidAmt">
                                          $
                                          {setDecimalPoint(
                                            getProductDetails.sprice
                                          )}
                                        </span>
                                        <span>Starting Price</span>
                                      </>
                                    )}
                                  </>
                                ) : timer.isCompleted ? (
                                  !buyTimer.isCompleted &&
                                  getProductDetails.buynow ? (
                                    <>
                                      <span className="bidAmt">
                                        $
                                        {setDecimalPoint(
                                          getProductDetails.bprice
                                        )}
                                      </span>
                                      <span>Buynow Price</span>
                                    </>
                                  ) : getProductDetails.market_status ==
                                      "sold" &&
                                    getProductDetails?.buynow_total_amount ? (
                                    <>
                                      <span className="bidAmt">
                                        $
                                        {setDecimalPoint(
                                          getProductDetails.buynow_total_amount
                                        )}
                                      </span>
                                      <span>Sold Price</span>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <>
                                    <span className="bidAmt">
                                      $
                                      {setDecimalPoint(
                                        getProductDetails.wprice
                                      )}
                                    </span>
                                    <span>Current Bid</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="headerbtmShad">
                        <div className="allBtnCont row align-items-center justify-content-between">
                          <div className="col-md-6">
                           
                            <CustomDialog
                              handleClose={() => setSchedulePopup(false)}
                              open={schedulePopup}
                              dialogClass="modalViewChange"
                              title="Showing Date & Time"
                            >
                              <div className="confimBuyPopup">
                                <div className="subBidPop pt-3">
                                  {getProductDetails?.enable_inspection ==
                                  "true" ? (
                                    <>
                                      {getProductDetails?.book_tour
                                        ? getProductDetails.book_tour
                                        : null}{" "}
                                      {getProductDetails?.deal_date
                                        ? getProductDetails.deal_date
                                        : null}
                                      {"  To "}
                                      {getProductDetails?.deal_end_date
                                        ? getProductDetails.deal_end_date
                                        : null}{" "}
                                    </>
                                  ) : (
                                    <p className="mt-3">
                                      No Showing Available.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </CustomDialog>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="pv-wrapper">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7 col-12">
                        <div>
                          <div className="pv-banner-video clearfix">
                            <div className="favoriteList">
                              <FavoriteCheckbox
                                project_id={getProductDetails.id}
                                setLoginOpen={setLoginOpen}
                              />
                            </div>
                            <div className="listingType">
                              {getProductDetails.product_type !== "" &&
                              getProductDetails.product_type != "None" ? (
                                <span className="assetType">
                                  {getProductDetails.product_type}
                                </span>
                              ) : null}
                              {getProductDetails.cash_only_flag === 1 ? (
                                <span className="featuedType">Cash Only</span>
                              ) : null}
                              {/* {getProductDetails.featured_auction_property ? (
                                <span className="featuedType">Featured</span>
                              ) : null} */}
                            </div>
                            {/* {isImageLoaded ? null : (
                              <div className="circular-loader">
                                <CircularProgress className="loading" />
                              </div>
                            )} */}
                            <ImageGallery
                              ref={imgRef}
                              items={itms}
                              thumbnailPosition="bottom"
                              showNav={true}
                              onErrorImageURL={fallbackImage}
                              showBullets={false}
                              showFullscreenButton={true}
                              showPlayButton={false}
                              useTranslate3D={true}
                              // additionalClass={
                              //   updateThumnailCount > 4
                              //     ? "navHandler"
                              //     : updateThumnailCount == 1
                              //     ? "oneItem"
                              //     : ""
                              // }
                            />
                            {/* {Object.keys(getProductDetails).length > 0 ? (
                            ) : (
                              ""
                            )} */}
                            {/* <ImageGallery
                            //     // original={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${getProductDetails.avatar}`}
                            //     // onImageLoad={() => setIsImageLoaded(true)}
                            //   />
                            // <img
                            //   ref={imgRef}
                            //   src={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${getProductDetails.avatar}`}
                            //   alt="Take Virtual Tour"
                            //   className="pvb-img"
                            //   onError={(e) => onPropertyImageError(e)}
                            //   onLoad={() => setIsImageLoaded(true)}
                            //   style={isImageLoaded ? {} : { display: "none" }}
                            // /> */}
                          </div>
                          <div className="property-left">
                            <PropertyLeftShow
                              data={getProductDetails}
                              bidHistory={comments}
                              matterport={getMatterPort}
                              showMatterport={showMatterport}
                              lable={lable}
                              bookTour={bookTour}
                              width={width}
                              currentPropCity={currentPropCity}
                              constructAddressComponent={
                                constructAddressComponent
                              }
                              setBeforePopup={setBeforePopup}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 col-12">
                        {getProductDetails &&
                          getProductDetails.auction === 1 && (
                            <PropertyBids
                              nextBidAmount={nextBidAmount}
                              data={getProductDetails}
                              myClientsList={myClientsList}
                              myAgent={myAgent}
                              timer={timer}
                              rtbCompleted={rtbCompleted}
                              setNextBidAmount={setNextBidAmount}
                              bidContainer={bidContainer}
                              seyProductDetails={seyProductDetails}
                              isMobile={false}
                              setModelopen={setModelopenBid}
                              modalopen={modalopenBid}
                              submittingBid={submittingBid}
                              highestBidder={highestBidder}
                              hasRegistered={hasRegistered}
                            />
                          )}
                        {comments.length > 0 ? (
                          <PropertyComments
                            comments={comments}
                            noComments={noComments}
                            setComments={setComments}
                            handleCommentEdit={(edi) =>
                              setModalOpen({ ...edi })
                            }
                            handleCommentDelete={OnDelete}
                            propertyDetails={getProductDetails}
                            agentDetails={agentDetails}
                            timer={timer}
                            width={width}
                            commentsRef={commentsRef}
                            setBeforePopup={setBeforePopup}
                            setSubmittedPopup={setSubmittedPopup}
                            submittedPopup={submittedPopup}
                          />
                        ) : null}
                        {getProductDetails && (
                          <div className="mblBdngInfo">
                            <PropertyRightShow
                              userValue={userDetails}
                              highestBidder={highestBidder}
                              data={getProductDetails}
                              tourCount={tourCount}
                              agentDetails={agentDetails}
                              userDetails={getSellerData}
                              currentPropCity={currentPropCity}
                              note={notes}
                              agentInfo={agentInfo}
                              openFile={openFile}
                              propertyId={id}
                              refreshNotes={() => getNotes()}
                              addProductToCart={addProductToCart}
                              rtbCompleted={rtbCompleted}
                              sellerTermsCon={sellerTermsCon}
                              width={width}
                              noComments={noComments}
                              addFav={addFav}
                              mobile={mobile}
                              fav={fav}
                              toggleShare={toggleShare}
                              clickAskQuestion={clickAskQuestion}
                              clickBidNumber={clickBidNumber}
                              submittingOffer={submittingOffer}
                              soldDetails={soldDetails}
                              rtbFiles={rtbFiles}
                              setBeforePopup={setBeforePopup}
                              setSubmittedPopup={setSubmittedPopup}
                              submittedPopup={submittedPopup}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <Featured />
                    {/* <div className="pc-wrapper"> */}
                    {/* <div className="property-left">
                        {width ? (
                          <>
                            <PropertyLeftShow
                              data={getProductDetails}
                              bidHistory={comments}
                              matterport={getMatterPort}
                              showMatterport={showMatterport}
                              lable={lable}
                              bookTour={bookTour}
                              width={width}
                              currentPropCity={currentPropCity}
                              constructAddressComponent={
                                constructAddressComponent
                              }
                              setBeforePopup={setBeforePopup}
                            />
                          </>
                        ) : (
                          <> */}
                    {/* {getProductDetails && (
                              <div
                                className="mblBdngInfo"
                                style={{ paddingTop: `${heightChange + 40}px` }}
                              >
                                <PropertyRightShow
                                  userValue={userDetails}
                                  highestBidder={highestBidder}
                                  data={getProductDetails}
                                  tourCount={tourCount}
                                  agentDetails={agentDetails}
                                  userDetails={getSellerData}
                                  currentPropCity={currentPropCity}
                                  note={notes}
                                  agentInfo={agentInfo}
                                  openFile={openFile}
                                  propertyId={id}
                                  refreshNotes={() => getNotes()}
                                  addProductToCart={addProductToCart}
                                  rtbCompleted={rtbCompleted}
                                  sellerTermsCon={sellerTermsCon}
                                  width={width}
                                  noComments={noComments}
                                  addFav={addFav}
                                  mobile={mobile}
                                  fav={fav}
                                  toggleShare={toggleShare}
                                  clickAskQuestion={clickAskQuestion}
                                  clickBidNumber={clickBidNumber}
                                  submittingOffer={submittingOffer}
                                  soldDetails={soldDetails}
                                  rtbFiles={rtbFiles}
                                  setBeforePopup={setBeforePopup}
                                  setSubmittedPopup={setSubmittedPopup}
                                  submittedPopup={submittedPopup}
                                />
                              </div>
                            )} */}
                    {/* <PropertyLeftTopMobile
                              data={getProductDetails}
                              matterport={getMatterPort}
                              showMatterport={showMatterport}
                              currentPropCity={currentPropCity}
                              lable={lable}
                              agentDetails={agentDetails}
                              userDetails={getSellerData}
                              note={notes}
                              openFile={openFile}
                              propertyId={id}
                              refreshNotes={() => getNotes()}
                              width={width}
                              nextBidAmount={nextBidAmount}
                              timer={timer}
                              myClientsList={myClientsList}
                              myAgent={myAgent}
                              setNextBidAmount={setNextBidAmount}
                              bidContainer={bidContainer}
                              seyProductDetails={seyProductDetails}
                              comments={comments}
                              noComments={noComments}
                              setComments={setComments}
                              handleCommentEdit={(edi) =>
                                setModalOpen({ ...edi })
                              }
                              handleCommentDelete={OnDelete}
                              agentInfo={agentInfo}
                              sellerTermsCon={sellerTermsCon}
                              toggleShare={toggleShare}
                              setModelopen={setModelopenBid}
                              modalopen={modalopenBid}
                              addFav={addFav}
                              mobile={mobile}
                              fav={fav}
                              bookTour={bookTour}
                              clickSchedule={clickSchedule}
                              clickAskQuestion={clickAskQuestion}
                              commentsRef={commentsRef}
                              addTourCount={addTourCount}
                              clickBidNumber={clickBidNumber}
                              tourCount={tourCount}
                              highestBidder={highestBidder}
                              heightChange={heightChange}
                              setHeightChange={setHeightChange}
                              setBeforePopup={setBeforePopup}
                              setDocUri={setDocUri}
                              setSubmittedPopup={setSubmittedPopup}
                              submittedPopup={submittedPopup}
                              bidHistory={comments}
                            />
                          </>
                        )}
                      </div> */}
                    {/* {width && getProductDetails && (
                        <>
                          <PropertyRightShow
                            userValue={userDetails}
                            highestBidder={highestBidder}
                            data={getProductDetails}
                            tourCount={tourCount}
                            agentDetails={agentDetails}
                            userDetails={getSellerData}
                            currentPropCity={currentPropCity}
                            note={notes}
                            agentInfo={agentInfo}
                            openFile={openFile}
                            propertyId={id}
                            refreshNotes={() => getNotes()}
                            addProductToCart={addProductToCart}
                            rtbCompleted={rtbCompleted}
                            sellerTermsCon={sellerTermsCon}
                            width={width}
                            noComments={noComments}
                            addFav={addFav}
                            mobile={mobile}
                            fav={fav}
                            toggleShare={toggleShare}
                            clickAskQuestion={clickAskQuestion}
                            clickBidNumber={clickBidNumber}
                            submittingOffer={submittingOffer}
                            soldDetails={soldDetails}
                            rtbFiles={rtbFiles}
                            setBeforePopup={setBeforePopup}
                            setSubmittedPopup={setSubmittedPopup}
                            submittedPopup={submittedPopup}
                          />
                        </>
                      )} */}
                    {/* </div> */}
                  </div>

                  <ShareModel
                    shareFollow={shareFollow}
                    toggleShare={toggleShare}
                    copyToClipBoard={copyToClipBoard}
                    property={getProductDetails}
                    userDetails={
                      Object.keys(userDetails.get).length != 0
                        ? userDetails.get
                        : null
                    }
                    site_id={site_id}
                    copied={copied}
                  />

                  <RuumrModal open={follow} handleClose={toggleFollow}>
                    <div className="follow-wrap">
                      <p className="follow-note">
                        {step === 1
                          ? "Want to receive text about home?"
                          : "Receive the most up to date notifications on followed homes."}
                      </p>
                      {step === 1 ? (
                        <div className="">
                          <button
                            className="btn btn-primary"
                            onClick={() => setStep(2)}
                          >
                            Yes
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => addFav({ project_id: id })}
                          >
                            No
                          </button>
                        </div>
                      ) : (
                        <div className="notifications-wrap">
                          <CustomInput
                            label="Phone Number"
                            name="phone"
                            onChange={favouriteAdd}
                            onBlur=""
                            className="hh-input"
                            placeholder="XXX-XXX-XXXX"
                            required
                          />
                          {mobileNumberVal ? (
                            <p className="invalid-feedback">
                              Phone number is invalid
                            </p>
                          ) : null}
                          <button
                            className="btn btn-primary"
                            onClick={addMobileNumberFollow}
                          >
                            Go
                          </button>
                        </div>
                      )}
                    </div>
                  </RuumrModal>
                  <Dialog
                    editComment
                    open={Object.keys(modalopen).length !== 0}
                    onClose={(commentId, text, setTexts) => {
                      onEdit(commentId, text);
                      setTexts("");
                      setModalOpen({});
                    }}
                    close={(e) => setModalOpen({})}
                    edit={modalopen}
                  />
                  <Bidding
                    submitBidPopup={submitBidPopup}
                    function={updateAfterBid}
                    id={id}
                  />
                  <Buynow
                    bidPopup={bidPopup}
                    function={updateAfterBuy}
                    id={id}
                    modelUpdate={modelUpdate}
                  />
                  <CustomDialog
                    dialogClass="beforePopup"
                    open={
                      beforePopup == "home_inspection" ||
                      beforePopup == "bidding_update" ||
                      beforePopup == "schedule_tour" ||
                      beforePopup == "seller_question" ||
                      beforePopup == "view_docs" ||
                      beforePopup == "ask_question"
                        ? true
                        : false
                    }
                    handleClose={() => setBeforePopup(false)}
                  >
                    <div className="buyOfferPopupWrpr">
                      {beforePopup == "home_inspection" ? (
                        <>
                          <div className="topTitlecontnr">
                            <h4>View home inspection</h4>
                          </div>
                        </>
                      ) : beforePopup == "schedule_tour" ? (
                        <>
                          <div className="topTitlecontnr">
                            <h4>Schedule tour</h4>
                          </div>
                        </>
                      ) : beforePopup == "bidding_update" ? (
                        <>
                          <div className="topTitlecontnr">
                            <h4>Get bidding updates</h4>
                          </div>
                        </>
                      ) : beforePopup == "view_docs" ? (
                        <>
                          <div className="topTitlecontnr">
                            <h4>View Documents</h4>
                          </div>
                        </>
                      ) : beforePopup == "ask_question" ? (
                        <>
                          <div className="topTitlecontnr">
                            <h4>Ask a question</h4>
                          </div>
                        </>
                      ) : beforePopup == "seller_question" ? (
                        <>
                          <div className="topTitlecontnr">
                            <h4>Ask about terms</h4>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="mdlBodCntnr">
                        <p className="mdlIfoTxt">
                          Please add your email to access exclusive listing
                          content and features.
                        </p>
                      </div>
                      <div className="modlFormWrpr">
                        <form
                          className="mdlForm"
                          onSubmit={beforeFormik.handleSubmit}
                        >
                          <div className="row">
                            <div className="col-md-12 col-12 mt-3">
                              <CustomInput
                                label="Email*"
                                placeholder="Enter valid email address"
                                size="small"
                                type="email"
                                name="email"
                                className={`hh-input ${
                                  beforeFormik.errors.email &&
                                  beforeFormik.touched.email &&
                                  "is-invalid"
                                }`}
                                value={beforeFormik.values.email}
                                onChange={beforeFormik.handleChange}
                                onBlur={beforeFormik.handleBlur}
                              />
                              <div className="d-flex align-items-center justify-content-between">
                                <>
                                  {beforeFormik.errors.email &&
                                  beforeFormik.touched.email ? (
                                    <div className="invalid-feedback">
                                      {beforeFormik.errors.email}
                                    </div>
                                  ) : null}
                                </>
                                {beforeFormik.values.email != "" &&
                                !beforeFormik.errors.email &&
                                validMessage?.message != "" ? (
                                  <>
                                    <div
                                      className={`vldInvldMsg ${
                                        validMessage.type == "success"
                                          ? "text-success"
                                          : "text-danger"
                                      }`}
                                    >
                                      {validMessage?.message}{" "}
                                      {validMessage.type == "error" && (
                                        <>
                                          <a
                                            className="lgnLmk"
                                            onClick={() =>
                                              handleExistingEmail()
                                            }
                                          >
                                            Login here
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-12 mt-3">
                              <div className="agnBxWrpr">
                                <label>Are you an agent?</label>
                                <div className="chkBxWp">
                                  <CheckBox
                                    label="Yes"
                                    value="true"
                                    checked={
                                      beforeFormik.values.isAgent === "true"
                                    }
                                    onchange={(e) =>
                                      beforeFormik.setFieldValue(
                                        "isAgent",
                                        "true",
                                      )
                                    }
                                  />
                                  <CheckBox
                                    label="No"
                                    value="false"
                                    checked={
                                      beforeFormik.values.isAgent === "false"
                                    }
                                    onchange={(e) =>
                                      beforeFormik.setFieldValue(
                                        "isAgent",
                                        "false",
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              {beforeFormik.errors.isAgent &&
                              beforeFormik.touched.isAgent ? (
                                <div className="invalid-feedback">
                                  {beforeFormik.errors.isAgent}
                                </div>
                              ) : null}
                            </div>
                            {beforeFormik?.values?.isAgent === "false" && (
                              <div className="col-md-12 col-12 mt-3">
                                <div className="agnBxWrpr">
                                  <label>
                                    Do you need an agent to help you buy a
                                    house?
                                  </label>
                                  <div className="chkBxWp">
                                    <CheckBox
                                      label="Yes"
                                      value="true"
                                      checked={
                                        beforeFormik.values.need_agent ===
                                        "true"
                                      }
                                      onchange={(e) =>
                                        beforeFormik.setFieldValue(
                                          "need_agent",
                                          "true",
                                        )
                                      }
                                    />
                                    <CheckBox
                                      label="No"
                                      value="false"
                                      checked={
                                        beforeFormik.values.need_agent ===
                                        "false"
                                      }
                                      onchange={(e) =>
                                        beforeFormik.setFieldValue(
                                          "need_agent",
                                          "false",
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                {beforeFormik.errors.need_agent &&
                                beforeFormik.touched.need_agent ? (
                                  <div className="invalid-feedback">
                                    {beforeFormik.errors.need_agent}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                          <PrimaryButton
                            disabled={
                              validMessage?.type != "error" ? false : true
                            }
                            label={
                              beforePopup == "home_inspection"
                                ? "View home inspection"
                                : beforePopup == "schedule_tour"
                                  ? "Schedule tour"
                                  : beforePopup == "bidding_update"
                                    ? "Get Bidding Updates"
                                    : beforePopup == "view_docs"
                                      ? "View document"
                                      : beforePopup == "ask_question"
                                        ? "Submit question"
                                        : beforePopup == "seller_question"
                                          ? "Ask about terms"
                                          : ""
                            }
                            type="submit"
                          />
                        </form>
                      </div>
                    </div>
                  </CustomDialog>
                  <RegisterToBidPopup
                    isRegisterToBidOpen={registerToBidOpen}
                    setRegisterToBidOpen={setRegisterToBidOpen}
                    nextStep={nextStep}
                  />
                </div>
              </Layout>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PropertyView;
